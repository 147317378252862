import React from 'react';
import { Helmet } from 'react-helmet';
import { Container } from './styles';
import setaVerde from '../../../../assets/seta-verde.png';
import ImgPrincipal from '../../../../assets/noticias/oglobo/filaDesempregados.jpg';

function AosMaisPobres() {
  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>
          Convergência Brasil - Grupo de empresários quer destinar 30% das
          privatizações do governo federal aos mais pobres{' '}
        </title>
        <meta
          name="description"
          content="Objetivo do Movimento Convergência Brasil é a criação de um programa de renda complementar permanente aos mais necessitados"
        />
      </Helmet>

      <Container>
        <div className="container" id="somos">
          <div className="row">
            <div className="col-12">
              <h1 className="font-weight-bold">
                Grupo de empresários quer destinar 30% das privatizações do
                governo federal aos mais pobres
              </h1>
              <h2 className="">
                Objetivo do Movimento Convergência Brasil é a criação de um
                programa de renda complementar permanente aos mais necessitados
              </h2>
              <div className="article-header__meta">
                <div className="article__author">João Sorima Neto</div>
                <div className="article__date">
                  23/03/2021 - 04:30 / Atualizado em 23/03/2021 - 07:16
                </div>
              </div>
              <figure className="article-header__picture">
                <img src={ImgPrincipal} alt="Fila de desempregados." />
                <figcaption className="article__picture-caption">
                  Fila de desempregados. Movimento quer ajudar os mais
                  necessitados Foto: Marcos de Paula / Agência O Globo
                </figcaption>
              </figure>

              <p>
                SÃO PAULO - Enquanto um grupo de economistas, ex-ministros da
                Fazenda, banqueiros pede em{' '}
                <a
                  href="https://oglobo.globo.com/economia/em-carta-economistas-empresarios-pedem-medidas-efetivas-para-combater-covid-falam-em-lockdown-nacional-1-24935217"
                  rel="noreferrer"
                  target="_blank"
                >
                  carta pública{' '}
                </a>
                medidas do governo para combater a Covid-19 para que a economia
                retome seu ritmo, outro grupo de empresários está se articulando
                no Congresso e com membros do governo para levar adiante as
                reformas estruturais, especialmente a administrativa, e a
                criação de um programa de renda complementar permanente para os
                brasileiros mais necessitados.
              </p>
              <p>
                Trata-de de um grupo denominado Movimento Convergência Brasil,
                que foi criado ano passado e é formado por 25 empresários e
                executivos.
              </p>
              <p>
                <strong>Entrevista:</strong>&nbsp;{' '}
                <a
                  href="https://oglobo.globo.com/economia/a-reacao-do-governo-deixou-muito-desejar-custando-caro-em-numero-de-vidas-em-termos-de-pib-diz-arminio-24908429"
                  rel="noreferrer"
                  target="_blank"
                >
                  A reação do governo deixou muito a desejar, custando caro em
                  número de vidas e em termos de PIB’, diz Arminio
                </a>
              </p>
              <p>
                — Criamos uma estratégia, com pontos concretos, e já estamos
                conversamos com mais de 20 parlamentares. Somos um grupo
                apolítico, que quer que o Brasil resolva questões que vem
                empurrando com a barriga há muito tempo — diz Elvaristo Amaral,
                coordenador do Convergência Brasil, que já foi presidente da
                Credicard, atuou no Citibank e no Santander &nbsp;Meridional.
              </p>

              <p>
                Entre os apoiadores do movimento, segundo Amaral, estão nomes da
                iniciativa privada como Luiza Heleno Trajano, presidente do
                conselho do Magazine Luiza e Jayme Garfinkel, ex-presidente do
                conselho do Porto Seguro.
              </p>
              <p>
                Também integram o movimento Fábio Barbosa, ex-presidente do
                Santander Brasil; Cassio Casseb, ex-presidente do Banco do
                Brasil; Hélio Magalhães, ex-presidente do Citi e presidente do
                conselho de administração do Banco do Brasil, entre outros.
              </p>
              <p>
                Amaral conta que um dos objetivos do movimento é a criação de um
                programa de renda complementar permanente aos mais necessitados.
                Os recursos viriam do programa de privatizações do governo,
                incluindo estatais como a Petrobras e o Banco do Brasil.
              </p>
              <p>
                <strong>CEOs:</strong>&nbsp;{' '}
                <a
                  href="https://oglobo.globo.com/economia/veja-receita-para-sair-da-crise-de-ceos-empresarios-que-representam-pib-brasileiro-24614262"
                  rel="noreferrer"
                  target="_blank"
                >
                  Veja a receita de empresários e economistas para sair da crise
                </a>
              </p>
              <p>
                Um percentual de 30% dessas privatizações seria depositado em um
                fundo de investimento administrado pelo BNDES. O cadastro do
                Bolsa Família pode ajudar a identificar quem são os brasileiros
                qie poderiam se enquadrar nesse benefício.
              </p>
              <p>
                - É um programa esturuturado, com contrapartida, em que os
                beneficiados têm que matricular os filhos na escola e receber
                formação profissional. E a venda das estatais trará mais
                arrecadação ao governo, menos problemas de corrupção e
                roubalheira como já vimos anteriormente - disse Amaral,
                lembrando que pouca coisa saiu do papel em termos de concessões
                no atual governo e que deve haver mais comprometimento.
              </p>

              <h2>Reforma administrativa</h2>
              <p>
                Outro ponto defendido pelo grupo é a reforma administrativa, que
                inclui funcionários da ativa. A economia com essa reforma, nas
                contas do Movimento, será de pelo menos R$ 800 bilhões os
                próximos dez anos.
              </p>
              <p>
                O grupo já conversou com o vice-presidente da Câmara, Marcelo
                Ramos (PL-AM), sobre a proposta e elaborou uma emenda à proposta
                de reforma administrativa que será discutida no Congresso. A
                ideia é que 30% da economia obtida com a reforma nos próximos
                anos também destinada à transferência de renda aos mais pobres.
              </p>

              <p>
                - Com todos os parlamentares que já conversamos, tivemos boa
                aceitação das propostas. Com 70% de economia da reforma
                administrativa, o governo pode abater dívida pública da União.
                Temos propostas bastante objetivas, que podem gerar emprego e
                receita para o governo. Não são idéias apenas teóricas - disse
                Amaral.{' '}
              </p>

              <a
                href="https://oglobo.globo.com/economia/grupo-de-empresarios-quer-destinar-30-das-privatizacoes-do-governo-federal-aos-mais-pobres-1-24937078"
                rel="noreferrer"
                target="_blank"
                className="mt-1 text-blue font-weight-bold d-block text-end h6 link-materia"
              >
                Matéria completa
                <img src={setaVerde} alt="Ícone Seta Verde" className="ml-2" />
              </a>
            </div>
          </div>
        </div>
      </Container>
    </>
  );
}

export default AosMaisPobres;
