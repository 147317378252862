import styled from 'styled-components';

export const Container = styled.div`



.sc-iwsKbI.ehorDI.rec.rec-dot.rec.rec-dot_active {
    opacity: 0;
   }

.green {
    background-color: #129346;
  }
  .bg-blue {
    background-color: #2e2d7b;
  }

.txt {
 font-size: 64px;
 font-weight: bold;
 font-style: normal;
}

.size{
    width: 800px;
    height: auto;
    position: relative;
    left: 140px;
}

  padding-top: 10px;
  .green {
    background-color: #129346;
  }
  .bg-blue {
    background-color: #2e2d7b;
  }
  .carrousel {
    .rec-item-wrapper {
      width: 100vw !important;
      display: block;
    }

    .rec-arrow-right {
      position: absolute !important;
      right: 10px !important;
    }

    .rec-arrow-left {
      position: absolute !important;
      left: 10px !important;
      z-index: 1 !important;
    }
    .rec-arrow {
      width: 35px !important;
      height: 35px !important;
      min-width: 35px !important;
      line-height: 36px !important;
      font-size: 1.4rem !important;
      background-color: rgba(46, 45, 123, 0.2) !important;
      box-shadow: 0 0 2px 0px #fff !important;
      color: #fff;
    }
    .rec-slider-container {
      margin: 0px !important;
    }

    .rec-carousel {
      position: relative !important;
    }
    .rec-dot_active {
      background-color: rgba(46, 45, 123, 0.5);
      box-shadow: 0 0 1px 3px rgb(46, 45, 123);
    }
  }
  .container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center; 
    }
    .controls-wrapper{
    width: 100%;
    }
    .carousel-wrapper{
      width: 100%;
    }
    .rec-carousel-item:focus{
        outline: none; 
    } 
    .kQXhYj { 
      background: none!important; 
      height: 285px; 
  } 
  .rec-arrow-left { 
    font-size: 1em;  
    width: 23px;
    height: 23px;
    min-width: 23px; 
    line-height: 23px;
}
.rec-arrow-right{
  font-size: 1em;  
  width: 23px;
  height: 23px;
  min-width: 23px; 
  line-height: 23px;
}
.rec-pagination{
  display:none;
}
  @media screen and (max-width: 767px) {

    .txt {
        font-size: 34px;
       }
      
    padding-top: 10px;
    .carrousel {
      .rec-item-wrapper {
        height: 560px;
      }
    }


  }
  @media screen and (max-width: 574px) {
    padding-top: 12px;
    .carrousel {
      .rec-item-wrapper {
        height: 93vw;
      }
    }
  }

  @media screen and (max-width: 415px) {

    .size{
        width: 500px;
        height: auto;
        position: relative;
        left: 0px;
    }
    
  }

  @media screen and (min-width: 768px) and (max-width: 800px) {

    .size{
        width: 800px;
        height: auto;
        position: relative;
    }
    
  }


`;



