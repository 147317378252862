import React, { Fragment } from 'react';
import { Helmet } from 'react-helmet';
import { NavHashLink } from 'react-router-hash-link';
import { Container } from './styles';
import BannerHome from '../../components/BannerHome';
import Contato from '../../components/Contato';
import Blog from '../../components/Blog';
import Assinaturas from '../../components/Assinaturas';
import Assine from '../../components/Assine';
import FacaParte from '../../components/FacaParte';
import Elvaristo from '../../assets/Elvaristo-Teixeira-coor.png';
// import Luiza from '../../assets/Luiza-Helena.png';
import Fabio from '../../assets/Fabio-Barbosa.png';
import Helena from '../../assets/Helena-Nader.png';
import Jayme from '../../assets/Jayme-Garfinkel.png';
import Helio from '../../assets/Helio-Magalhaes.png';
import Claudio from '../../assets/Claudio-Kornarzewski.png';
import Antonio from '../../assets/Antonio-Jacinto.png';
import Luis from '../../assets/Luis-Felipe.png';
import Silvio from '../../assets/Silvio-Carvalho.png';
import CassioCasseb from '../../assets/cassio.png';
import DecioClemente from '../../assets/decio-clemente.png';
import HelioMattar from '../../assets/helio-mattar.png';
import JosePaulo from '../../assets/jose-paulo.png';
import JoaoElek from '../../assets/elek.png';
import Gentili from '../../assets/gentili.png';
import Mizuno from '../../assets/mizuno.jpg';
import Leo from '../../assets/leo.png';
import Andre from '../../assets/andre.png';
import Paulo from '../../assets/paulo_.png';
import Roberto from '../../assets/roberto_.png';
import Elias from '../../assets/elias_.png';
import Jorge from '../../assets/jorge_.png';
import PauloH from '../../assets/paulo_h_.png';

function Coordenadores() {
  const scrollWithOffset = el => {
    const yCoordinate = el.getBoundingClientRect().top + window.pageYOffset;
    let yOffset = -109;
    if (window.innerWidth <= 768) {
      yOffset = -80;
    }
    window.scrollTo({ top: yCoordinate + yOffset, behavior: 'smooth' });
  };

  const sortCompoundNames = names => {
    return names.sort((a, b) => {
      return a.localeCompare(b);
    });
  };

  const sortProfiles = profiles => {
    return profiles.sort((a, b) => {
      return a.name.localeCompare(b.name);
    });
  };

  const listOne = [
    'Álvaro de Souza',
    'Alexandre Langaro',
    'Álvaro Rubens Xavier de Castro',
    'André Ferraz',
    'Antônio Jacinto Matias',
    'Carlos Kawall',
    'Cassio Casseb',
    'Claudio Kornarzewski',
    'Daniel Randon',
    'Décio Clemente',
    'Elias Bueno',
    'Elvaristo Teixeira do Amaral',
    'Fabio Barbosa',
    'Frederico Trajano',
    'Helena Nader',
    'Hélio Magalhães',
    'Hélio Mattar',
    'Jayme Garfinkel',
    'João Elek',
    'João Paulo Carneiro',
    'Joaquim Castro Neto',
    'Jorge Gerdau Johannpeter',
    'Jorge Nascimento',
  ];

  const listTwo = [
    'José Carlos Gentili',
    'Jose Fadul',
    'José Ferdinando Ducca',
    'José Paulo Soares Martins',
    'Léo Voigt',
    'Luciano Timm',
    'Luís Felipe Teixeira do Amaral',
    'Marcopolo Melo Lopes',
    'Maria Antonia Civitta',
    'Mary Mizuno',
    'Matheus Villares',
    'Paulo Ari Gartner',
    'Paulo Hartung',
    'Paulo Uebel',
    'Paulo A. Zottolo',
    'Roberto Rodrigues',
    'Silvio Carvalho',
    'Sonia Hess de Souza',
    'Wang Wei Chang',
    'Wilson Ferreira',
    'Yon Moreira',
  ];

  const profiles = [
    {
      image: Elvaristo,
      name: 'Elvaristo Teixeira do Amaral',
      position: 'Criador do Manifesto Convergência Brasil',
      description:
        'É hora de os brasileiros e brasileiras de bom senso somarem esforços, CONVERGIREM. Enfrentemos nossos problemas econômicos, sociais e sanitários convergindo e trabalhando pela aprovação de Reformas do Estado e Privatização de todas as Empresas administradas pelo Estado, DESTINANDO 30% dos recursos levantados para criação de Programa de Complementação de Renda aos realmente mais necessitados deste país',
    },
    /* {
      image: Luiza,
      name: 'Luiza Helena Trajano',
      position: 'Sócia-proprietária e CEO do Magazine Luiza',
      description:
        'Nosso propósito é fazer um Brasil melhor de acordo com várias premissas em que a gente acredita. Uma delas é a união em momentos de dificuldade. Durante um incêndio, jogar gasolina só  piora as coisas. Precisamos de pessoas que podem, sim, pensar diferente, mas que tenham o mesmo objetivo',
    }, */
    {
      image: Fabio,
      name: 'Fabio Barbosa',
      position:
        'Administrador e executivo brasileiro, ex-presidente do Grupo Santander Brasil',
      description:
        'Não concordo com aqueles que dizem ‘ah, mas aqui no Brasil é assim mesmo’. O Brasil vai ser o que nós fizermos dele. Podemos ser protagonistas da construção de um país que seja do jeito que a gente quer. Muito me agrada participar de um movimento que segue nesse caminho',
    },
    {
      image: Helena,
      name: 'Helena Nader',
      position: 'Vice-presidente da Academia Brasileira de Ciências',
      description:
        'A ciência é porta para o crescimento de qualquer nação. Nenhum governo pode avaliar Educação e Ciência como gasto, mas, como investimento. O investimento no país também pode vir das reformas e desestatizações defendidas pelo Manifesto Convergência Brasil',
    },
    {
      image: Jayme,
      name: 'Jayme Garfinkel',
      position:
        'Investidor brasileiro e acionista controlador do Grupo Porto Seguro',
      description:
        'Dar meu apoio ao Movimento Convergência é uma oportunidade de me envolver com algo inovador e ao lado de pessoas seguramente comprometidas com o nosso país',
    },
    {
      image: Claudio,
      name: 'Claudio Kornarzewski',
      position: ' Presidente da Claudio Kornarzewski consultoria financeira',
      description:
        'Por que privatizar e fazer a reforma administrativa? Para remover o Estado de atividades que não agregam valor e que podem ser muito mais bem administradas pelo setor privado. Ao sair de atividades não essenciais, o Estado tem mais recursos para investir naquilo que é de fato essencial para o nosso futuro: educação e saúde. Atualmente, nosso país gasta com o setor público muito mais do que outros países, sejam desenvolvidos, sejam emergentes como o Brasil. Apenas como exemplo: o percentual do PIB gasto com o Judiciário é de três a quatro vezes superior ao do que é gasto em muitos outros países',
    },
    {
      image: Antonio,
      name: 'Antônio Jacinto Matias',
      position: 'Consultor e Membro de Conselhos do terceiro setor',
      description:
        'Entendo que o Manifesto Convergência aponta o caminho para o Brasil que sonhamos. Mais justo porque precisamos resgatar, incluir e promover os brasileiros marginalizados. Mais eficiente porque o Estado precisa ganhar inteligência, força e foco a partir de reformas que o levem a servir a sociedade e não a servir-se dela',
    },
    {
      image: Luis,
      name: 'Luís Felipe Teixeira do Amaral',
      position: 'Sócio gestor da Equitas Investimentos',
      description:
        'A ineficiência do Estado brasileiro e a manutenção de privilégios são mecanismos promotores de injustiça social no nosso país. O Movimento Convergência propõe uma solução prática para questões que parecem consensuais entre cidadãos que almejam uma sociedade mais justa e próspera. Ao propor o uso de parte dos ganhos a serem obtidos com privatizações e com a reforma administrativa para ampliar programas de repasse aos mais necessitados, o Convergência une interesses de representantes de diversos espectros políticos buscando facilitar a tramitação de medidas que modernizam o nosso país',
    },
    {
      image: Silvio,
      name: 'Silvio Carvalho',
      position: 'Ex vice-presidente do Citibank no Brasil',
      description:
        'Nós apoiamos o Manifesto Convergência, que está sendo apresentado a autoridades do Executivo, Legislativo, Judiciário e com muito boa receptividade quanto ao conceito básico. Mais um programa assistencialista? Não. É uma solução para uma necessidade consensual que está em discussão neste momento como continuidade para o Auxílio Emergencial e será submetida a todas as regras para quem recebe, como e por quanto tempo',
    },
    {
      image: CassioCasseb,
      name: 'Cassio Casseb',
      position:
        ' Senior Advisor do Banco Morgan Stanley no Brasil, Conselheiro do BBCE (Balcão Brasileiro de Comercialização de Energia) e ex-presidente do Banco do Brasil',
      description:
        'Apoio o Manifesto porque o Brasil precisa de uma agenda de reformas que façam com o que o país volte a crescer novamente. Com o dinheiro gasto na pandemia, as reformas e privatizações se fazem ainda mais necessárias para um alívio fiscal e continuarmos investindo em programas sociais',
    },
    {
      image: DecioClemente,
      name: 'Décio Clemente',
      position:
        'Ex marketing da Fiat, Citibank e Santander, foi comentarista do Jornal da Manhã da Jovem Pan por 12 anos, é presidente da DClemente & Associados e criou o primeiro e melhor evento de relacionamento esportivo do Brasil, o Líderes em Campo',
      description:
        'O Brasil só terá sua retomada econômica se economizarmos mais, as privatizações são super necessárias e também uma boa reforma do estado, diminuir as diferenças de salários e aposentadorias entre servidores públicos e as pessoas comuns, são mais do que esperadas. Faço parte do Convergência Brasil para pressionarmos, ao máximo, o Congresso e o Senado a pautarem estes temas tão relevantes para todos os brasileiros',
    },
    {
      image: HelioMattar,
      name: 'Hélio Mattar',
      position:
        'Diretor presidente do Instituto Akatu e membro dos Comitês de Sustentabilidade do Grupo Pão de Açúcar e da Dow Chemical global, e dos Conselhos de dois programas das Nações Unidas para o Meio-Ambiente',
      description:
        'O Brasil precisa de notícias boas, de investimento em políticas sociais aos que mais precisam, de investimento em biodiversidade, na proteção de nossas florestas nativas e no uso econômico da floresta em pé. Apoio esse Manifesto porque precisamos nos unir em prol de ideias que permitam minimamente uma vida digna e com alguma perspectiva a todos os brasileiros',
    },
    {
      image: JosePaulo,
      name: 'José Paulo Soares Martins',
      position:
        'Servidor público, membro do Conselho da ONG Parceiros Voluntários e irmão da Santa Casa de Misericórdia de Porto Alegre, foi secretário de fomento e incentivo à cultura no governo federal e participou da fundação e direção de importantes entidades na área da gestão, cultura e educação, como os movimentos Brasil Competitivo e Todos Pela Educação',
      description:
        'Precisamos convergir para o fortalecimento de uma máquina pública eficaz e focada no atendimento das necessidades vitais da sociedade brasileira e do desenvolvimento do nosso país',
    },
    {
      image: Helio,
      name: 'Hélio Magalhães',
      position: 'Presidente do Conselho Administrativo do Branco do Brasil',
      description:
        'O projeto de aliar a reforma administrativa e o programa de desestatizações à criação de um fundo de renda mínima me soa ousado e original. Acredito que se houver boa vontade para compreender o alcance da proposta, ela tem tudo para avançar e ajudar a melhorar o Brasil',
    },
    {
      image: JoaoElek,
      name: 'João Elek',
      position:
        ' CEO e Conselheiro de Administração da Tuv-Sud Bureau e Eldorado Celulose',
      description:
        'Sou extremamente a favor de reformas. Assim como a da Previdência, as reformas administrativa e tributária são vitais para o funcionamento do país nos próximos anos. São elas que podem fazer com que tenhamos um respiro e possamos ajudar os mais pobres. Tenho orgulho de apoiar o Convergência',
    },
    {
      image: Gentili,
      name: 'José Carlos Gentili',
      position:
        ' Advogado, Jornalista, Empresário, Historiador, Romancista,  Conferencista e Poeta',
      description: '',
    },
    {
      image: Mizuno,
      name: 'Mary Mizuno',
      position:
        'Ex-vice-presidente da MasterCard, Conselheira Certificada em Governança Social, empreendedora Founder da EmagNet',
      description:
        'Acredito na CONVERGÊNCIA de Pessoas para criar um ATIVO real de  engajamento, um ambiente de negócios mais resiliente, para a  construção de uma sociedade menos assimétrica e desigual para o  nosso Brasil',
    },
    {
      image: Leo,
      name: 'Léo Voigt',
      position:
        'Sociólogo e Mestre em Ciência Política pela Universidade Federal do Rio Grande do Sul – UFRGS é professor Universitário, e atualmente Secretário Municipal do Desenvolvimento Social da Prefeitura de Porto Alegre',
      description: '',
    },
    {
      image: Andre,
      name: 'André Ferraz',
      position:
        'Consultor e estrategista político, palestrante, publicitário pela PUC/RS e mestre em Ciência Política pela UFRGS/RS',
      description: '',
    },
    {
      image: Paulo,
      name: 'Paulo A. Zottolo',
      position:
        'Ex - CEO da Nívea Brasil e Nívea USA. Ex-CEO Philips Latin America . Atualmente é Presidente da  ZM International Consulting',
      description: '',
    },
    {
      image: Roberto,
      name: 'Roberto Rodrigues',
      position: '',
      description:
        'Engenheiro agrônomo, foi ministro da Agricultura, Pecuária e Abastecimento entre 1º de janeiro de 2003 e 30 de junho de 2006, é formado na Escola Superior de Agricultura Luiz de Queiroz (Esalq), em São Paulo, é especialista em economia agrícola e cooperativismo. Foi também secretário de Agricultura do governo paulista entre 1995 e 1997 e também presidente da Organização Internacional de Cooperativas Agrícolas. Hoje é agricultor. Além de engenheiro agrônomo é coordenador do Centro de Agronegócio na Escola de Economia de São Paulo, da Fundação Getúlio Vargas (FGV-SP)',
    },
    {
      image: Jorge,
      name: 'Jorge Gerdau Johannpeter',
      position: '',
      description:
        'Presidente do Conselho Superior do MBC (Movimento Brasil Competitivo), com forte atuação na busca pela eficiência e qualidade da gestão nos setores públicos e privados no Brasil, fundador do Programa Gaúcho de Qualidade e Produtividade, e membro da Academia Internacional da Qualidade e da Academia Brasileira da Qualidade. É membro do Grupo de Controle Gerdau, exerceu os cargos de CEO e presidente do Conselho do Grupo. É presidente do Conselho da Fundação Iberê Camargo, conselheiro da Fundação Bienal do Mercosul, participou do Conselho Consultivo da Junior Achievement, além de ser integrante do Conselho de Parceiros Voluntários e do Conselho de fundadores do movimento Todos pela Educação, que presidiu durante dez anos',
    },
    {
      image: Elias,
      name: 'Elias Silveira Bueno',
      position: '',
      description:
        'Formado em Direito pela PUC – SP, em 1965. Após breve período trabalhando com direito tributário, ingressou no mercado financeiro. Criou a Invest Lease, empresa de leasing do Banco Investbanco. Iniciou no Citibank como gestor da Citicorp Leasing  e após o exercício de diversas funções  se tornou CFO do Citibank Itália e posteriormente CFO do Citibank Brasil. Exerceu a função de coordenador financeiro do grupo Pirelli para a América do Sul e diretor executivo no Brasil da Pirelli Cabos S/A  e Pirelli Pneus S/A. Em 2000, deixou a atividade de executivo dando início à sua própria empresa ,  iNTERFILE LTDA Servicos de BPO, posteriormente vendida e hoje pertencente ao Grupo Atento',
    },
    {
      image: PauloH,
      name: 'Paulo Hartung',
      position: '',
      description:
        'Economista formado pela Universidade Federal do Espírito Santo (Ufes) em 1978, é “Professor Honoris Causa” pela Universidade Vila Velha (2004). É “Doutor Honoris Causa” pela Ufes (2006). Com longa carreira política, foi governador do Espírito Santo por três mandatos (2003- 2010 e 2015-2018), senador, prefeito de Vitória, deputado federal e deputado estadual.  Atualmente, comanda a Indústria Brasileira de Árvores (Ibá), associação que reúne a cadeia produtiva de árvores plantadas para fins industriais. Atua em diversas frentes em prol da formação de novas lideranças, além de realizar palestras sobre gestão pública em todo o País.  Recebeu em 2023 o Grande-Colar do Mérito do Tribunal de Contas da União (TCU). É membro do conselho do RenovaBR e participa do Instituto de Estudos da Saúde (IES), criado por Armínio Fraga. É membro do Conselho da Vale e integra o Conselho do Centro Brasileiro de Relações Internacionais (Cebri)',
    },
  ];

  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Convergência Brasil – Conheça nossos Coordenadores</title>
        <meta
          name="description"
          content="O Convergência Brasil foi criado por um grupo de empresários e executivos unidos para lutar por um Brasil mais justo, eficiente e igualitário"
        />
      </Helmet>
      <BannerHome heading={2} />
      <Container id="titulo">
        <div className="container pt-4 pb-2">
          <span className="barra d-flex mx-auto mb-4">
            <div className="green" />
            <div className="bg-blue" />
          </span>
          <h1 className="text-blue text-center titulo mb-1 text-uppercase">
            Unidos por um país mais justo e eficiente
          </h1>
          <h2 className="text-center mb-4 font-weight-bold h2 text-blue sub-titulo">
            Conheça nossos Coordenadores
          </h2>
          <p className="um-grupo mb-5">
            Um grupo de executivos e empresários apartidários que visa um país
            melhor e com menos desigualdade social para todos os brasileiros.
          </p>

          {sortProfiles(profiles).map(profile => (
            <Fragment key={profile.name}>
              <div className="row">
                <div className="col-4 col-img">
                  <img src={profile.image} alt={profile.name} />
                </div>
                <div className="col-12 col-md-8 d-flex flex-column justify-content-center">
                  <div className="row ">
                    <div className="col-12 info-coordenador">
                      <h3 className="text-blue mb-1 h5">{profile.name}</h3>
                      <p className="desc">{profile.position}</p>
                    </div>
                  </div>

                  {profile.description && (
                    <p className="fala">{`“${profile.description}”.`}</p>
                  )}
                </div>
              </div>
              <hr />
            </Fragment>
          ))}

          <div className="row">
            <div className="col-12 col-md-6">
              {sortCompoundNames(listOne).map(item => (
                <li key={item}>{item}</li>
              ))}
            </div>
            <div className="col-12 col-md-6">
              {sortCompoundNames(listTwo).map(item => (
                <li key={item}>{item}</li>
              ))}
            </div>
          </div>
        </div>
        <div className="container">
          <div className="row">
            <h2 className="col-12">
              <NavHashLink
                smooth
                scroll={el => scrollWithOffset(el)}
                to={`${process.env.PUBLIC_URL}/manifesto#manifesto-na-integra`}
                className="text-blue font-weight-bold mx-1"
              >
                Clique aqui e conheça o Manifesto do Convergência Brasil
              </NavHashLink>
            </h2>
          </div>
        </div>

        <div className="faca-parte-div">
          <FacaParte />
        </div>
      </Container>

      <Assine heading={4} />
      <Blog heading={4} />
      <Assinaturas />
      <Contato />
    </>
  );
}

export default Coordenadores;
