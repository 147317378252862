import React from 'react';
import { Input } from '@rocketseat/unform';
import InputMask from 'react-input-mask';
import PropTypes from 'prop-types';

export default function MaskInput({ name }) {
  return (
    <InputMask mask="999.999.999-99">
      {() => <Input name={name} className="col-12 input-assinatura" />}
    </InputMask>
  );
}

MaskInput.propTypes = {
  name: PropTypes.string.isRequired,
};
