import styled from 'styled-components';

import Banner from '../../assets/banner-manifesto.jpg';

export const BannerManifesto = styled.div`
  padding-top: 140px;
  .banner {
    background-size: cover;
    background-image: url(${Banner});
    background-position: center;
    background-repeat: no-repeat;
    padding: 0;
    height: 560px;
  }
  .assine-manifesto {
    margin-left: auto;
    margin-right: 176px !important;
    margin-top: -40px !important;
    text-decoration: none;
  }
  .assine-link {
    display: none;
  }
  a,
  a:active,
  a:hover {
    text-decoration: none;
    color: inherit;
  }

  .assine {
    transform: skewX(18deg);
    font-size: 1.5rem;
  }
  .leia {
    transform: skewX(18deg);
    font-size: 0.9rem;
  }
  .blue {
    transform: skewX(-18deg);
    background-color: #2e2d7b;
    height: 39px;
  }
  .input-assinatura {
    background: none;
    border: none;
    border-bottom: 1px solid #2e2d7b;
  }
  .barra {
    height: 39px;
    width: 15px;
    position: relative;
    left: -2px;

    div {
      width: 100%;
      transform: skewX(-18deg);
    }
  }
  .barra-leia {
    height: 28px;
    width: 10px;
    position: relative;
    left: -2px;

    div {
      width: 100%;
      transform: skewX(-18deg);
    }
  }
  .blue-leia {
    transform: skewX(-18deg);
    background-color: #2e2d7b;
    height: 28px;
  }
  .green {
    background-color: #129346;
  }
  .bg-blue {
    background-color: #2e2d7b;
  }
  .font-weight-black {
    font-weight: 900;
  }

  .por-um-brasil {
    font-size: 2.8rem;
    line-height: 58px;
    text-align: right;
    margin: 19px 137px 0 auto !important;

    .por-um {
      display: flex;
    }
    .brasil {
      font-size: 5.7rem;
      vertical-align: text-top;
    }
  }
  .justo-eficiente {
    line-height: 54px;
    font-weight: 900;
    text-align: right;
    margin: 40px auto auto 79px;
    font-size: 3.3rem;
    .justo {
      font-size: 4.5rem;
    }
    .eficiente {
      font-size: 3.6rem;
    }
  }
  #somos {
    .assine-manifesto {
      display: none !important;
    }
  }
  @media screen and (max-width: 1199px) {
    .assine-manifesto {
      margin-right: 89px !important;
    }
  }
  @media screen and (min-width: 767px) and (max-width: 992px) {
    .banner {
      height: 512px;
    }
    .assine-manifesto {
      // margin-left: 89px;
    }
  }

  @media screen and (max-width: 767px) {
    padding-top: 112px;
    h1 {
      font-size: 1.5rem;
    }

    #somos {
      text-align: center;
      .row {
        flex-direction: column-reverse;
      }
      .assine-manifesto {
        .blue {
          height: 34px;
        }
        .barra {
          height: 34px;
          width: 12px;
        }
        p {
          font-size: 1.5rem;
        }
      }
    }
    .por-um-brasil {
      font-size: 35px;
      line-height: 38px;
      margin: 36px 136px 0 auto !important;
      .brasil {
        font-size: 52px;
      }
    }

    .justo-eficiente {
      margin: 40px auto auto 49px;
      line-height: 39px;
      font-size: 40px;

      .justo {
        font-size: 48px;
      }
      .eficiente {
        font-size: 42px;
      }
    }

    .assine-manifesto {
      margin-left: auto;
      margin-right: -29px !important;
      margin-top: -32px !important;
    }

    .blue {
      height: 31px;
      .assine {
        width: 170px;
        font-size: 1rem;
      }
    }

    .barra {
      height: 31px;
      width: 15px;
    }

    .banner {
      height: 489px;
    }
  }

  @media screen and (max-width: 574px) {
    padding-top: 112px;
    .assine-link {
      display: block !important;
    }
    .assine-manifesto {
      margin-left: auto;
      margin-right: auto !important;
      margin-top: 60vw !important;
    }
    .por-um-brasil {
      font-size: 6.5vw;
      line-height: 8vw;
      margin: 4.8vw 23.6vw 0 -1vw !important;

      .por-um {
        display: flex;
      }
      .brasil {
        font-size: 10vw;
      }
      .mais {
        margin-right: -2vw;
      }
    }
    .barra {
      height: 38px;
    }
    .blue {
      height: 38px;
      .assine {
        width: auto;
        font-size: 1.5rem;
      }
    }

    .leia-manifesto {
      display: none !important;
    }
    .justo-eficiente {
      line-height: 7vw;
      margin: 8.2vw auto auto 7.6vw;
      font-size: 7vw;

      .justo {
        font-size: 8.7vw;
      }
      .eficiente {
        font-size: 7vw;
      }
    }
    .banner {
      height: 81vw;
    }
  }
  @media screen and (max-width: 350px) {
    #somos {
      .assine-manifesto {
        p {
          font-size: 1.25rem;
        }
      }
    }
    .assine {
      font-size: 1.2rem !important;
    }
  }
  @media screen and (min-width: 767px) and (max-width: 992px) {
    h1 {
      font-size: 2rem;
    }
    .por-um-brasil {
      font-size: 2.8rem;
      line-height: 55px;
      text-align: right;

      .brasil {
        font-size: 5rem;
      }
    }

    .justo-eficiente {
      line-height: 54px;
      font-size: 3.3rem;
      .justo {
        font-size: 4.2rem;
      }
      .eficiente {
        font-size: 3.4rem;
      }
    }

    .assine-manifesto {
      margin-right: -20px !important;
    }

    .assine {
      font-size: 1.35rem;
    }
  }

  @media screen and (min-width: 1546px) {
    .banner {
      height: 715px;
    }
    .por-um-brasil {
      font-size: 3.8rem;
      line-height: 74px;
      margin: -425px 136px 0 auto !important;

      .brasil {
        font-size: 6.7rem;
      }
    }

    .justo-eficiente {
      line-height: 63px;
      margin: 66px auto auto 86px;
      font-size: 4.3rem;

      .justo {
        font-size: 5.5rem;
      }
      .eficiente {
        font-size: 4.6rem;
      }
    }

    .assine-manifesto {
      margin-left: 84px;

      .blue {
        width: 350px;
        .assine {
          font-size: 1.5rem;
          margin: auto;
        }
      }
    }
  }
`;

export const OManifesto = styled.div`
  h1 {
    font-weight: 900;
    font-size: 4rem;
    margin-bottom: 2rem;
  }

  .barra {
    height: 12px;
    width: 340px;

    div {
      width: 50%;
      transform: skewX(-25deg);
    }
  }
  .green {
    background-color: #129346;
  }
  .bg-blue {
    background-color: #2e2d7b;
  }
  .manifesto {
    box-shadow: -2px 3px 22px rgba(0, 0, 0, 0.24);
    p,
    li {
      text-align: left;
      color: #707070;
      font-size: 1.5rem;
      h3 {
        font-size: 1.5rem;
        margin: 0;
        display: inline;
      }
    }
    li {
      margin: 1rem 0;
    }
  }

  @media screen and (max-width: 767px) {
    .barra {
      width: 300px;
    }
    h1 {
      font-size: 3rem;
    }
    .manifesto p,
    .manifesto li {
      font-size: 1.2rem;
      h3 {
        font-size: 1.2rem;
      }
    }
    .li-1-col-2 {
      margin-top: 0 !important;
    }
  }

  @media screen and (max-width: 574px) {
    margin-top: 27vw;
    .manifesto p,
    .manifesto li {
      text-align: left;
      color: #707070;
      font-size: 1rem;
      h3 {
        font-size: 1rem;
      }
    }
    .faca-parte-component {
      margin-top: -760px;
      position: absolute;
    }
    .manifesto {
      padding: 2rem !important;
      padding-bottom: 700px !important;
    }

    .row-manifesto {
      padding: 1rem;
    }
  }

  @media screen and (max-width: 560px) {
    .faca-parte-component {
      .faca-parte {
        max-width: 289px;
      }
      .compartilhe {
        font-size: 1.28rem;
        max-width: 281px;
      }
    }
  }

  @media screen and (min-width: 767px) and (max-width: 992px) {
  }
`;
