import React from 'react';
import { Helmet } from 'react-helmet';
import { Container } from './styles';
import ImgPrincipal from '../../../../assets/noticias/veja/RendaBrasil.jpg';
import setaVerde from '../../../../assets/seta-verde.png';

function RendaBrasil() {
  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>
          Convergência Brasil - Renda Brasil: A interminável discussão em torno
          de como pagar o programa
        </title>
        <meta
          name="description"
          content="Empresários sugerem usar dinheiro de reforma e privatização, enquanto outras formas de tornar palpável ideia de Bolsonaro quase custaram cabeças"
        />
      </Helmet>
      <Container>
        <div className="container" id="somos">
          <div className="row">
            <div className="col-12">
              <h1 className="text-center font-weight-bold">
                Renda Brasil: A interminável discussão em torno de como pagar o
                programa
              </h1>
              <h2 className="text-center">
                Empresários sugerem usar dinheiro de reforma e privatização,
                enquanto outras formas de tornar palpável ideia de Bolsonaro
                quase custaram cabeças
              </h2>
              <span className="text-center d-block author">
                Por{' '}
                <strong>
                  <span>Josette Goulart</span>, <span>Victor Irajá</span>
                </strong>{' '}
                <span>
                  Atualizado em 15 jan 2021, 16h44 - Publicado em 15 out 2020,
                  09h19{' '}
                </span>
              </span>

              <hr />
              <figure className="text-center">
                <img
                  src={ImgPrincipal}
                  alt="Paulo Guedes no Congresso: o ministro busca manter o governo alinhado às diretrizes econômicas -&nbsp;Michel Jesus/Câmara dos Deputados"
                />
                <figcaption className="caption mx-auto">
                  PRESSÃO - Paulo Guedes no Congresso: o ministro busca manter o
                  governo alinhado às diretrizes econômicas -&nbsp;Michel
                  Jesus/Câmara dos Deputados
                </figcaption>
              </figure>
              <hr />
              <section className="content">
                <p>
                  Não foram poucas as ideias apresentadas pela equipe econômica
                  para arrumar um naco do orçamento que pudesse fazer frente a
                  um substituto do auxílio emergencial. Mas as propostas
                  acabaram vinculadas a tirar dinheiro de pobres para dar aos
                  mais pobres. Foi assim que o <strong>Renda Brasil</strong>{' '}
                  morreu e nasceu o <strong>Renda Cidadã</strong>, que por sua
                  vez está em suspenso até dezembro, quando acaba a distribuição
                  do auxílio emergencial. De onde tirar dinheiro? Um grupo de
                  empresários brasileiros tem uma proposta e apresentou um
                  projeto de lei a parlamentares e ao governo. Junto com o
                  projeto, um manifesto: “Não podemos perder mais tempo com
                  disputas ideológicas e, infelizmente, estamos politicamente
                  divididos”. A ideia deles é pegar parte do dinheiro que o
                  governo federal vai economizar com a reforma administrativa,
                  que muda a remuneração e{' '}
                  <a href="https://vocerh.abril.com.br/mercado-vagas/plano-de-carreira-como-criar/">
                    plano de carreira
                  </a>{' '}
                  dos funcionários públicos, e parte do que arrecadar com a
                  venda de empresas estatais e destinar ao programa de renda
                  complementar que eles voltaram a chamar de Renda Brasil. Pelas
                  contas dos empresários daria para conseguir aproximadamente
                  240 bilhões de reais em dez anos. Simples. Só resta saber se o
                  ministro da Economia,{' '}
                  <a href="http://veja.com/noticias-sobre/paulo-guedes">
                    <strong>Paulo Guedes</strong>
                  </a>
                  , já não tem outros planos para esses recursos.
                </p>
                <p>
                  De acordo com uma versão preliminar do projeto de lei
                  costurado pelos empresários e encaminhado a integrantes do
                  governo e do parlamento, 30% dos recursos oriundos da venda de
                  estatais, somada à dinheirama gerada pela economia com os
                  servidores públicos seriam alocados em um fundo, chamado Fundo
                  Convergência, administrado por um conselho composto por
                  membros da iniciativa privada e do Ministério da Economia, que
                  seria gerido pelo Banco Nacional de Desenvolvimento Social, o
                  BNDES. Desse montante, 10% seria utilizado anualmente para
                  financiar o programa de distribuição de renda até quando
                  acabar o governo.
                </p>
                <p>
                  A proposta formulada pelas lideranças do{' '}
                  <strong>Movimento Convergência</strong>, que reúne alguns
                  nomes polpudos da economia do país, tem aval de ex-integrantes
                  do governo como o ex-secretário de Desburocratização Paulo
                  Uebel, que saiu da equipe de Guedes depois do vai-não-vai da
                  reforma administrativa. Estão na lista dos empresários nomes
                  como os de Jayme Garfinkel, fundador da Porto Seguro; Luiza
                  Helena Trajano, presidente do conselho do Magazine Luiza;
                  Jorge Gerdau Johannpeter, dono da Gerdau. Entre os executivos
                  estão nomes como Fábio Barbosa, ex-presidente do Santander
                  Brasil; Cassio Casseb, ex-presidente do Banco do Brasil,
                  Elvaristo T. Amaral, ex-Santander/Banespa; e Décio Clemente,
                  que foi diretor de marketing da Fiat. Roberto Rodrigues,
                  ex-ministro da Agricultura, é um dos nomes da política. Alguns
                  dos integrantes do grupo procuraram Uebel para que o
                  ex-secretário de Guedes fizesse uma análise sobre as
                  propostas, bem recebidas por ele. Uebel, então, aproveitou as
                  poucas reuniões com o grupo para apresentar um diagnóstico do
                  funcionalismo brasileiro e as duras penas para as contas
                  públicas com os altíssimos gastos da máquina pública.
                </p>
                <p>
                  O projeto já foi enviado para o Ministério da Economia e
                  descansa nos sistemas da pasta, mas pelo o que disse em
                  entrevista a VEJA Elvaristo Amaral, um dos porta-vozes do
                  movimento, o ministro Guedes ainda não deu sua opinião sobre o
                  assunto, tampouco os recebeu. O único entusiasmado até agora
                  teria sido o ministro da Cidadania, Onyx Lorenzoni, que
                  inclusive vai receber a proposta na próxima semana. Como
                  Bolsonaro sempre diz que quem dita os rumos na economia é
                  Guedes, o grupo ainda terá que passar pelo ministro. No
                  Congresso, o outro entusiasta, segundo Amaral, é o deputado
                  Osmar Terra, uma das vozes que reforçaram o coro de Bolsonaro
                  à ideia de que o coronavírus não iria matar mais do que mil
                  pessoas e, portanto, qualquer medida como o isolamento social
                  era um exagero <span>—</span> além, é claro, dos louros da
                  cloroquina para a doença, o que mostra-se cada dia mais
                  frágil.
                </p>

                <p>
                  O movimento Convergência nasceu para se distanciar dos
                  radiciais e conversar com pessoas de bom senso, segundo
                  Amaral. Eles acreditam que a iniciativa representa todo o
                  Brasil com a proposta de renda mínima. O conselho do grupo é
                  formado por 25 pessoas, todos empresários, ex-executivos ou
                  políticos. A proposta, acreditam, além de sanar uma das
                  preocupações principais de Guedes,&nbsp; seria um facilitador
                  para o próprio governo conseguir aprovar privatizações e
                  reformas no Congresso Nacional. Apesar de defenderem o uso de
                  parte do dinheiro que seria economizado com uma reforma
                  administrativa, os idealizadores não formularam sua própria
                  proposta de reforma. Outro ponto defendido por eles, que o
                  fundo com recursos públicos possa ser administrado por um
                  conselho que tenha representantes do setor privado, já fez
                  políticos e sociedade torcerem o nariz, mas o fato de colocar
                  o fundo nas asas do BNDES poderia ser um facilitador, na visão
                  do grupo. A ideia é que o conselho diretor deste fundo
                  bilionário tenha representantes públicos e privados, mas a
                  cada dois anos será presidido por um representante do setor
                  privado. A iniciativa lembra a fracassada experiência do
                  Ministério Público Federal em formar um fundo para administrar
                  o dinheiro recuperado dos escândalos colocados à luz pela
                  Operação Lava-Jato. A proposta da força-tarefa não deu dois
                  passos antes de ser derrubada.
                </p>
                <h3 className="font-weight-bold">Auxílio emergencial</h3>
                <p>
                  Desde que viu sua popularidade crescer na esteira do seu
                  auxílio emergencial, Bolsonaro incentivou a equipe econômica a
                  buscar formas de botar de pé um novo programa de renda básica.
                  A forma de financiar o programa já quase custou a cabeça do
                  secretário de Fazenda, Waldery Rodrigues. Depois que o homem
                  forte das finanças de Guedes revelou que os planos em estudo
                  para o então Renda Brasil envolvia o congelamento de
                  aposentadorias e do salário mínimo, para a fúria do
                  presidente. “Quem, porventura, vier me apresentar uma medida
                  como essa, eu só posso dar um cartão vermelho”, disse o
                  presidente<strong>&nbsp;Bolsonaro</strong>. “Até 2022, o meu
                  governo está proibido de falar a palavra (sic) Renda Brasil.
                  Vamos continuar com o Bolsa Família e ponto-final”. Talvez
                  pela censura ao título do programa por parte do próprio
                  presidente, a equipe de ministros e aliados do governo no
                  Congresso Nacional batizaram o novo Bolsa Família com outro
                  nome, o Renda Cidadã. Se os valores fechados ainda estavam em
                  estudo, a classe política bateu o martelo sobre como pagar a
                  conta <span>— o que apenas complicou o já complicado.</span>
                </p>
              </section>
              <a
                href="https://veja.abril.com.br/economia/renda-brasil-a-interminavel-discussao-em-torno-de-como-pagar-o-programa/"
                rel="noreferrer"
                target="_blank"
                className="mt-1 text-blue font-weight-bold d-block text-end h6 link-materia"
              >
                Matéria completa
                <img src={setaVerde} alt="Ícone Seta Verde" className="ml-2" />
              </a>
            </div>
          </div>
        </div>
      </Container>
    </>
  );
}

export default RendaBrasil;
