import { createGlobalStyle } from 'styled-components';

export default createGlobalStyle`
  @import url('https://fonts.googleapis.com/css2?family=Raleway:wght@400;500;600;700;900&family=Roboto:wght@400;500;600;700;800;900&family=Open+Sans&display=swap');
  * {
    font-family: 'Raleway', cursive;
    margin: 0;
    padding: 0;
    outline: 0;
    box-sizing: border-box;
  }
  *:focus {
    outline: 0;
  }
  html, body, #root {
    height: 100%;
    color: #575756;
  }
  body {
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }
  body {
    font: 16px 'Raleway';
  }
  img {
    max-width: 100%;
  }
  a {
    text-decoration: none;
    color:inherit;
  }
  ul {
    list-style: none;
  }
  button {
    cursor: pointer;
    border: none;
    background: transparent;
  }
  h1, h2, h3, h4, h5, h6, input, button{
    font-family: 'Raleway', sans-serif;
  }
  hr{
    border: none;
    border-top: 1px solid #999;
  }
  .mt {
    margin-top: 20px;
  }
  .text-center{
    text-align: center;
  }
  .mobile-only{
    display: none;
  }

  .w-fit-content {
    width: fit-content !important;
  }

  .text-green {
    color: #129346 !important;
  }

  .text-blue {
    color: #2E2D7B !important;
  }

  @media screen and (min-width: 768px) {
    .mobile-only {
      display: none !important;
    }
  }

  @media screen and (max-width: 767px) {
    .desk-only {
      display: none;
    }
  }

`;
