import React from 'react';

import { NavHashLink } from 'react-router-hash-link';
import { Helmet } from 'react-helmet';
import { BannerManifesto, OManifesto } from './styles';

import Contato from '../../components/Contato';
import Blog from '../../components/Blog';
import Assinaturas from '../../components/Assinaturas';
import Assine from '../../components/Assine';
import FacaParte from '../../components/FacaParte';

function ManifestoIntegra() {
  const scrollWithOffset = el => {
    const yCoordinate = el.getBoundingClientRect().top + window.pageYOffset;
    let yOffset = -109;
    if (window.innerWidth <= 768) {
      yOffset = -80;
    }
    window.scrollTo({ top: yCoordinate + yOffset, behavior: 'smooth' });
  };

  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Convergência Brasil – Conheça o Manifesto</title>
        <meta
          name="description"
          content=" Convergência Brasil é formado por um grupo de empresários que fazem um manifesto para mudar a realidade econômica e social do Brasil"
        />
      </Helmet>
      <BannerManifesto>
        <div className="banner">
          <div className="container h-100">
            <div className="row h-100 justify-content-between">
              <div className="col-12">
                <h2 className="d-inline-flex w-100">
                  <span className="w-fit-content  my-auto align-self-center text-green font-weight-bold por-um-brasil">
                    <span className="por-um">Por um </span>
                    <span className="brasil font-weight-black">
                      Brasil
                    </span>{' '}
                    <br />
                    <span className="mais">mais</span>
                  </span>

                  <span className="w-fit-content align-self-center text-blue text-uppercase justo-eficiente">
                    <span className="justo">Justo</span> e
                    <br />
                    <span className="eficiente">eficiente</span>
                  </span>
                </h2>
                <NavHashLink
                  smooth
                  scroll={el => scrollWithOffset(el)}
                  to={`${process.env.PUBLIC_URL}/#assine`}
                  className="row w-fit-content assine-manifesto mr-0 my-4 flex-nowrap"
                >
                  <div className="blue d-flex px-3">
                    <h2 className="my-auto text-white text-uppercase font-weight-bold assine mb-0">
                      Assine o manifesto
                    </h2>
                  </div>
                  <span className="barra d-flex">
                    <div className="green" />
                  </span>
                </NavHashLink>
              </div>
            </div>
          </div>
        </div>
        <NavHashLink
          smooth
          scroll={el => scrollWithOffset(el)}
          to={`${process.env.PUBLIC_URL}/manifesto/#manifesto-na-integra`}
          className="row w-fit-content leia-manifesto mx-auto mb-5 flex-nowrap"
        >
          <div className="blue-leia d-flex px-3">
            <p className="my-auto text-white text-uppercase font-weight-bold leia mb-0">
              LEIA O NOSSO MANIFESTO NA ÍNTEGRA
            </p>
          </div>
          <span className="barra-leia d-flex">
            <div className="green" />
          </span>
        </NavHashLink>
      </BannerManifesto>
      <OManifesto>
        <div className="container pb-2" id="manifesto-na-integra">
          <span className="barra d-flex mx-auto mb-4">
            <div className="green" />
            <div className="bg-blue" />
          </span>
          <h1 id="titulo" className="text-blue text-center text-uppercase">
            Recursos para um Brasil mais justo a partir de um Brasil mais
            eficiente
          </h1>
          <div className="row row-manifesto">
            <div className="col-12 p-5 manifesto">
              <p>
                Somos cidadãos e organizações da sociedade civil com o propósito
                de defender, mobilizar e levar adiante a proposta de um Brasil
                mais justo e mais eficiente por meio do avanço de pautas
                econômicas e sociais fundamentais para a estabilidade política e
                o desenvolvimento sustentável do nosso país, com base na livre
                iniciativa, no livre mercado e na gestão firme, republicana e
                democrática do Estado.
              </p>
              <p>
                Acreditamos que o Brasil possa reforçar e aperfeiçoar suas
                políticas públicas e programas sociais, tão necessários para a
                igualdade de oportunidades em nossa sociedade, a partir de
                recursos oriundos de ganhos de eficiência do Estado, como
                reformas no campo administrativo e tributário, desestatização de
                atividades não atinentes à gestão pública e venda de patrimônio
                ocioso.
              </p>
              <p>
                A nossa realidade econômica e as circunstâncias sociais que
                pressionam nossa sociedade demandam urgência na implementação de
                medidas que permitam maior desenvolvimento sustentável da nação.
                Não podemos perder mais tempo com disputas ideológicas.
                Precisamos de união e de convergência de propósitos para gerar a
                estabilidade tão necessária ao desenvolvimento nacional.{' '}
              </p>
              <p>
                Com essa visão e confiantes na possibilidade de importantes
                ganhos de recursos em processos de busca por mais eficiência na
                gestão pública, vemos a necessidade de convergir estratégias,
                interesses e ações que nos permitam obter esses ganhos possíveis
                e direcionar parte dos recursos para programas sociais.
              </p>
              <p>
                Essa é uma tarefa complexa e tremenda, que abrange a construção
                de propostas, leis e políticas econômicas e sociais da maior
                importância e, em especial, convergentes aos grandes interesses
                da nação brasileira. Necessita de envolvimento, sensibilidade,
                racionalidade, efetividade e todo o esforço dos nossos Poderes
                Executivo, Legislativo e Judiciário. Em especial, de todos os
                cidadãos brasileiros.
              </p>
              <p>
                Uma questão fundamental para o sucesso do nosso propósito e do
                desenvolvimento sustentável do nosso Brasil é um progresso
                pautado pela ética e alicerçado na economia e na cidadania
                dentro de um ambiente de liberdade, democrático e respeitoso à
                Constituição.
              </p>

              <ul className="row">
                <div className="col-12 col-md-6">
                  <li>
                    • <h3>Elvaristo Teixeira do Amaral</h3>
                  </li>
                  <li>
                    • <h3>Álvaro Rubens Xavier de Castro</h3>
                  </li>
                  <li>
                    • <h3>Álvaro de Souza</h3>
                  </li>
                  <li>
                    • <h3>Antônio Jacinto Matias</h3>
                  </li>
                  <li>
                    • <h3>Cassio Casseb</h3>
                  </li>
                  <li>
                    • <h3>Claudio Kornarzewski</h3>
                  </li>
                  <li>
                    • <h3>Daniel Randon</h3>
                  </li>
                  <li>
                    • <h3>Décio Clemente</h3>
                  </li>
                  <li>
                    • <h3>Elias Bueno</h3>
                  </li>
                  <li>
                    • <h3>Fabio Barbosa</h3>
                  </li>
                  <li>
                    • <h3>Frederico Trajano</h3>
                  </li>
                  <li>
                    • <h3>Helena Nader</h3>
                  </li>
                  <li>
                    • <h3>Hélio Magalhães</h3>
                  </li>
                  <li>
                    • <h3>Hélio Mattar</h3>
                  </li>
                  <li>
                    • <h3>Jayme Garfinkel</h3>
                  </li>
                  <li>
                    • <h3>João Elek</h3>
                  </li>
                </div>
                <div className="col-12 col-md-6">
                  <li className="li-1-col-2">
                    • <h3>João Paulo Carneiro</h3>
                  </li>
                  <li>
                    • <h3>Joaquim Castro Neto</h3>
                  </li>
                  <li>
                    • <h3>Jorge Gerdau Johannpeter</h3>
                  </li>
                  <li>
                    • <h3>José Ferdinando Ducca</h3>
                  </li>
                  <li>
                    • <h3>José Paulo Soares Martins</h3>
                  </li>
                  <li>
                    • <h3>Luciano Timm</h3>
                  </li>
                  <li>
                    • <h3>Luís Felipe Teixeira do Amaral</h3>
                  </li>
                  <li>
                    • <h3>Maria Antonia Civitta</h3>
                  </li>
                  <li>
                    • <h3>Paulo Hartung</h3>
                  </li>
                  <li>
                    • <h3>Paulo Uebel</h3>
                  </li>
                  <li>
                    • <h3>Roberto Rodrigues</h3>
                  </li>
                  <li>
                    • <h3>Roberto Rodrigues</h3>
                  </li>
                  <li>
                    • <h3>Silvio Carvalho</h3>
                  </li>
                  <li>
                    • <h3>Yon Moreira</h3>
                  </li>
                </div>
              </ul>
            </div>
          </div>
        </div>
        <FacaParte />
      </OManifesto>

      <Assine heading={3} />
      <Blog heading={3} />
      <Assinaturas />
      <Contato />
    </>
  );
}

export default ManifestoIntegra;
