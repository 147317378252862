import React from 'react';
import PropTypes from 'prop-types';

import swal from '@sweetalert/with-react';
import { Container, Content } from './styles';
import RemoverAssinatura from '../../components/RemoverAssinatura';

export default function PoliticaPrivacidade({
  bgPrimaryColor,
  bgSecondaryColor,
  titleColor,
  contentTextColor,
  fontRootSize,
}) {
  const showRemoverAssinatura = () => {
    swal({
      title: 'Remover Assinatura',
      content: <RemoverAssinatura />,
      buttons: ['Fechar', false],
    });
  };

  return (
    <Container bgColor={bgPrimaryColor} fontSize={fontRootSize}>
      <Content
        bgColor={bgSecondaryColor}
        titleColor={titleColor}
        textColor={contentTextColor}
      >
        <h1>Política de Privacidade</h1>
        <div>
          <div>
            <p>
              A presente Política de Privacidade tem por finalidade demonstrar o
              compromisso e o respeito de <b> Decio Clemente</b>, inscrito no
              CPF sob o nº 022.373.268-00, neste ato representada na forma de
              seu Contrato Social, doravante designada como{' '}
              <b>“Convergência Brasil”</b>, com sua privacidade dentro do uso e
              da navegação da nossa rede wi-fi, servindo a presente Política e
              Privacidade para regular e informar de forma simples, objetiva e
              transparente quais dados e informações serão obtidos e como
              poderão ser utilizados.
            </p>
            <p>
              Nosso objetivo é garantir o sigilo total das informações que você
              nos fornece, estabelecendo regras sobre a coleta, registro,
              utilização, armazenamento, tratamento e compartilhamento.
            </p>
            <p>
              Nós do <b>Convergência Brasil</b> estamos à sua disposição em caso
              de dúvidas sobre a nossa Política de Privacidade, entre em contato
              pelo Fale Conosco disponível no endereço eletrônico{' '}
              <a href="https://convergenciabrasil.org/#fale-conosco">
                https://convergenciabrasil.org/#fale-conosco
              </a>
            </p>
          </div>
          <button className="btn btn-primary mb-2" type="button" onClick={showRemoverAssinatura}>
            Remover Assinatura
          </button>
          <div>
            <p>
              <b>1. DEFINIÇÕES</b>
            </p>
            <p>Para todos os fins desta Política de Privacidade:</p>
            <p>
              Dados Não Pessoais: são os dados e as informações que não permitem
              a identificação de seu titular ou que não se referem a pessoas que
              possam ser identificadas, também considerados como dados anônimos.
            </p>
            <p>
              <b>Dados Pessoais:</b> são os dados que permitem, direta ou
              indiretamente, a identificação de uma pessoa em particular,
              conforme especificamente discriminados nesta Política de
              Privacidade.
            </p>
            <p>
              <b>Dados Pessoais Sensíveis:</b> são os dados pessoais com maior
              potencial discriminatório, capazes de revelar informações como
              origem racial ou étnica, convicção religiosa, opinião política,
              filiação a sindicato ou a organização de caráter religioso,
              filosófico ou político, dado referente à saúde ou à vida sexual,
              dado genético ou biométrico.{' '}
            </p>
            <p>
              <b>Fale Conosco:</b> é uma das formas de entrar em contato com o
              Convergência Brasil disponível no endereço eletrônico{' '}
              <a href="https://convergenciabrasil.org/#fale-conosco">
                https://convergenciabrasil.org/#fale-conosco
              </a>
            </p>
            <p>
              Site: é o site do <b>Convergência Brasil</b> disponível no
              endereço eletrônico{' '}
              <a href="www.convergenciabrasil.org">
                www.convergenciabrasil.org
              </a>
            </p>
            <p>
              Titular: pessoa natural a quem se referem os dados pessoais, tais
              como antigos, presentes ou potenciais usuários, colaboradores,
              contratados, parceiros comerciais e terceiros.{' '}
            </p>
          </div>

          <div>
            <p>
              <b>2. COLETA DE DADOS PESSOAIS</b>
            </p>
            <p>
              2.1. Dados Pessoais fornecidos diretamente pelo Titular: todos os
              Dados Pessoais fornecidos diretamente pelo Titular quando realiza
              o seu cadastro no Site ou na tela de acesso de nossa rede wi-fi,
              disponível em/no
            </p>
            <p>
              Os Dados Pessoais do Titular coletados são especificamente: (i)
              nome completo; (ii) data de nascimento; (iii) número de CPF; (iv)
              endereço residencial; (v) e-mail; (vi) telefone; (vii) dados
              bancários.
            </p>
            <p>
              2.2. Dados Pessoais coletados automaticamente: Dados Pessoais
              coletados automaticamente mediante a utilização de tecnologias
              padrões, como cookies, pixel tags, beacons e local shared objects,
              com o propósito de melhorar a experiência de navegação do usuário
              nos serviços, de acordo com seus hábitos e suas preferências.
            </p>
            <p>
              Os Dados Pessoais coletados automaticamente são informações de
              geolocalização e informações referentes ao dispositivo utilizado
              para navegar ou utilizar o Site, como por exemplo: endereços IP,
              tipo de navegador e idioma, provedor de serviços de internet,
              páginas de consulta e saída, sistema operacional, informações
              sobre a data e horário, dados sobre a sequência de clicks, ações
              no Site, fabricante do dispositivo, operadora, modelo, redes Wi-Fi
              e número de telefone.
            </p>
            <p>
              É possível desabilitar, por meio das configurações de seu
              navegador de internet, a coleta automática de informações,
              conforme item 4. No entanto, o Titular deve estar ciente de que,
              se desabilitar essas tecnologias, alguns recursos oferecidos pelo
              Site ou pela rede wi-fi, que dependem do tratamento dos referidos
              dados, poderão não funcionar corretamente.
            </p>
            <p>
              O acesso a seus Dados Pessoais é restrito aos colaboradores do{' '}
              <b>Convergência Brasil</b> e a seus parceiros comerciais, nos
              estritos limites desta Política de Privacidade, e nenhuma
              informação pessoal será divulgada publicamente.
            </p>
            <p>
              2.3. Dados Sensíveis: O <b>Convergência Brasil</b> poderá coletar
              certos tipos de dados sensíveis, tais como informação médica e de
              saúde, dados biométricos e sexuais.
            </p>
            <p>
              O tratamento dos Dados Pessoais Sensíveis será realizado tão
              somente mediante o expresso e específico consentimento do Titular.
              Para o tratamento de Dados Sensíveis de crianças e adolescentes, o
              tratamento será realizado mediante o expresso consentimento dos
              pais e/ou responsáveis legais. Tais informações serão armazenadas
              de maneira segura e não serão compartilhadas com terceiros, salvo
              após processo de anonimização.
            </p>
          </div>

          <div>
            <p>
              <b>3. USO E COMPARTILHAMENTO DOS DADOS</b>
            </p>
            <p>
              Os dados coletados poderão ser utilizados e compartilhados pelo{' '}
              <b>Convergência Brasil</b> para as seguintes finalidades:
            </p>
            <p>
              (a) Acesso à rede wi-fi disponibilizada gratuita e/ou
              onerosamente: usamos os Dados Pessoais para completar e
              administrar seu cadastro de usuário;
            </p>
            <p>
              (b) Cobrança do Preço: os Dados Pessoais do Titular serão
              utilizados pelo <b>Convergência Brasil</b> para cobrança do preço,
              quando e se for o caso. O Titular, desde já, concorda que o{' '}
              <b>Convergência Brasil</b> poderá compartilhar seus dados com
              empresas processadoras de pagamento, com as quais tenha contrato
              firmado tão somente para a finalidade de processamento do
              pagamento;{' '}
            </p>

            <p>
              (c) Serviços de atendimento ao usuário: o{' '}
              <b>Convergência Brasil</b> oferece um serviço de atendimento ao
              usuário disponível no endereço:{' '}
              <a href="https://convergenciabrasil.org/#fale-conosco">
                https://convergenciabrasil.org/#fale-conosco
              </a>
            </p>

            <p>
              (d) Correspondência eletrônica: o <b>Convergência Brasil</b>{' '}
              poderá utilizar o endereço de e-mail para enviar mensagens
              pertinentes ou uso de sua rede wi-fi ou acesso ao seu site.
            </p>

            <p>
              (e) Atividades de marketing: também usamos as informações do
              Titular para as atividades de marketing, de acordo com o perfil do
              usuário, conforme autorizado pelas normas legais. O Titular, desde
              já, concorda que as informações coletadas poderão ser
              compartilhadas com terceiros para fins de desenvolvimento de
              campanhas de marketing, desde que os terceiros respeitem o
              disposto nesta Política de Privacidade. Caso o Titular não queira
              mais receber os informativos publicitários e promocionais do{' '}
              <b>Convergência Brasil</b>, a qualquer momento, poderá
              descadastrar essa função, que também estará disponível em todas as
              comunicações enviadas.{' '}
            </p>

            <p>
              (f) Detecção e prevenção de fraudes: podemos usar seus Dados
              Pessoais para detectar e prevenir fraudes e outras atividades
              ilegais ou indesejadas.{' '}
            </p>

            <p>
              (g) Cumprimento de ordem legal ou judicial: o{' '}
              <b>Convergência Brasil</b> atenderá, independentemente de
              comunicação prévia ao Titular, qualquer autoridade judicial
              competente que tenha por fim determinar que seja revelada a
              identidade do Titular ou quaisquer dados fornecidos ao{' '}
              <b>Convergência Brasil</b> e/ou por ela coletados.{' '}
            </p>

            <p>
              (h) Cumprimento de solicitação administrativa: o{' '}
              <b>Convergência Brasil</b> poderá fornecer informações ao
              solicitante das informações, desde que autorizado pelo Titular;{' '}
            </p>

            <p>
              (i) Melhoria dos nossos serviços: o <b>Convergência Brasil</b>{' '}
              utiliza seus dados pessoais para fins de análise, melhoria e
              aprimoramento das Unidades.{' '}
            </p>

            <p>
              (j) Reorganização Societária: na hipótese de realização de
              quaisquer operações societárias envolvendo o{' '}
              <b>Convergência Brasil</b>, tais como fusão, reestruturação,
              incorporação, venda de ações e/ou quaisquer ativos, o Titular,
              desde já, concorda que seus dados poderão ser transferidos, desde
              que respeitados os termos desta Políticas de Privacidade.{' '}
            </p>
          </div>

          <div>
            <p>
              <b>4. COOKIES</b>
            </p>
            <p>
              Um cookie é uma pequena quantidade de dados colocada no navegador
              de seu computador ou no seu dispositivo móvel. A Política de
              Privacidade e Cookies se aplicam a cookies instalados nos
              navegadores e também às tecnologias similares ou análogas contidas
              nos aplicativos para dispositivos móveis (aqui referidas,
              genericamente, como “cookies”).{' '}
            </p>

            <p>
              Os cookies permitem que você seja reconhecido como o mesmo usuário
              navegando pelas páginas de um website.{' '}
            </p>

            <p>
              Em sua navegação no website, poderão ser utilizados 04 (quatro)
              tipos de cookies:{' '}
            </p>

            <p>
              (a) Autenticação: servem para reconhecer um determinado usuário,
              possibilitando o acesso e utilização do website com conteúdo e/ou
              serviços restritos e proporcionando experiências de navegação mais
              personalizadas.{' '}
            </p>

            <p>
              (b) Segurança: são utilizados para ativar recursos de segurança
              dos websites, com a finalidade de auxiliar o monitoramento e/ou
              detecção de atividades maliciosas ou vedadas por esta Política de
              Privacidade, bem como de proteger as informações do usuário do
              acesso por terceiros não autorizados.{' '}
            </p>

            <p>
              (c) Pesquisa, Análise e Desempenho: a finalidade deste tipo de
              cookie é ajudar a entender o desempenho do website, medir a
              audiência do website, verificar os hábitos de navegação do usuário
              no website, bem como a forma pela qual chegou à página do website
              (por exemplo, através de links de outros websites, buscadores ou
              diretamente pelo endereço).{' '}
            </p>

            <p>
              (d) Propaganda: são usados para apresentar publicidade relevante
              ao usuário, tanto dentro quanto fora do website ou em websites de
              parceiros, bem como para saber se o usuário que visualizou a
              publicidade visitou o website após terem visto a publicidade. Os
              Cookies de Propaganda também podem ser utilizados para lembrar
              eventuais pesquisas realizadas pelo usuário no website e, com base
              nas pesquisas realizadas pelo usuário no website, apresentar ao
              usuário anúncios relacionados aos seus interesses.{' '}
            </p>

            <p>
              O usuário poderá desabilitar o uso de cookies e limpar cache
              utilizando as configurações disponíveis em seu dispositivo.
              Contudo, ao optar por essa alternativa, é possível que o Site não
              desempenhe 100% das suas funcionalidades.{' '}
            </p>

            <p>
              Para mais informações sobre como proceder em relação à gestão dos
              cookies nos navegadores:{' '}
            </p>

            <p>
              Internet Explorer:
              <br />
              <a
                href="https://support.microsoft.com/pt-br/help/17442/windows-internet-explorer-delete-manage-cookies"
                target="blank"
              >
                https://support.microsoft.com/pt-br/help/17442/windows-internet-explorer-delete-manage-cookies{' '}
              </a>
            </p>

            <p>
              Mozilla Firefox: <br />
              <a
                href="https://support.mozilla.org/pt-BR/kb/ative-e-desative-os-cookies-que-os-sites-usam"
                target="blank"
              >
                https://support.mozilla.org/pt-BR/kb/ative-e-desative-os-cookies-que-os-sites-usam{' '}
              </a>
            </p>

            <p>
              Google Chrome: <br />
              <a
                href="https://support.google.com/accounts/answer/61416?co=GENIE.Platform%3DDesktop&hl=pt-BR"
                target="blank"
              >
                https://support.google.com/accounts/answer/61416?co=GENIE.Platform%3DDesktop&hl=pt-BR{' '}
              </a>
            </p>

            <p>
              Safari: <br />
              <a
                href="https://support.apple.com/pt-br/guide/safari/sfri11471/mac"
                target="blank"
              >
                https://support.apple.com/pt-br/guide/safari/sfri11471/mac{' '}
              </a>
            </p>
          </div>

          <div>
            <p>
              <b>5. SEGURANÇA</b>
            </p>
            <p>
              Os Dados Pessoais e as informações coletados são armazenados de
              forma segura e sigilosa enquanto considerados relevantes, seja
              pela manutenção de seu uso, seja pela suspeita de relevância legal
              das informações ou pelo continuado interesse nas estatísticas
              geradas a partir dessas informações.{' '}
            </p>

            <p>
              Aqueles que entrarem em contato com os Dados Pessoais do Titular
              agirão com confidencialidade e sigilo absoluto.{' '}
            </p>

            <p>
              O <b>Convergência Brasil</b>, seus parceiros e prestadores de
              serviços são devidamente instruídos para, quando for o caso,
              tratar os dados armazenados do Titular. O{' '}
              <b>Convergência Brasil</b> faz todo o controle de acesso às
              informações armazenadas, delimitando as permissões de acesso.{' '}
            </p>

            <p>
              O <b>Convergência Brasil</b> possui proteção contra acesso não
              autorizado a seus sistemas e utiliza métodos padrão de mercado
              para proteção dos dados coletados.{' '}
            </p>

            <p>
              O <b>Convergência Brasil</b> garante aos Titulares exatidão,
              clareza, relevância e atualização dos Dados Pessoais, de acordo
              com a necessidade e para o cumprimento da finalidade de seu
              tratamento.{' '}
            </p>

            <p>
              O <b>Convergência Brasil</b> adota os melhores esforços no sentido
              de preservar a privacidade dos dados do Titular. Entretanto nenhum
              site e/ou aplicativo é totalmente seguro e o{' '}
              <b>Convergência Brasil</b> não pode garantir integralmente que
              todas as informações que trafegam no Site ou em sua rede wi-fi
              disponibilizada gratuita ou onerosamente não sejam alvo de acessos
              não autorizados perpetrados por meio de métodos desenvolvidos para
              obter informações de forma indevida. Por esse motivo, o{' '}
              <b>Convergência Brasil</b> incentiva e recomenda que os Titulares
              tomem medidas apropriadas para se proteger, como por exemplo,
              mantendo confidenciais todos os nomes de usuários e senhas.{' '}
            </p>
          </div>

          <div>
            <p>
              <b>6. DIREITOS DOS TITULARES</b>
            </p>
            <p>
              Os Titulares poderão, por meio do Fale Conosco disponível no
              endereço eletrônico{' '}
              <a href="https://convergenciabrasil.org/#fale-conosco">
                https://convergenciabrasil.org/#fale-conosco
              </a>
              , solicitar ao <b>Convergência Brasil</b>, exclusivamente com
              relação a seus próprios Dados Pessoais (a) a confirmação da
              existência de tratamento dos dados; (b) o acesso aos dados; (c) a
              correção dos dados incompletos, inexatos ou desatualizados; (d) a
              obtenção de informações sobre as entidades públicas ou privadas
              com as quais o <b>Convergência Brasil</b> tenha compartilhados os
              dados; (e) anonimização, bloqueio ou eliminação dos dados; (f)
              portabilidade dos dados, nos termos da lei; e (g) a revogação do
              seu consentimento a esta Política.{' '}
            </p>

            <p>
              A revogação, pelo Titular, do seu consentimento a esta Política de
              Privacidade e/ou a exclusão de dados essenciais podem resultar na
              impossibilidade de continuação do vínculo com o{' '}
              <b>Convergência Brasil</b>. O <b>Convergência Brasil</b>{' '}
              empreenderá todos os esforços para atender tais pedidos no menor
              tempo possível. No entanto, mesmo em caso de exclusão e/ou
              revogação da aceitação a esta Política de Privacidade, será
              respeitado o prazo mínimo de armazenamento de dados e informações
              de usuários de aplicações de internet, conforme determinado pela
              legislação brasileira vigente.{' '}
            </p>
          </div>

          <div>
            <p>
              <b>7. ACEITAÇÃO</b>
            </p>
            <p>
              O Titular declara expressamente ter lido, compreendido e estar de
              acordo com esta Política de Privacidade.{' '}
            </p>

            <p>
              O Titular declara que está de acordo com a coleta dos dados, bem
              como com a utilização e compartilhamento para as finalidades aqui
              estabelecidas.{' '}
            </p>

            <p>
              O <b>Convergência Brasil</b> reserva-se no direito de alterar esta
              Política de Privacidade a qualquer momento, devendo manter
              atualizada e disponível no site www.<b>convergenciabrasil</b>.org.
              Nesse caso o Titular será informado quanto às alterações
              realizadas, conforme exigido pela legislação brasileira vigente,
              sendo altamente recomendável a leitura periódica e integral da
              presente Política de Privacidade.{' '}
            </p>

            <p>
              Qualquer oposição que o Titular tenha sobre as alterações desta
              Política de Privacidade poderão ser manifestadas, por escrito, por
              meio do Fale Conosco, sendo que a recusa desta Política de
              Privacidade e de suas eventuais alterações implicará ao Titular a
              impossibilidade de celebração de qualquer relação contratual com o{' '}
              <b>Convergência Brasil</b>.{' '}
            </p>
          </div>

          <div>
            <p>
              <b>8. LEGISLAÇÅO E FORO</b>
            </p>
            <p>
              Esta Política de Privacidade será regida, interpretada e executada
              de acordo com as leis brasileiras, especialmente, mas não se
              limitando à, a Lei Geral de Proteção de Dados nº 13.709/2018,
              sendo competente o foro da sede do <b>Convergência Brasil</b>/o
              domicílio do Titular para dirimir qualquer dúvida decorrente deste
              instrumento.
            </p>
          </div>
        </div>
      </Content>
    </Container>
  );
}

PoliticaPrivacidade.propTypes = {
  bgPrimaryColor: PropTypes.string,
  bgSecondaryColor: PropTypes.string,
  titleColor: PropTypes.string,
  contentTextColor: PropTypes.string,
  fontRootSize: PropTypes.string,
};

PoliticaPrivacidade.defaultProps = {
  bgPrimaryColor: '#fff',
  bgSecondaryColor: '#00000008',
  titleColor: '#303030',
  contentTextColor: '#303030',
  fontRootSize: '56%',
};
