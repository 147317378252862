import styled from 'styled-components';

export const Container = styled.div`
  text-align: left;
  label {
    font-weight: 600;
  }
  button {
    border-radius: 0px;
    transform: skewX(25deg);
    width: 100%;
    height: 100%;
    font-size: 1.25rem;
    outline: none;
  }
  button:focus {
    outline: 0 !important;
  }

  .blue {
    transform: skewX(-25deg);
    background-color: #2e2d7b;
    width: -webkit-fill-available;
    height: 48px;
  }
  .input-assinatura {
    background: none;
    border: none;
    border-bottom: 1px solid #2e2d7b;
  }
  .barra {
    height: 48px;
    width: 22.5px;

    div {
      width: 100%;
      transform: skewX(-25deg);
    }
  }
  .green {
    background-color: #129346;
  }
  @media screen and (max-width: 768px) {
    button {
      font-size: 2rem;
      padding: 0;
    }
    .barra {
      height: 48px;
      width: 29px;
    }
  }

  @media screen and (min-width: 1440px) {
  }
`;
