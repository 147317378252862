/* eslint-disable react/no-danger */
import React, { useState, useEffect } from 'react';
import { Helmet } from 'react-helmet';
import { Container } from './styles';
import api from '../../services/api';
import Loader from '../../components/Loader';
import Pagination from '../../components/Pagination';

function Blog() {
  const [materias, setMaterias] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    api.get(`blog`).then(response => {
      const { data } = response;
      data.materias = response.data.materias.map(moreMateria => ({
        id: moreMateria.Id,
        titulo: moreMateria.NmTitulo,
        tituloCapa: moreMateria.NmTituloCapa,
        descricao: moreMateria.NmDescription,
        slug: moreMateria.NmSlug,
        image: moreMateria.urlImgCapa,
      }));
      setMaterias(data);
      setLoading(false);
    });
  }, []);

  function loadPage(page) {
    setLoading(true);
    api.get(`blog?page=${page}`).then(response => {
      const { data } = response;
      data.materias = response.data.materias.map(moreMateria => ({
        id: moreMateria.Id,
        titulo: moreMateria.NmTitulo,
        tituloCapa: moreMateria.NmTituloCapa,
        descricao: moreMateria.NmDescription,
        slug: moreMateria.NmSlug,
        image: moreMateria.urlImgCapa,
      }));
      setMaterias(data);
      setLoading(false);
    });
  }

  return (
    <Container>
      <Helmet>
        <meta charSet="utf-8" />
        <title />
        <meta name="description" content="" />
        <meta name="keywords" content="" />
      </Helmet>
      <div className="w-100 d-flex align-items-center banner">
        <div className="container">
          <h1 className="titulo text-uppercase text-white font-weight-bold">
            Blog <br /> <span className="convergencia"> Convergência </span>
            <br /> <span className="text-yellow">Brasil </span>{' '}
          </h1>
        </div>
      </div>
      {loading && (
        <div className="col loader position-fixed">
          <Loader />
        </div>
      )}
      {!loading && Object.keys(materias).length < 1 && (
        <div className="col text-center nao-encontrado">
          <div className="h2">Nenhum post encontrado.</div>
        </div>
      )}

      {!loading && Object.keys(materias).length > 0 && (
        <div className="container">
          <div className="row">
            <div className="col">
              <p className="h3 my-0 mt-3">Posts mais recentes</p>
            </div>
          </div>
          {materias.materias.map(materia => (
            <a key={materia.id} className="row my-3"
            href={`${process.env.PUBLIC_URL}/blog/${materia.slug}`}
            >
              <div
                className="col-md-5"
              >
                <img
                  src={materia.image}
                  alt={materia.titulo}
                  className="w-100 py-md-3"
                />
              </div>
              <div className="col-md-7 py-3 d-flex justify-content-center flex-column">
                <h2>{materia.titulo}</h2>
                <p>
                  <div>
                    {materia.descricao}
                  </div>
                </p>
              </div>
            </a>
          ))}
          <div className="row pb-3">
            {Object.keys(materias).length > 0 && (
              <Pagination
                currentPage={materias.page}
                totalPages={materias.totalPages}
                onChangePage={loadPage}
              />
            )}
          </div>
        </div>
      )}
    </Container>
  );
}

export default Blog;
