import React from 'react';

import { Container } from './styles';

function Loader() {
  return (
    <>
      <Container className="my-5">
        <div className="row justify-content-center">
          <div className="col position-relative justify-content-center d-flex">
            <div className="setas-up-down position-absolute">
              <div className="seta-up">
                <span />
                <span />
                <span />
              </div>
              <div className="seta-down ">
                <span />
                <span />
                <span />
              </div>
            </div>
            <div className="setas-right-left d-flex position-absolute">
              <div className="seta-right">
                <span />
                <span />
                <span />
              </div>
              <div className="seta-left">
                <span />
                <span />
                <span />
              </div>
            </div>
          </div>
        </div>
      </Container>
    </>
  );
}

export default Loader;
