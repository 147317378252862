import styled from 'styled-components';

export const Container = styled.div`
  a,
  a:active,
  a:hover {
    text-decoration: none;
    color: inherit;
  }

  .font-weight-black {
    font-weight: 900;
  }

  @media screen and (max-width: 767px) {
    h2 {
      font-size: 1.5rem;
    }

    #somos {
      padding-top: 2.8rem;
      text-align: center;
      .row {
        flex-direction: column-reverse;
      }
    }
  }

  @media screen and (min-width: 767px) and (max-width: 992px) {
    h2 {
      font-size: 2rem;
    }
  }
`;

export const FaleConosco = styled.div``;
