import React from 'react';
// import { NavHashLink } from 'react-router-hash-link';
import PropTypes from 'prop-types';
import Carousel from 'react-elastic-carousel';
import { Container } from './styles';
// import videoCassio from '~/assets/videos/cassio.mp4';
// import  "node_modules/video-react/dist/video-react.css";
// import { Player } from 'video-react';
import Item from './item';

function VideoCarousel({ heading }) {
  const scrollWithOffset = el => {
    const yCoordinate = el.getBoundingClientRect().top + window.pageYOffset;
    let yOffset = -109;
    if (window.innerWidth <= 768) {
      yOffset = -80;
    }
    window.scrollTo({ top: yCoordinate + yOffset, behavior: 'smooth' });
  };
  // const CustomHeading = `h${heading}`;
  // const SubCustomHeading = `h${heading + 1}`;
  const breakPoints = [
    { width: 1, itemsToShow: 1 },
    { width: 550, itemsToShow: 2 },
    { width: 768, itemsToShow: 3 },
    { width: 1200, itemsToShow: 3 },
  ];
  return (
    <Container>
      <div className="container">
        <span className="barra d-flex mx-auto my-4 mb-4">
          <div className="green" />
          <div className="bg-blue" />
        </span>
        <h3 className="text-blue text-uppercase mx-auto text-center txt">
          LIVES COM PERSONALIDADES DO CENÁRIO NACIONAL
        </h3>

        <p className="text-center">
          Assista nossos bate-papos com personalidades do ambiente empresarial,
          poético e social em que <br /> debatemos a importância de um Brasil
          mais justo e eficiente
        </p>
      </div>

      <div className="container">
        <div className="carousel-wrapper">
          <Carousel itemsToShow={3} breakPoints={breakPoints}>
            <Item>
              <div className="embed-responsive embed-responsive-16by9">
                <video
                  controls
                  poster="https://convergenciabrasil.com.br/videos/lives/capa_live1.jpg"
                >
                  <source
                    src="https://convergenciabrasil.com.br/videos/lives/video_live1.mp4"
                    type="video/mp4"
                  />
                </video>
              </div>
            </Item>
            <Item>
              <div className="embed-responsive embed-responsive-16by9">
                <video
                  controls
                  poster="https://convergenciabrasil.com.br/videos/lives/capa_live2.jpg"
                >
                  <source
                    src="https://convergenciabrasil.com.br/videos/lives/video_live2.mp4"
                    type="video/mp4"
                  />
                </video>
              </div>
            </Item>
            <Item>
              <div className="embed-responsive embed-responsive-16by9">
                <video
                  controls
                  poster="https://convergenciabrasil.com.br/videos/lives/capa_live3.jpg"
                >
                  <source
                    src="https://convergenciabrasil.com.br/videos/lives/video_live3.mp4"
                    type="video/mp4"
                  />
                </video>
              </div>
            </Item>
            <Item>
              <div className="embed-responsive embed-responsive-16by9">
                <video
                  controls
                  poster="https://convergenciabrasil.com.br/videos/lives/capa_live4.jpg"
                >
                  <source
                    src="https://convergenciabrasil.com.br/videos/lives/video_live4.mp4"
                    type="video/mp4"
                  />
                </video>
              </div>
            </Item>
            <Item>
              <div className="embed-responsive embed-responsive-16by9">
                <video
                  controls
                  poster="https://convergenciabrasil.com.br/videos/lives/capa_live5.jpg"
                >
                  <source
                    src="https://convergenciabrasil.com.br/videos/lives/video_live5.mp4"
                    type="video/mp4"
                  />
                </video>
              </div>
            </Item>
            <Item>
              <div className="embed-responsive embed-responsive-16by9">
                <video
                  controls
                  poster="https://convergenciabrasil.com.br/videos/lives/capa_live6.jpg"
                >
                  <source
                    src="https://convergenciabrasil.com.br/videos/lives/video_live6.mp4"
                    type="video/mp4"
                  />
                </video>
              </div>
            </Item>
            <Item>
              <div className="embed-responsive embed-responsive-16by9">
                <video
                  controls
                  poster="https://convergenciabrasil.com.br/videos/lives/capa_live7.jpg"
                >
                  <source
                    src="https://convergenciabrasil.com.br/videos/lives/video_live7.mp4"
                    type="video/mp4"
                  />
                </video>
              </div>
            </Item>
            <Item>
              <div className="embed-responsive embed-responsive-16by9">
                <video
                  controls
                  poster="https://convergenciabrasil.com.br/videos/lives/capa_live8.jpg"
                >
                  <source
                    src="https://convergenciabrasil.com.br/videos/lives/video_live8.mp4"
                    type="video/mp4"
                  />
                </video>
              </div>
            </Item>
            <Item>
              <div className="embed-responsive embed-responsive-16by9">
                <video
                  controls
                  poster="https://convergenciabrasil.com.br/videos/lives/capa_live9.jpg"
                >
                  <source
                    src="https://convergenciabrasil.com.br/videos/lives/video_live9.mp4"
                    type="video/mp4"
                  />
                </video>
              </div>
            </Item>
            <Item>
              <div className="embed-responsive embed-responsive-16by9">
                <video
                  controls
                  poster="https://convergenciabrasil.com.br/videos/lives/capa_live10.jpg"
                >
                  <source
                    src="https://convergenciabrasil.com.br/videos/lives/video_live10.mp4"
                    type="video/mp4"
                  />
                </video>
              </div>
            </Item>
            <Item>
              <div className="embed-responsive embed-responsive-16by9">
                <video
                  controls
                  poster="https://convergenciabrasil.com.br/videos/lives/capa_live11.jpg"
                >
                  <source
                    src="https://convergenciabrasil.com.br/videos/lives/video_live11.mp4"
                    type="video/mp4"
                  />
                </video>
              </div>
            </Item>
            <Item>
              <div className="embed-responsive embed-responsive-16by9">
                <video
                  controls
                  poster="https://convergenciabrasil.com.br/videos/lives/capa_live12.jpg"
                >
                  <source
                    src="https://convergenciabrasil.com.br/videos/lives/video_live12.mp4"
                    type="video/mp4"
                  />
                </video>
              </div>
            </Item>
            <Item>
              <div className="embed-responsive embed-responsive-16by9">
                <video
                  controls
                  poster="https://convergenciabrasil.com.br/videos/lives/capa_live13.jpg"
                >
                  <source
                    src="https://convergenciabrasil.com.br/videos/lives/video_live13.mp4"
                    type="video/mp4"
                  />
                </video>
              </div>
            </Item>
            <Item>
              <div className="embed-responsive embed-responsive-16by9">
                <video
                  controls
                  poster="https://convergenciabrasil.com.br/videos/lives/capa_live14.jpg"
                >
                  <source
                    src="https://convergenciabrasil.com.br/videos/lives/video_live14.mp4"
                    type="video/mp4"
                  />
                </video>
              </div>
            </Item>
            <Item>
              <div className="embed-responsive embed-responsive-16by9">
                <video
                  controls
                  poster="https://convergenciabrasil.com.br/videos/lives/capa_live15.jpg"
                >
                  <source
                    src="https://convergenciabrasil.com.br/videos/lives/video_live15.mp4"
                    type="video/mp4"
                  />
                </video>
              </div>
            </Item>
            <Item>
              <div className="embed-responsive embed-responsive-16by9">
                <video
                  controls
                  poster="https://convergenciabrasil.com.br/videos/lives/capa_live16.jpg"
                >
                  <source
                    src="https://convergenciabrasil.com.br/videos/lives/video_live16.mp4"
                    type="video/mp4"
                  />
                </video>
              </div>
            </Item>
          </Carousel>
        </div>
      </div>
    </Container>
  );
}

export default VideoCarousel;
VideoCarousel.propTypes = {
  heading: PropTypes.number.isRequired,
};
