import styled from 'styled-components';

export const Container = styled.div`
  @import url('https://fonts.googleapis.com/css2?family=Rufina:wght@400;700&display=swap');
  padding-top: 130px;
  a,
  a:active,
  a:hover {
    text-decoration: underline;
    color: #0f9244;
  }
  h1 {
    font-size: 52px;
    color: #2f2f2f;
    font-family: 'Rufina';
  }
  h2 {
    font-size: 18px;
  }
  .caption {
    font-size: 12px;
    max-width: 680px;
  }
  .font-weight-black {
    font-weight: 900;
  }
  .link-materia {
    text-align: end;
    font-family: 'Raleway';
    text-decoration: none !important;
  }
  @media screen and (min-width: 767px) and (max-width: 992px) {
    padding-top: 130px;
  }
  @media screen and (max-width: 767px) {
    padding-top: 105px;
    h1 {
      font-size: 32px;
    }
  }
`;
