import styled from 'styled-components';

export const Container = styled.div`
  h2 {
    font-weight: 900;
    font-size: 4rem;
    margin-bottom: 2.25rem;
  }

  a {
    text-decoration: none !important;
    color: inherit;
  }

  a:hover {
    color: inherit;
  }

  .barra {
    height: 12px;
    width: 340px;

    div {
      width: 50%;
      transform: skewX(-25deg);
    }
  }

  .barra-entrevista {
    height: 15px;
    width: 16px;

    div {
      width: 50%;
      transform: skewX(-25deg);
    }
  }
  .green {
    background-color: #129346;
  }
  .bg-blue {
    background-color: #2e2d7b;
  }
  .box-entrevista {
    padding: 0.6rem 0.8rem;
    .entrevista {
      font-size: 1.25rem;
    }
  }
  .box-entrevista-principal {
    padding: 0.9rem;
  }
  .logo-jp {
    max-width: 60px;
  }
  .logo-istoe {
    max-width: 100px;
  }
  .logo-estadao {
    max-width: 121px;
  }
  .logo-ig {
    max-width: 113px;
  }
  .h5-5 {
    font-size: 1.1rem;
  }
  .entrevista-sub {
    font-size: 1.25rem;
  }
  .entrevista-sub-texto {
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
    font-size: 1rem;
  }
  .noticia-texto-secundaria {
    display: -webkit-box;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
    -webkit-line-clamp: 5;
  }
  @media screen and (min-width: 768px) {
    .logo-texto {
      margin-top: 15px;
    }
  }
  @media screen and (min-width: 992px) {
    .img-midia {
      max-width: 196px;
    }
  }
  @media screen and (min-width: 1200px) {
    .img-midia {
      max-width: 256px;
    }
  }
  @media screen and (max-width: 767px) {
    .logo-texto-img {
      padding: 0 15px !important;
      display: flex;
      flex-wrap: inherit;
      margin: 15px 0 0 0;
    }
    .logo-texto {
      display: flex;
    }
    .img-materia-meio {
      padding-right: 15px !important;
    }
    .noticia-texto-principal,
    .noticia-texto-secundaria {
      display: -webkit-box;
      -webkit-box-orient: vertical;
      overflow: hidden;
      text-overflow: ellipsis;
    }
    .noticia-texto-principal {
      -webkit-line-clamp: 2;
    }
    .noticia-texto-secundaria {
      -webkit-line-clamp: 3;
    }
    .barra {
      width: 300px;
    }
    h2 {
      font-size: 3rem;
      margin-bottom: 1.5rem;
    }
  }
  @media screen and (max-width: 574px) {
    .img-midia {
      max-width: 39%;
      height: max-content;
    }
    .box-entrevista {
      .entrevista {
        font-size: 1.2rem;
      }
    }
  }
  @media screen and (max-width: 484px) {
    .box-entrevista {
      .entrevista {
        font-size: 1rem;
      }
      p {
        font-size: 0.9rem;
      }
    }
  }
  @media screen and (max-width: 447px) {
    .img-midia {
      max-width: 43%;
      height: max-content;
    }
    .box-entrevista {
      .entrevista {
        font-size: 1rem;
      }
      p {
        font-size: 0.9rem;
      }
    }
  }

  @media screen and (max-width: 400px) {
    .img-materia-meio,
    .logo-texto {
      flex: 0 0 100%;
      max-width: 100%;
    }
    .img-materia-meio {
      padding-right: 0 !important;
    }
    .logo-texto {
      padding: 0 !important;
      flex-direction: row !important;
    }
    /* .logo-jp,
    .logo-istoe {
      margin: auto 15px auto 0 !important;
    } */
  }
  @media screen and (max-width: 387px) {
    .img-midia {
      max-width: 100%;
      height: max-content;
    }
  }
  @media screen and (min-width: 767px) and (max-width: 991px) {
    .img-midia {
      height: fit-content;
      width: 48%;
    }
    .box-entrevista-principal {
      padding: 0.8rem;
      p {
        font-size: 0.9rem;
      }
    }
    .entrevista-sub {
      font-size: 1.1rem;
    }
    .entrevista-sub-texto {
      font-size: 0.8rem;
      display: -webkit-box;
      -webkit-line-clamp: 3;
      -webkit-box-orient: vertical;
      overflow: hidden;
      text-overflow: ellipsis;
    }
    .noticia-texto-principal {
      font-size: 0.8rem;
    }
  }
`;
