import React from 'react';
import { FaBackward, FaForward } from 'react-icons/fa';
import PropTypes from 'prop-types';
import { Container } from './styles';

function Pagination({ currentPage, totalPages, onChangePage }) {
  let page = currentPage - 2;
  const hasBackwardButton = page > 1;
  const hasForwardButton = currentPage + 4 <= totalPages;
  const buttons = [];

  for (page; page <= currentPage + 2; page += 1) {
    if (page > 0 && page <= totalPages) {
      buttons.push(
        <button
          key={page}
          type="button"
          value={page}
          onClick={e => onChangePage(e.target.value)}
          className={`h5 mx-1 pagination ${
            page === currentPage ? 'selected' : ''
          }`}
        >
          {page}
        </button>
      );
    }
  }



  return (
    <Container className="col-12 mt-3 d-flex align-items-center">
      {hasBackwardButton && (
        <button
          type="button"
          onClick={() => onChangePage(currentPage - 4)}
          className="pagination mr-1"
        >
          <FaBackward size={16} color="#2E2D7B" />
        </button>
      )}
      {buttons}
      {hasForwardButton && (
        <button
          type="button"
          onClick={() => onChangePage(currentPage + 4)}
          className="pagination ml-1"
        >
          <FaForward size={16} color="#2E2D7B" />
        </button>
      )}
    </Container>
  );
}

export default Pagination;

Pagination.propTypes = {
  currentPage: PropTypes.number.isRequired,
  totalPages: PropTypes.number.isRequired,
  onChangePage: PropTypes.func.isRequired,
};
