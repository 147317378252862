import React from 'react';
import {
  FaFacebookF,
  FaInstagram,
  FaPlay,
  FaLinkedinIn,
  FaTwitter,
} from 'react-icons/fa';
import { Link } from 'react-router-dom';
import { Container, Copyright } from './styles';
import logo from '../../assets/logo.png';
import GDPRPanel from '../GDPRPanel';

function Footer() {
  return (
    <>
      <Container>
        <div className="container py-3">
          <div className="row w-100 m-0 justify-content-center logos-icones mb-2">
            <div className="d-flex">
              <a
                href="https://www.facebook.com/Converg%C3%AAncia-Brasil-102857425216072"
                rel="noreferrer"
                target="_blank"
                className="mx-2"
              >
                <FaFacebookF
                  title="Facebook Convergência Brasil"
                  size={26}
                  color="#0F9244"
                />
              </a>
              <a
                href="https://www.instagram.com/brconvergencia/"
                rel="noreferrer"
                target="_blank"
                className="icon mx-2"
              >
                <FaInstagram
                  title="Instagram Convergência Brasil"
                  size={26}
                  color="#0F9244"
                />
              </a>
              <a
                href="https://www.youtube.com/channel/UCMH-G5tlsCY2D-qysDCHYrw"
                rel="noreferrer"
                target="_blank"
                className="icon mx-2"
              >
                <FaPlay
                  title="Canal YouTube Convergência Brasil"
                  size={20}
                  color="#0F9244"
                />
              </a>
              <a
                href="https://twitter.com/brconvergencia"
                rel="noreferrer"
                target="_blank"
                className="icon mx-2"
              >
                <FaTwitter
                  title="Twitter Convergência Brasil"
                  size={20}
                  color="#0F9244"
                />
              </a>
              <a
                href="https://www.linkedin.com/company/brconvergencia/"
                rel="noreferrer"
                target="_blank"
                className="icon mx-2"
              >
                <FaLinkedinIn
                  title="LinkedIn Convergência Brasil"
                  size={26}
                  color="#0F9244"
                />
              </a>
            </div>
          </div>
          <div className="row">
            <div className="col">
              <Link to={`${process.env.PUBLIC_URL}`}>
                <img src={logo} alt="Logo Manifesto Convergência Brasil" />
              </Link>
            </div>
            <div className="col d-flex flex-wrap">
              <p className="ml-auto mt-auto email">
                E-mail: manifestoconvergenciabrasil@gmail.com
              </p>
              <div className=" ml-auto my-auto postion-relative">
                <div className="d-none hamburguer ">
                  <span />
                  <span />
                  <span />
                </div>
              </div>
            </div>
          </div>
        </div>
      </Container>
      <Copyright className="text-center py-2">
        Copyright © 2021 criação: amz|mp
      </Copyright>
      <GDPRPanel />
    </>
  );
}

export default Footer;
