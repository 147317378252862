import React, { useState } from 'react';
import { Form } from '@rocketseat/unform';
import * as Yup from 'yup';
import swal from 'sweetalert2';
import CpfMask from '../CpfMask';
import api from '../../services/api';
import { Container } from './styles';

const schema = Yup.object().shape({
  cpf: Yup.string()
    .test('test-name', 'O CPF é inválido', value => {
      const cpfDigitos = value.replace(/[^\d]+/g, '');
      let i;
      let Soma;
      let Resto;
      let peso = 10;
      Soma = 0;

      const invalidos = [
        '00000000000',
        '11111111111',
        '22222222222',
        '33333333333',
        '44444444444',
        '55555555555',
        '66666666666',
        '77777777777',
        '88888888888',
        '99999999999',
      ];
      if (String(cpfDigitos).length < 11) return false;

      if (invalidos.includes(cpfDigitos)) return false;

      for (i = 1; i <= 9; i++) {
        Soma += parseInt(cpfDigitos.substring(i - 1, i)) * peso;
        peso--;
      }
      Resto = (Soma * 10) % 11;

      if (Resto === 10 || Resto === 11) Resto = 0;
      if (Resto !== parseInt(cpfDigitos.substring(9, 10))) return false;

      Soma = 0;
      peso = 11;
      for (i = 1; i <= 10; i++) {
        Soma += parseInt(cpfDigitos.substring(i - 1, i)) * peso;
        peso--;
      }
      Resto = (Soma * 10) % 11;

      if (Resto === 10 || Resto === 11) Resto = 0;
      if (Resto !== parseInt(cpfDigitos.substring(10, 11))) return false;
      return true;
    })
    .required('O CPF é obrigatório'),
});

function RemoverAssinatura() {
  const [loading, setLoading] = useState(false);

  async function handleSubmit({ cpf }, { resetForm }) {
    setLoading(true);
    const formData = new URLSearchParams();

    formData.append('NmCpf', cpf);

    try {
      const response = await api.post('assinatura/removeSignature', formData);
      if (response) {
        swal
          .fire({
            title: 'Bom Trabalho!',
            text: 'Assinatura removida com sucesso!',
            icon: 'success',
          })
          .then(_ => {
            resetForm();
          });
      }
    } catch (error) {
      swal.fire({
        title: 'Ocorreu um erro',
        text: error.response.data.erro,
        icon: 'error',
      });
    }
    setLoading(false);
  }

  return (
    <>
      <Container>
        <Form schema={schema} onSubmit={handleSubmit}>
          <div className="row mx-0 mb-2">
            <label className="text-blue text-uppercase my-3 mb-3" htmlFor="cpf">
              CPF*
            </label>
            <CpfMask mask="999.999.999-99" name="cpf" id="cpf" />
          </div>
          <div className="col-12 py-3">
            <div className="row my-4 mx-0 flex-nowrap">
              <span className="barra d-flex">
                <div className="green" />
              </span>
              <div className="blue">
                <button
                  type="submit"
                  dissable={loading}
                  className="btn btn-assinar px-4 text-white font-weight-bold m-0"
                >
                  {loading ? (
                    <div className="spinner-border" role="status">
                      <span className="sr-only" />
                    </div>
                  ) : (
                    'CONFIRMAR'
                  )}
                </button>
              </div>
            </div>
          </div>
        </Form>
      </Container>
    </>
  );
}

export default RemoverAssinatura;
