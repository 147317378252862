import styled from 'styled-components';

export const Container = styled.div`
  .setas-up-down,
  .setas-right-left {
    width: 52px;
    height: 52px;
    justify-content: center;
    align-items: center;
    display: flex;
  }
  .setas-up-down {
    flex-direction: column;
    span {
      background-color: #2e2d7b;
    }
  }

  .setas-right-left {
    span {
      background-color: #0f9244;
    }
  }
  .seta-up,
  .seta-down,
  .seta-right,
  .seta-left {
    position: relative;
    transform-origin: center center;
    width: 26px;
    height: 26px;
    span {
      position: absolute;
      height: 6px;
      width: 26px;
    }
    span:nth-child(1) {
      left: 8px;
      transform: rotate(45deg);
    }

    span:nth-child(2) {
      top: 7.5px;
      left: 0.53px;
      width: 25px;
      transform: rotate(90deg);
    }

    span:nth-child(3) {
      left: -7.5px;
      transform: rotate(-45deg);
    }
  }
  .seta-up {
    transform: rotate(180deg);
    animation: goDown 2s infinite;
  }
  .seta-down {
    animation: goUp 2s infinite;
    span:nth-child(2) {
      left: 0.63px;
    }
  }
  .seta-right {
    animation: goLeft 2s infinite;
    transform: rotate(90deg);
  }
  .seta-left {
    animation: goRight 2s infinite;
    transform: rotate(-90deg);
  }

  @keyframes goDown {
    0% {
      top: -40px;
      opacity: 0.6;
    }
    10% {
      opacity: 1;
    }
    60% {
      opacity: 0.8;
    }
    100% {
      top: -10px;
      opacity: 0;
    }
  }
  @keyframes goUp {
    from {
      top: 40px;
      opacity: 0.6;
    }
    10% {
      opacity: 1;
    }
    60% {
      opacity: 0.8;
    }
    to {
      top: 10px;
      opacity: 0;
    }
  }
  @keyframes goLeft {
    from {
      left: -40px;
      opacity: 0.6;
    }

    10% {
      opacity: 1;
    }
    60% {
      opacity: 0.8;
    }
    to {
      left: -10px;
      opacity: 0;
    }
  }
  @keyframes goRight {
    from {
      right: -40px;
      opacity: 0.6;
    }
    10% {
      opacity: 1;
    }
    60% {
      opacity: 0.8;
    }
    to {
      right: -10px;
      opacity: 0;
    }
  }
`;
