import styled from 'styled-components';

export const Container = styled.div`
  text-align: left;
  background-color: #eeeeee;
  h4 {
    font-weight: 900;
    font-size: 4rem;
    margin-bottom: 2rem;
  }
  label span {
    font-size: 24px;
    vertical-align: middle;
    margin-left: -1px;
  }
  .barra {
    height: 12px;
    width: 340px;

    div {
      width: 50%;
      transform: skewX(-25deg);
    }
  }
  .green {
    background-color: #129346;
  }
  .bg-blue {
    background-color: #2e2d7b;
  }

  @media screen and (max-width: 767px) {
    .barra {
      width: 300px;
    }
    h4 {
      font-size: 3rem;
      margin-bottom: 0;
    }
  }
  @media screen and (min-width: 767px) and (max-width: 992px) {
    h4 {
      font-size: 3rem;
    }
  }
  label {
    font-weight: 600;
    color: #4a4a4a;
  }

  #messageContato:focus + .input-label {
    color: #2e2d7b !important;
  }

  .input-contato:focus + label {
    color: #2e2d7b !important;
  }

  button {
    border-radius: 0px;
    transform: skewX(25deg);
    width: 100%;
    height: 100%;
    font-size: 1.25rem;
    outline: none;
  }
  button:focus {
    outline: 0 !important;
  }
  .blue {
    transform: skewX(-25deg);
    background-color: #2e2d7b;
    width: -moz-available;
    width: -webkit-fill-available;
    height: 48px;
  }
  .input-contato,
  #messageContato {
    background: none;
    border: none;
    padding: 0 10px;
  }
  .campo-nome,
  .campo-email {
    height: max-content;
  }
  #messageContato {
    height: inherit;
    height: -webkit-fill-available;
    resize: none;
  }
  .campo-message {
    height: 240px;
  }
  .barra-btn {
    height: 48px;
    width: 22.5px;

    div {
      width: 100%;
      transform: skewX(-25deg);
    }
  }
  .green {
    background-color: #129346;
  }
  .yellow {
    background-color: #f2ca10;
  }

  .campo {
    border-radius: 6px;
    border-color: #129346;
    border: 1px solid #129346;
    padding: 1rem;
    background-color: #fff;
    margin: 1rem;
    flex-direction: column-reverse;
    display: flex;
  }

  @media screen and (min-width: 768px) {
    .campo-nome {
      margin-left: 0;
    }

    .campo-email {
      margin-right: 0;
    }
  }
  @media screen and (max-width: 767px) {
    .campo-nome,
    .campo-email {
      margin-left: 0;
      margin-right: 0;
    }
    button {
      font-size: 2rem;
      padding: 0;
    }
    .barra-btn {
      height: 48px;
      width: 29px;
    }
  }

  @media screen and (min-width: 1440px) {
  }
`;
