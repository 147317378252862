import styled from 'styled-components';

export const Container = styled.div`
  h2 {
    font-weight: 900;
    font-size: 4rem;
    margin-bottom: 2rem;
  }
  a,
  a:active,
  a:hover {
    text-decoration: none;
    color: #2e2d7b;
  }
  .barra {
    height: 12px;
    width: 340px;

    div {
      width: 50%;
      transform: skewX(-25deg);
    }
  }
  .green {
    background-color: #129346;
  }
  .bg-blue {
    background-color: #2e2d7b;
  }
  @media screen and (min-width: 767px) {
    .d-none-md {
      display: none;
    }
  }
  @media screen and (max-width: 767px) {
    text-align: center;
    h2 {
      font-weight: 900;
      font-size: 3rem;
      margin-bottom: 1.5rem;
    }
    .img-convergencia {
      display: none;
    }
    .img-convergencia-mobile {
      max-width: 345px;
    }
    .barra {
      width: 300px;
    }
  }
  @media screen and (min-width: 767px) and (max-width: 992px) {
    h2 {
      font-size: 3rem;
    }
  }
  @media screen and (min-width: 767px) and (max-width: 992px) {
    h2 {
      font-size: 3rem;
    }
  }
`;
