import axios from 'axios';

const api = axios.create({
  baseURL: 'https://convergenciabrasil.com.br/backend',
  /* 
  baseURL: 'http://br1000.teste.website/~conv5081/backend/',
  baseURL: 'http://localhost:8080/convergencia-brasil/back',
  baseURL: 'https://amzmp.com.br/cliente/convergencia-brasil-back/'
  */
});

export default api;
