import styled from 'styled-components';

export const Container = styled.div`
  z-index: 1002;

  background-color: #fff;
  a {
    text-decoration: none !important;
  }
  a:active,
  a:hover {
    color: #129346;
  }
  .nav-link:hover + .barra {
    display: flex;
  }
  .text-green {
    color: #0c6f35 !important;
  }
  .barra {
    height: 12px;
    width: 16px;
    display: none;

    div {
      width: 50%;
      transform: skewX(-25deg);
    }
  }
  .green {
    background-color: #129346;
  }
  .blue {
    background-color: #2e2d7b;
  }

  .menu-btn {
    background-color: transparent;
    border: none;
    margin-top: 0 !important;
    cursor: pointer;
    &:active,
    &:focus {
      outline: none;
    }
    &:after {
      content: '';
      display: block;
      position: absolute;
      top: 8px;
      left: 12px;
      background: #333;
      border-radius: 50%;
      width: 65px;
      height: 15px;
      transition: all 0.2s;
      opacity: 0;
    }
    &__icon {
      position: relative;
      width: 50px;
      height: 15px;
      margin: 0 auto;
      cursor: pointer;
      span span {
        background-color: #ec6839;
        transform: skewX(-25deg);
      }
    }
  }

  .icon {
    position: absolute;
    top: 0;
    left: 0;
  }

  .icon--menu {
    span {
      display: block;
      width: 48px;
      height: 10px;
      background: #333;
      transition: all 0.18s;
      transition-timing-function: $transition;
      transition-delay: 0.05s;
      position: absolute;
      &:nth-child(1) {
        top: -2px;
        background-color: #129346;
      }
      &:nth-child(2) {
        top: 13px;
        background-color: #2e2d7b;
        left: -5px;
      }
      &:nth-child(3) {
        top: 29px;
        background-color: #129346;
        left: -10px;
      }
    }
  }

  .is--active {
    .icon--menu {
      span:nth-child(1) {
        left: -5px;
        position: absolute;
        transform: rotate(45deg);
        transform-origin: top left;
      }
      span:nth-child(2) {
        width: 0px;
      }
      span:nth-child(3) {
        left: -5px;
        transform: rotate(-45deg);
        transform-origin: bottom left;
      }
    }
    .icon--close {
      transform: scale(1);
      opacity: 1;
    }
  }
  .d-none-md {
    display: none;
  }
  @media (max-width: 767px) {
    .logo {
      flex: 0 0 66.666667%;
      max-width: 66.666667%;
    }
    .d-none-md {
      display: flex;
    }
    .assine-contato {
      flex-direction: column;
      align-items: flex-end;
      padding: 0 1.5rem 1rem 1rem;
    }

    .dropdown-nav {
      display: flex;
      flex-direction: column-reverse;
      overflow: hidden;
      transition: right 0.4s ease-in-out;
      height: fit-content;
      right: -100vw;
      background-color: #fff;
      position: absolute;
      top: 70px;
      width: auto;

      .items-nav {
        flex-direction: column;
        .nav-item {
          justify-content: end;
          padding: 0 1rem 0 0;
        }
      }
    }
    .mobile-only {
      font-size: 14px;
    }

    .drop {
      padding-right: 2.2rem;
      right: 0;
    }
  }
  @media screen and (max-width: 421px) {
    .dropdown-nav {
      top: 16vw;
    }
    .mobile-only {
      font-size: 11px !important;
    }
  }
  @media screen and (min-width: 768px) {
    .col-logo {
      margin-bottom: -40px;
    }
    .mobile-only {
      display: none !important;
    }
  }
`;
