import styled from 'styled-components';

import Banner from '../../assets/banner-blog.jpg';

export const Container = styled.div`
  color: #2e2d7b;
  padding-top: 109px;
  background-color: #eeeeee;
  .banner {
    background-size: cover;
    background-image: url(${Banner});
    background-position: center;
    background-repeat: no-repeat;
    height: 560px;
  }
  .text-yellow {
    color: #ceac0d;
  }
  .titulo {
    font-size: 3.6rem;
    line-height: 3.2rem;
    margin-top: 3rem;
    margin-left: 8.5rem;
    width: fit-content;
  }
  .convergencia {
    font-size: 3rem;
  }
  p {
    font-weight: 600;
  }
  .titulo-capa {
    max-width: 133px;
    position: absolute;
    z-index: 9;
    margin-left: 3vw;
    -webkit-text-stroke-color: #fff;
    color: transparent;
    -webkit-text-stroke-width: 0.08vw;
    font-weight: 900;
    text-transform: uppercase;
    font-size: 6vw;
    line-height: 5.5vw;
    margin-bottom: 0;
  }
  .loader,
  .nao-encontrado {
    padding: 3rem 0;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #fff;
  }
  .loader {
    z-index: 1001;
  }
  .bg-blue {
    background-color: #2e2d7b;
  }
  .bottom-0 {
    bottom: 0;
  }

  .post-blog {
    opacity: 0.8;
    height: 100px;
    display: flex;
    align-items: center;
  }

  .blue {
    transform: skewX(-25deg);
    background-color: #2e2d7b;
    width: -webkit-fill-available;
    height: 48px;
    display: flex;
  }

  @media screen and (min-width: 992px) and (max-width: 1199px) {
    .titulo {
      font-size: 3.2rem;
      line-height: 2.8rem;
    }
    .convergencia {
      font-size: 2.3rem;
    }
  }

  @media screen and (min-width: 767px) and (max-width: 992px) {
    .post-chamada {
      font-size: 1.1rem;
    }
    padding-top: 131px;
    .titulo {
      font-size: 3.2rem;
      line-height: 2.5rem;
      margin-left: 0px;
    }
    .convergencia {
      font-size: 2.4rem;
    }
  }

  @media screen and (max-width: 767px) {
    padding-top: 112px;

    .img-post {
      max-height: 170px;
      height: 30vw;
    }

    .post-blog {
      position: absolute;
      right: 0;
      height: 100%;
      width: 55%;
      text-align: right;
      opacity: 1;
      padding-left: 0 !important;
      h5 {
        z-index: 2;
        font-size: 1.3rem;
        margin: auto 0;
      }
    }
    .post-blog:after {
      content: ' ';
      position: absolute;
      display: block;
      height: 100%;
      top: 0;
      background: #2e2d7b;
      -webkit-transform-origin: bottom left;
      -ms-transform-origin: bottom left;
      transform-origin: bottom left;
      -ms-transform: skew(-30deg, 0deg);
      -webkit-transform: skew(-30deg, 0deg);
      -webkit-transform: skew(-30deg, 0deg);
      -ms-transform: skew(-30deg, 0deg);
      transform: skew(335deg, 0deg);
      z-index: 1;
      width: 36%;
      left: -36%;
    }
    .sub-post {
      display: -webkit-box;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }
  @media screen and (min-width: 526px) and (max-width: 768px) {
    .convergencia {
      font-size: 1.8rem;
    }
    .titulo {
      font-size: 2.6rem;
      line-height: 2.1rem;
      margin-top: 3rem;
      margin-left: 0;
    }
    .banner {
      height: 480px;
    }
  }
  @media screen and (max-width: 525px) {
    .titulo {
      font-size: 9.9vw;
      line-height: 8.36vw;
      margin-top: 11.4vw;
      margin-left: 0;
    }
    .convergencia {
      font-size: 7.6vw;
    }
    .banner {
      height: 82vw;
    }
  }
  @media screen and (max-width: 486px) {
    .post-blog {
      h5 {
        font-size: 4vw;
      }
    }
  }

  .assine-manifesto {
    text-decoration: none;
  }
  a,
  a:active,
  a:hover {
    text-decoration: none;
    color: inherit;
  }

  .assine {
    transform: skewX(18deg);
    font-size: 1.15rem;
  }

  .blue {
    transform: skewX(-18deg);
    background-color: #2e2d7b;
    height: 28px;
    padding: 0 2.2rem;
  }
  .barra {
    height: 28px;
    width: 7.5px;

    div {
      width: 100%;
      transform: skewX(-18deg);
    }
  }
  .green {
    background-color: #129346;
  }
  .font-weight-black {
    font-weight: 900;
  }

  #somos {
    .assine-manifesto {
      display: none !important;
    }
  }

  @media screen and (max-width: 767px) {
    .assine {
      font-size: 1.5rem;
    }
    .blue,
    .barra {
      height: 38px;
    }
    .barra {
      width: 23px;
    }

    #somos {
      text-align: center;
      .row {
        flex-direction: column-reverse;
      }
      .assine-manifesto {
        display: -webkit-box !important;
        .blue {
          height: 34px !important;
        }
        .barra {
          height: 34px !important;
          width: 24px !important;
        }
        p {
          font-size: 1.5rem;
        }
      }
    }
  }

  @media screen and (max-width: 350px) {
    #somos {
      .assine-manifesto {
        p {
          font-size: 1.25rem;
        }
      }
    }
    .assine {
      font-size: 1.2rem;
    }
  }
`;
