/* eslint-disable prettier/prettier */
import React from 'react';
import { Switch, Route } from 'react-router-dom';

import Home from '../pages/Home/index';
import PoliticaPrivacidade from '../pages/PoliticaPrivacidade';
import Materia from '../pages/Materia';
import ManifestoIntegra from '../pages/ManifestoIntegra';
import Coordenadores from '../pages/Coordenadores';
import QuemSomos from '../pages/QuemSomos';
import Assinaturas from '../pages/Assinaturas';
import EstadaoRendaBasica from '../pages/Noticias/Estadao/RendaBasica';
import EstadaoGuedes from '../pages/Noticias/Estadao/GuedesDefendeu';
import OGlobo30AosMaisPobres from '../pages/Noticias/OGlobo/AosMaisPobres';
import ValorConterCrise from '../pages/Noticias/Valor/ConterCrise';
import VejaRendaBrasil from '../pages/Noticias/Veja/RendaBrasil';
// import FolhaDobrarBolsa from '../pages/Noticias/Folha/DobrarBolsa';
import MigalhasPoranduba from '../pages/Noticias/Migalha/PorandubasPoliticas';
import Assine from '../pages/Assine';
import Blog from '../pages/Blog';
// import Page404 from '~/pages/Page404/';

export default function Routes() {
  return (
    <Switch>
      <Route path={`${process.env.PUBLIC_URL}/`} exact component={Home} />
      <Route
        path={`${process.env.PUBLIC_URL}/assinatura-realizada`}
        exact
        component={Home}
      />
      <Route
        path={`${process.env.PUBLIC_URL}/quem-somos`}
        exact
        component={QuemSomos}
      />
      <Route
        path={`${process.env.PUBLIC_URL}/assine`}
        exact
        component={Assine}
      />
      <Route path={`${process.env.PUBLIC_URL}/blog`} exact component={Blog} />
      <Route
        path={`${process.env.PUBLIC_URL}/assinaturas`}
        exact
        component={Assinaturas}
      />
      <Route
        path={`${process.env.PUBLIC_URL}/manifesto`}
        exact
        component={ManifestoIntegra}
      />
      <Route
        path={`${process.env.PUBLIC_URL}/coordenadores`}
        exact
        component={Coordenadores}
      />
      <Route
        path={`${process.env.PUBLIC_URL}/politica-privacidade`}
        exact
        component={PoliticaPrivacidade}
      />
      <Route
        path={`${process.env.PUBLIC_URL}/blog/:slug`}
        exact
        component={Materia}
        isPrivate
      />
      <Route
        path={`${process.env.PUBLIC_URL}/noticias/empresarios-articulam-proposta-para-programa-de-renda-basica-no-pais`}
        exact
        component={EstadaoRendaBasica}
      />
      <Route
        path={`${process.env.PUBLIC_URL}/noticias/grupo-de-empresarios-quer-destinar-30-das-privatizacoes-do-governo-federal-aos-mais-pobres`}
        exact
        component={OGlobo30AosMaisPobres}
      />
      <Route
        path={`${process.env.PUBLIC_URL}/noticias/empresarios-tentam-agir-para-conter-crise`}
        exact
        component={ValorConterCrise}
      />
      <Route
        path={`${process.env.PUBLIC_URL}/noticias/renda-brasil-a-interminavel-discussao-em-torno-de-como-pagar-o-programa`}
        exact
        component={VejaRendaBrasil}
      />
      <Route
        path={`${process.env.PUBLIC_URL}/noticias/guedes-ja-defendeu-uso-de-dividendos-pagos-por-estatais`}
        exact
        component={EstadaoGuedes}
      />
      {/*       <Route path={`${process.env.PUBLIC_URL}/noticias/executivos-querem-usar-privatizacao-e-reforma-administrativa-para-dobrar-bolsa-familia`} exact component={FolhaDobrarBolsa}  />
       */}{' '}
      <Route
        path={`${process.env.PUBLIC_URL}/noticias/porandubas-n-709`}
        exact
        component={MigalhasPoranduba}
      />
      {/* <Route path={`${process.env.PUBLIC_URL}/`} component={Page404} /> */}
    </Switch>
  );
}
