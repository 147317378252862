import styled from 'styled-components';

export const Container = styled.div`
  .barra {
    height: 12px;
    width: 340px;

    div {
      width: 50%;
      transform: skewX(-25deg);
    }
  }
  .green {
    background-color: #129346;
  }

  .bg-blue {
    background-color: #2e2d7b;
  }
  .bottom-0 {
    bottom: 0;
  }

  .barra-assinatura {
    height: 48px;
    width: 22.5px;

    div {
      width: 100%;
      transform: skewX(-25deg);
    }
  }
  .blue {
    transform: skewX(-25deg);
    background-color: #2e2d7b;
    width: -moz-available;
    width: -webkit-fill-available;
    height: 48px;
    display: flex;
  }

  .ja-assinou {
    display: block;
    transform: skewX(25deg);
    width: inherit;
  }

  .num-assinatura {
    font-family: Roboto;
    font-weight: 900;
    font-size: 4rem;
    vertical-align: sub;
  }

  .assinaturas {
    vertical-align: super;
  }

  @media screen and (max-width: 767px) {
    .barra {
      width: 300px;
    }
    h2 {
      font-size: 3rem;
      margin-bottom: 1.5rem;
    }
    .ja-assinou {
      font-size: 1.25rem;
      padding: 0;
      text-transform: none !important;
    }
    .barra-assinatura {
      height: 48px;
      width: 29px;
    }
  }
`;
