import React from 'react';
import { NavHashLink } from 'react-router-hash-link';
import { Container } from './styles';
import convergencia from '../../assets/convergencia-1.png';
import setaVerde from '../../assets/seta-verde.png';

function Manifesto() {
  const scrollWithOffset = el => {
    const yCoordinate = el.getBoundingClientRect().top + window.pageYOffset;
    let yOffset = -109;
    if (window.innerWidth <= 768) {
      yOffset = -80;
    }
    window.scrollTo({ top: yCoordinate + yOffset, behavior: 'smooth' });
  };
  return (
    <Container id="manifesto">
      <div className="container">
        <span className="barra d-flex mx-auto my-4 mb-4">
          <div className="green" />
          <div className="bg-blue" />
        </span>
        <div className="row my-5">
          <img
            src={convergencia}
            alt="Convergência Brasil"
            className="col-md-6 my-auto img-convergencia"
          />
          <div className="col-md-6 d-flex flex-wrap">
            <div className="my-auto d-flex flex-wrap">
              <h2 className="text-blue text-uppercase mx-auto text-center">
                O Manifesto
              </h2>
              <img
                src={convergencia}
                alt="Convergência Brasil"
                className="col-12 my-auto d-none-md img-convergencia-mobile mx-auto pb-3"
              />
              <p className=" h5">
                Desenvolvemos um manifesto que nos une em busca de um Estado
                mais justo e eficiente e apoiamos programas de privatizações,
                reformas, principalmente a administrativa, e renda mínima para a
                população mais pobre com contrapartidas educacionais.
              </p>
              <NavHashLink
                smooth
                scroll={el => scrollWithOffset(el)}
                to={`${process.env.PUBLIC_URL}/manifesto#manifesto-na-integra`}
                className="mt-3 text-blue font-weight-bold ml-auto h5"
              >
                Leia na íntegra: O manifesto
                <img src={setaVerde} alt="Ícone Seta Verde" className="ml-2" />
              </NavHashLink>
            </div>
          </div>
        </div>
      </div>
    </Container>
  );
}

export default Manifesto;
