import React, { useState } from 'react';
import { Form, Input } from '@rocketseat/unform';
import * as Yup from 'yup';
import swal from '@sweetalert/with-react';
import swal2 from 'sweetalert2';
import { useDispatch } from 'react-redux';
import { Link, useHistory } from 'react-router-dom';
import PropTypes from 'prop-types';
import {
  FacebookShareButton,
  LinkedinShareButton,
  TwitterShareButton,
  WhatsappShareButton,
  FacebookIcon,
  TwitterIcon,
  LinkedinIcon,
  WhatsappIcon,
} from 'react-share';
import CpfMask from '../CpfMask';
import api from '../../services/api';
import { Container } from './styles';
import assinaturaIcone from '../../assets/assinatura-icone.png';
// import tag from 'gtag_report_conversion';

const schema = Yup.object().shape({
  name: Yup.string().required('O nome completo é obrigatório'),
  cpf: Yup.string()
    .test('test-name', 'O CPF é inválido', value => {
      const cpfDigitos = value.replace(/[^\d]+/g, '');
      let i;
      let Soma;
      let Resto;
      let peso = 10;
      Soma = 0;

      const invalidos = [
        '00000000000',
        '11111111111',
        '22222222222',
        '33333333333',
        '44444444444',
        '55555555555',
        '66666666666',
        '77777777777',
        '88888888888',
        '99999999999',
      ];
      if (String(cpfDigitos).length < 11) return false;

      if (invalidos.includes(cpfDigitos)) return false;

      for (i = 1; i <= 9; i++) {
        Soma += parseInt(cpfDigitos.substring(i - 1, i)) * peso;
        peso--;
      }
      Resto = (Soma * 10) % 11;

      if (Resto === 10 || Resto === 11) Resto = 0;
      if (Resto !== parseInt(cpfDigitos.substring(9, 10))) return false;

      Soma = 0;
      peso = 11;
      for (i = 1; i <= 10; i++) {
        Soma += parseInt(cpfDigitos.substring(i - 1, i)) * peso;
        peso--;
      }
      Resto = (Soma * 10) % 11;

      if (Resto === 10 || Resto === 11) Resto = 0;
      if (Resto !== parseInt(cpfDigitos.substring(10, 11))) return false;
      return true;
    })
    .required('O CPF é obrigatório'),
});

function Assine({ heading }) {
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();
  const history = useHistory();

  async function handleSubmit({ name, cpf }, { resetForm }) {
    setLoading(true);
    const formData = new URLSearchParams();
    formData.append('NmNome', name);
    formData.append('NmCpf', cpf);
    try {
      const response = await api.post('assinatura/create', formData);
      if (response) {
        window.gtag_report_conversion();
        window.pixel_report();
        history.push(`${process.env.PUBLIC_URL}/assinatura-realizada`);
        dispatch({ type: 'addNew' });
        swal({
          icon: 'success',
          title: 'Parabéns!',
          content: (
            <div>
              <p>
                Agora você apoia o movimento que luta por um Brasil mais justo e
                eficiente!
              </p>
              <p className="text-blue text-center text-uppercase">
                Compartilhe nas redes
              </p>
              <div className="row">
                <div className="col-12 d-flex justify-content-center">
                  <FacebookShareButton
                    className="mx-2"
                    url={`${window.location.origin}/assine`}
                  >
                    <FacebookIcon size={32} round />
                  </FacebookShareButton>
                  <LinkedinShareButton
                    className="mx-2"
                    url={`${window.location.origin}/assine`}
                  >
                    <LinkedinIcon size={32} round />
                  </LinkedinShareButton>
                  <TwitterShareButton
                    className="mx-2"
                    url={`${window.location.origin}/assine`}
                  >
                    <TwitterIcon size={32} round />
                  </TwitterShareButton>
                  <WhatsappShareButton
                    className="mx-2"
                    url={`${window.location.origin}/assine`}
                  >
                    <WhatsappIcon size={32} round />
                  </WhatsappShareButton>
                </div>
              </div>
            </div>
          ),
          buttons: ['Fechar', false],
        }).then(_ => {
          resetForm();
          history.push(`${process.env.PUBLIC_URL}/assinatura-realizada`);
        });

        // gtag_report_conversion('assine');
      }
    } catch (error) {
      swal2.fire('Ocorreu um erro', error.response.data.erro, 'error');
    }
    setLoading(false);
  }
  const CustomHeading = `h${heading}`;
  return (
    <>
      <Container id="assine">
        <div className="banner pb-5">
          <div className="container text-center">
            <span className="barra d-flex mx-auto mb-4">
              <div className="green" />
              <div className="bg-blue" />
            </span>
            <div className="row justify-content-center">
              <img
                src={assinaturaIcone}
                alt="Ícone Assinatura"
                className="my-auto mr-1"
              />
              <CustomHeading className="text-blue ml-1 my-auto titulo">
                Assine
              </CustomHeading>
            </div>
            <p className="texto-assinatura mx-auto h5 my-4 text-justify">
              Assine este manifesto e nos ajude a mostrar que cada vez mais a
              sociedade está em busca de uma convergência para um país melhor
              para todos os brasileiros.
            </p>
            <Form schema={schema} onSubmit={handleSubmit}>
              <div className="row mx-0">
                <label
                  className="text-blue text-uppercase my-3 mb-3"
                  htmlFor="name"
                >
                  Nome Completo <span>*</span>
                </label>
                <Input
                  name="name"
                  type="text"
                  className="col-12 input-assinatura"
                />
              </div>

              <div className="row mx-0 mb-2">
                <label
                  className="text-blue text-uppercase my-3 mb-3"
                  htmlFor="cpf"
                >
                  CPF <span>*</span>
                </label>
                <CpfMask mask="999.999.999-99" name="cpf" id="cpf" />
              </div>
              <div className="col-12 py-3">
                <div className="row my-4 mx-0 flex-nowrap">
                  <span className="barra-btn d-flex">
                    <div className="green" />
                  </span>
                  <div className="blue">
                    <button
                      type="submit"
                      dissable={loading.toString()}
                      className="btn btn-assinar px-4 text-white font-weight-bold m-0"
                    >
                      {loading ? (
                        <div className="spinner-border" role="status">
                          <span className="sr-only" />
                        </div>
                      ) : (
                        'ASSINE'
                      )}
                    </button>
                  </div>
                </div>
              </div>
            </Form>
            <Link
              to={`${process.env.PUBLIC_URL}/politica-privacidade`}
              className="text-blue h5 font-weight-bold lgpd"
            >
              LGPD | clique aqui e leia nossa política de segurança de dados.
            </Link>
          </div>
        </div>
      </Container>
    </>
  );
}

export default Assine;
Assine.propTypes = {
  heading: PropTypes.number.isRequired,
};
