import styled from 'styled-components';

export const Container = styled.div`
  .titulo {
    font-weight: 900;
    font-size: 4rem;
    margin-bottom: 2rem;
  }
  .barra {
    height: 12px;
    width: 340px;

    div {
      width: 50%;
      transform: skewX(-25deg);
    }
  }
  .green {
    background-color: #129346;
  }
  .bg-blue {
    background-color: #2e2d7b;
  }
  .heading-materia {
    max-width: 133px;
    position: absolute;
    z-index: 9;
    margin-left: 27px;
    -webkit-text-stroke-color: #fff;
    color: transparent;
    -webkit-text-stroke-width: 1px;
    font-weight: 900;
    text-transform: uppercase;
    font-size: 57px;
    line-height: 54px;
    margin-bottom: 0;
  }

  .post-blog-principal {
    background-color: #2e2d7b;
    opacity: 0.8;
    position: absolute;
    top: 0;
    right: 1rem;
    height: -webkit-fill-available;
    width: 29%;
    height: 100%;
  }
  .post-blog-principal:after {
    content: ' ';
    position: absolute;
    display: block;
    width: 60%;
    height: 99.9%;
    top: 0;
    left: -54%;
    z-index: -1;
    background: #2e2d7b;
    -webkit-transform-origin: bottom left;
    -ms-transform-origin: bottom left;
    transform-origin: bottom left;
    -ms-transform: skew(-30deg, 0deg);
    -webkit-transform: skew(-30deg, 0deg);
    -webkit-transform: skew(-30deg, 0deg);
    -ms-transform: skew(-30deg, 0deg);
    transform: skew(335deg, 0deg);
  }

  .bg-blue {
    background-color: #2e2d7b;
  }
  .bottom-0 {
    bottom: 0;
  }

  .post-blog {
    opacity: 0.8;
    height: 100px;
    display: flex;
    align-items: center;
  }

  .barra-assinatura {
    height: 48px;
    width: 45px;

    div {
      width: 50%;
      transform: skewX(-25deg);
    }
  }
  .blue {
    transform: skewX(-25deg);
    background-color: #2e2d7b;
    width: -webkit-fill-available;
    height: 48px;
    display: flex;
  }

  .ja-assinou {
    display: block;
    transform: skewX(25deg);
    width: inherit;
  }

  .num-assinatura {
    font-family: Roboto;
    font-weight: 900;
    font-size: 4rem;
    vertical-align: sub;
  }

  .assinaturas {
    vertical-align: super;
  }
  @media screen and (min-width: 767px) and (max-width: 991px) {
    .post-principal-chamada {
      font-size: 1.2rem;
    }

    .post-chamada {
      font-size: 1.1rem;
    }

    .post-blog-principal {
      p {
        display: -webkit-box;
        -webkit-line-clamp: 3;
        -webkit-box-orient: vertical;
        overflow: hidden;
        text-overflow: ellipsis;
      }
    }
    .heading-materia {
      margin-left: 20px;
      font-size: 41px;
      line-height: 40px;
    }
  }
  @media screen and (max-width: 767px) {
    .barra {
      width: 300px;
    }
    .titulo {
      font-size: 3rem;
      margin-bottom: 1.5rem;
    }
    .ja-assinou {
      font-size: 1.25rem;
      padding: 0;
      text-transform: none !important;
    }
    .barra-assinatura {
      height: 48px;
      width: 58px;
    }
    .post-blog-principal, .post-blog {
      width: 49%;
      max-width: 49%;
      opacity: 1;

      h3 {
        z-index: 2;
        font-size: 1.3rem;
        margin: auto 0;
      }
      p {
        display: none;
      }
    }
    .post-blog-principal:after {
      z-index: 1;
      width: 35%;
      left: -35%;
    }

    .post-blog {
      position: absolute;
      right: 0;
      height: 100%;
      text-align: right;
      opacity: 1;
      padding: 0 1.5rem 0 0 !important;
      h3 {
        z-index: 2;
        font-size: 1.3rem;
        margin: auto 0;
      }
      h5 {
        z-index: 2;
        font-size: 4vw;
      }
    }
    .post-blog:after {
      content: ' ';
      position: absolute;
      display: block;
      height: 100%;
      top: 0;
      background: #2e2d7b;
      transform-origin: bottom left;
      transform: skew(335deg, 0deg);
      z-index: 1;
      width: 36%;
      left: -36%;
    }
    .sub-post,
    .sub-post-principal {
      display: -webkit-box;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
      overflow: hidden;
      text-overflow: ellipsis;
    }
    .heading-materia {
      line-height: 29px;
      font-size: 29px;
      margin: 17px;
      display: block;
    }
  }
  @media screen and (min-width: 1200px) {
    .heading-materia {
      font-size: 67px;
      line-height: 62px;
    }
  }
  @media screen and (min-width: 767px) {
    .link-post-principal,
    .sub-post-principal {
      display: none;
    }
  }
  @media screen and (max-width: 720px) {
    .post-principal {
      margin-bottom: 0 !important;
    }
  }
   @media screen and (max-width: 575px) {
    .heading-materia {
      margin-left: 10px;
      line-height: 5.5vw;
      font-size: 5.5vw;
    }
    .post-blog-principal, .post-blog {
      max-width: 44%;
    }
  }
  @media screen and (max-width: 486px) {
    .post-blog {
      h3 {
        font-size: 4vw;
      }
    }
    .post-blog-principal, .post-blog {
      h3 {
        font-size: 4vw;
      }
    }
  }

  @media screen and (max-width: 340px) {
    .heading-materia {
      font-size: 5vw;
    }
  }
`;
