import React from 'react';
import { Helmet } from 'react-helmet';
import { Container } from './styles';
import setaVerde from '../../../../assets/seta-verde.png';

function RendaBasica() {
  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>
          Convergência Brasil - Guedes já defendeu uso de dividendos pagos por
          estatais
        </title>
        <meta
          name="description"
          content="Movimento Convergência Brasil, lançado no ano passado, propõe direcionar às famílias uma parcela dos recursos obtidos com as privatizações e com a reforma administrativa, que começou a ser discutida no Congresso"
        />
      </Helmet>

      <Container>
        <div className="container" id="somos">
          <div className="row">
            <div className="col-12">
              <h1 className="font-weight-bold">
                Empresários articulam proposta para programa de renda básica no
                País
              </h1>
              <h2 className="">
                Movimento Convergência Brasil, lançado no ano passado, propõe
                direcionar às famílias uma parcela dos recursos obtidos com as
                privatizações e com a reforma administrativa, que começou a ser
                discutida no Congresso
              </h2>
              <div className="">
                {' '}
                <div className="">
                  {' '}
                  Idiana Tomazelli, O Estado de S.Paulo{' '}
                </div>{' '}
                <p> 22 de março de 2021 | 05h00 </p>{' '}
              </div>
              <p>
                {' '}
                Empresários articulam proposta para programa de renda básica no
                País
              </p>
              <p>Guedes já defendeu uso de dividendos pagos por estatais</p>
              <p>
                BRASÍLIA - A retomada do auxílio emergencial e a discussão sobre
                como reforçar as políticas sociais no segundo semestre do ano
                levaram um grupo de executivos a lançar uma nova ofensiva junto
                a parlamentares e integrantes do governo para tentar destravar
                reformas e, ao mesmo tempo, tornar viável um programa de renda
                básica. A proposta é direcionar às famílias uma parte dos
                recursos obtidos com as privatizações e com a reforma
                administrativa, que tende a reduzir os gastos com o RH do
                Estado.{' '}
              </p>

              <p>
                Lançado no ano passado, o Movimento Convergência Brasil retomou
                as articulações após a definição para o comando do Congresso
                Nacional ter desanuviado o ambiente para o andamento das
                reformas. Com a aprovação da PEC emergencial, que recriou o
                auxílio a vulneráveis e instituiu mecanismos constitucionais de
                contenção de despesas, o presidente da Câmara, Arthur Lira
                (PP-AL), colocou a reforma administrativa como uma das
                prioridades e designou o relator do texto na Comissão de
                Constituição e Justiça (CCJ) – onde ocorre o primeiro estágio da
                tramitação.{' '}
              </p>

              <p>
                Trazido à tona pela pandemia, debate sobre renda básica precisa
                ir além da forma de financiamento, afirmam especialistas{' '}
              </p>

              <p>
                O grupo é liderado por Elvaristo do Amaral e tem como apoiadores
                Luiza Helena Trajano, presidente do conselho de administração do
                Magazine Luiza, Jorge Gerdau Johannpeter, presidente do conselho
                de administração do Grupo Gerdau, Hélio Magalhães, ex-presidente
                do Citi e presidente do conselho de administração do Banco do
                Brasil, Helena Nader, vice-presidente da Academia Brasileira de
                Ciências, Paulo Hartung, ex-governador do Espírito Santo, e
                Fabio Barbosa, membro do Conselho das Nações Unidas, entre
                outros empresários e personalidades.{' '}
              </p>

              <p>Luiza Trajano </p>
              <p>
                Luiza Trajano: 'Nosso propósito é fazer um Brasil melhor de
                acordo com várias premissas. Uma delas é a união em momentos de
                dificuldade' Foto: Felipe Rau/Estadão{' '}
              </p>
              <p>
                “Precisamos criar um programa complementar de renda de longo
                prazo aos mais necessitados”, afirma Amaral. “Não se está
                fazendo favor, simplesmente. Não está se dando esmola para
                ninguém. Estamos fazendo um programa social de resgate do
                cidadão que hoje está no desespero.”{' '}
              </p>

              <p>
                Representantes do grupo conversaram recentemente com o
                vice-presidente da Câmara, Marcelo Ramos (PL-AM), sobre a
                proposta. Eles elaboraram uma emenda a ser apresentada à
                comissão que discutirá o conteúdo da reforma administrativa. O
                objetivo é vincular 30% da economia obtida com a proposta a um
                programa de transferência de renda a vulneráveis.{' '}
              </p>

              <p>
                Estimativas. Cálculos do Instituto de Pesquisa Econômica
                Aplicada (Ipea) divulgados em setembro apontam que iniciativas
                para reduzir a reposição de servidores daqui para frente, cortar
                salários iniciais para novos entrantes e alongar os degraus das
                carreiras podem resultar em impactos de R$ 202,5 bilhões a R$
                318,5 bilhões em dez anos, a depender do alcance das medidas. O
                valor considera a economia obtida por União, Estados e
                municípios.{' '}
              </p>

              <p>
                Amaral chega a citar números mais generosos, perto de R$ 800
                bilhões, pois é defensor da inclusão dos atuais servidores e dos
                membros dos demais Poderes, como juízes, procuradores,
                desembargadores e parlamentares. Todos esses grupos ficaram de
                fora do texto encaminhado pelo governo Bolsonaro no ano passado.
                Para o coordenador do grupo, é preciso acabar com
                “penduricalhos” em salários do serviço público, sobretudo num
                momento em que a administração tem poucos recursos e há famílias
                que precisam do apoio estatal.{' '}
              </p>

              <p>
                A mesma vinculação seria feita com recursos a serem obtidos com
                as privatizações. Na avaliação de Amaral, o governo deveria
                colocar à venda todas as suas empresas – inclusive Petrobrás e
                Banco do Brasil, cuja privatização já foi descartada por
                Bolsonaro. Como potencial de arrecadação, ele cita valores de R$
                500 bilhões até o R$ 1 trilhão já pregado pelo ministro da
                Economia, Paulo Guedes. Nessa proposta, 30% das receitas obtidas
                seriam colocadas em um fundo financeiro administrado pelo BNDES.
                A cada ano, 5% ou 10% seriam sacados para bancar o programa de
                renda básica. O porcentual seria calculado de forma a garantir
                longa duração à política.{' '}
              </p>

              <p>
                “O esforço do Convergência é atrair as forças políticas
                divergentes para convergir para uma proposta concreta. Colocamos
                de um lado da equação a reforma administrativa e as
                privatizações, que teoricamente seriam um problema meramente
                econômico, e seria uma posição de pessoas de centro ou de
                direita. Do outro lado, se coloca o atendimento de uma demanda
                social imediata e urgente da sociedade brasileira que precisa
                ser atendida, é inescapável, e que, em teoria, deveria atrair
                pessoas de centro-esquerda”, afirma Amaral.{' '}
              </p>

              <p>
                O coordenador do movimento reconhece que alguns detalhes
                operacionais ainda precisam ser discutidos, como a harmonização
                da proposta com o mecanismo do teto de gastos, que limita o
                avanço das despesas à inflação, sobretudo no caso do uso das
                receitas obtidas com privatizações para elevar o gasto social.
                Mas ele diz acreditar que, com um sinal de compromisso do
                Congresso com as reformas, seria plausível encontrar um desenho
                que torne o programa viável. “Não é possível que não se encontre
                uma saída que não seja danosa às políticas fiscal e monetária”,
                afirma.
              </p>

              <p>
                Amaral reconhece que, além de um fator social, os empresários
                também se beneficiarão de uma política de renda básica mais
                robusta. “A criação de uma renda básica para os mais vulneráveis
                será altamente benéfica para toda a sociedade, inclusive para os
                empresários. Uma renda complementar de caráter permanente
                gradualmente vai sendo incorporada ao mercado consumidor, e os
                empresários muito corretamente estão dispostos a produzir. Isso
                gera mais crescimento, mais emprego”, afirma.
              </p>

              <a
                href="https://economia.estadao.com.br/noticias/geral,empresarios-articulam-proposta-para-programa-de-renda-basica-no-pais,70003655453"
                rel="noreferrer"
                target="_blank"
                className="mt-1 text-blue font-weight-bold d-block text-end h6 link-materia"
              >
                Matéria completa
                <img src={setaVerde} alt="Ícone Seta Verde" className="ml-2" />
              </a>
            </div>
          </div>
        </div>
      </Container>
    </>
  );
}

export default RendaBasica;
