import React from 'react';
import { Helmet } from 'react-helmet';
import { Container } from './styles';
import setaVerde from '../../../../assets/seta-verde.png';

function PorandubasPoliticas() {
  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>
          Convergência Brasil - Empresários querem usar privatização e reforma
          administrativa para dobrar Bolsa Família
        </title>
        <meta
          name="description"
          content="Luiza Trajano e Helena Nader estão entre organizadores do movimento; projeto de lei pode garantir R$ 240 bilhões para os próximos dez anos"
        />
      </Helmet>

      <Container>
        <div className="container" id="somos">
          <div className="row">
            <div className="col-12 text-justify">
              <h1 className="font-weight-bold">Porandubas nº 709</h1>
              <h2 className="">Renda básica e vacinas</h2>
              <div className="mb-3">
                <span className="font-weight-bold">Gaudêncio Torquato</span>
                <br />
                <span>quarta-feira, 24 de março de 2021</span>
              </div>
              <p>
                O Movimento Convergência, que agrega importantes figuras da área
                econômica, lança ofensiva junto aos Poderes Executivo e
                Legislativo para viabilizar um programa de renda básica. Os
                recursos viriam das privatizações e da reforma administrativa,
                considerada central para reduzir gastos com recursos humanos do
                Estado. Outro Movimento, reunindo banqueiros e economistas, faz
                uma Carta Aberta aos Poderes da República cobrando a adoção de
                medidas efetivas contra a Covid-19. As lideranças parecem
                despertar da letargia. Estão apavoradas. A carta dos economistas
                foi rechaçada pelo presidente.
              </p>
              <a
                href="https://www.migalhas.com.br/coluna/porandubas-politicas/342267/porandubas-n-709"
                rel="noreferrer"
                target="_blank"
                className="mt-1 text-blue font-weight-bold d-block text-end h6 link-materia"
              >
                Matéria completa
                <img src={setaVerde} alt="Ícone Seta Verde" className="ml-2" />
              </a>
            </div>
          </div>
        </div>
      </Container>
    </>
  );
}

export default PorandubasPoliticas;
