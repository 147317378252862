import React from 'react';
import { Helmet } from 'react-helmet';
import { Container } from './styles';
import setaVerde from '../../../../assets/seta-verde.png';
import Guedes from '../../../../assets/noticias/estadao/guedes.jpg';

function GuedesDefendeu() {
  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>
          Convergência Brasil - Guedes já defendeu uso de dividendos pagos por
          estatais{' '}
        </title>
        <meta
          name="description"
          content="Resistência do presidente Jair Bolsonaro a outras medidas do “pacote” planejado pelo ministro da Economia adiou o debate"
        />
      </Helmet>

      <Container>
        <div className="container" id="somos">
          <div className="row">
            <div className="col-12">
              <h1 className="font-weight-bold">
                Guedes já defendeu uso de dividendos pagos por estatais
              </h1>
              <h2 className="">
                Resistência do presidente Jair Bolsonaro a outras medidas do
                “pacote” planejado pelo ministro da Economia adiou o debate
              </h2>
              <div className="">
                {' '}
                <div className="">
                  {' '}
                  Idiana Tomazelli, O Estado de S.Paulo{' '}
                </div>{' '}
                <p> 22 de março de 2021 | 05h00 </p>{' '}
              </div>
              <section className="col-xs-12 col-content col-center ">
                {' '}
                <div className="content">
                  {' '}
                  <p>
                    BRASÍLIA - A ideia de vincular recursos obtidos com o
                    processo de privatizações de empresas estatais a um programa
                    de renda básica, como defende o{' '}
                    <a
                      href="https://economia.estadao.com.br/noticias/geral,empresarios-se-unem-para-trocar-reformas-por-fundos-para-o-bolsa-familia,70003475087"
                      rel="noreferrer"
                      target="_blank"
                    >
                      <strong>Movimento Convergência Brasil</strong>
                    </a>
                    , é semelhante a uma proposta já analisada pela equipe do{' '}
                    <a
                      href="https://tudo-sobre.estadao.com.br/paulo-guedes"
                      rel="noreferrer"
                      target="_blank"
                    >
                      <strong>ministro da Economia, Paulo Guedes</strong>
                    </a>
                    , de destinar os dividendos pagos por essas companhias
                    públicas a um fundo que bancaria políticas sociais.{' '}
                  </p>
                  <div />
                  <p>
                    Guedes já havia cogitado a ideia no ano passado, no auge das
                    discussões para tirar do papel o chamado Renda Brasil – que
                    seria um sucessor mais robusto do programa assistencial{' '}
                    <a
                      href="https://tudo-sobre.estadao.com.br/bolsa-familia"
                      rel="noreferrer"
                      target="_blank"
                    >
                      <strong>Bolsa Família</strong>
                    </a>
                    . No entanto, resistências do presidente Jair Bolsonaro a
                    outras medidas do “pacote”, como a revisão de despesas com
                    seguro-desemprego e congelamento do valor de aposentadorias,
                    acabaram adiando o debate do{' '}
                    <a
                      href="https://tudo-sobre.estadao.com.br/renda-brasil"
                      rel="noreferrer"
                      target="_blank"
                    >
                      <strong>Renda Brasil</strong>
                    </a>
                    .{' '}
                  </p>
                  <p />
                  <div>
                    <figure className=" text-center">
                      <img src={Guedes} alt="Paulo Guedes" />
                      <figcaption className="caption">
                        'Ou paga dividendos para os mais pobres ou vende (a
                        Petrobrás)', disse Paulo Guedes no início deste mês
                        Foto: Marcelo Camargo/Agência Brasil
                      </figcaption>
                    </figure>
                  </div>{' '}
                  <p>
                    No início do mês, com a polêmica em torno da política de
                    preços da{' '}
                    <a
                      href="https://tudo-sobre.estadao.com.br/petrobras"
                      rel="noreferrer"
                      target="_blank"
                    >
                      <strong>Petrobrás</strong>
                    </a>{' '}
                    e a interferência feita pelo próprio Bolsonaro para mudar o
                    comando da companhia, Guedes voltou ao assunto.{' '}
                  </p>
                  <p>
                    “É o seguinte: ou paga dividendos para os mais pobres ou
                    vende. Não pode ficar dando prejuízo”, afirmou Guedes em
                    declaração no início de março. “Tem uma turma que começa com
                    ‘o petróleo é nosso’, então pega os mais pobres e vamos dar
                    um pedaço para eles. Temos a ideia de fazer algo parecido um
                    pouco à frente, criar um fundo e colocar ativos lá,
                    principalmente para os mais frágeis. Vamos fazer um programa
                    de transferência na veia, pega os 20%, 30% mais pobres e dá
                    a sua parte”, acrescentou o ministro.{' '}
                  </p>
                  <p>
                    <strong>Funcionalismo</strong>. Guedes também tem vinculado
                    economias obtidas com menores gastos com o funcionalismo
                    público federal ao aumento de despesas sociais. Para lançar
                    a nova rodada do auxílio emergencial, que custará até R$ 44
                    bilhões conforme a proposta aprovada no Congresso Nacional,
                    Guedes se amparou na economia obtida com o congelamento de
                    salários de servidores até o fim de 2021 – aprovada na lei
                    complementar 173, que instituiu no ano passado o socorro
                    financeiro a Estados e municípios durante a pandemia.{' '}
                  </p>
                  <p>
                    Na proposta do Movimento Convergência Brasil, a ideia é
                    vincular tanto as receitas de privatizações quanto a
                    economia com a reforma administrativa a um programa de renda
                    básica. Ambas as propostas têm resistências no Congresso
                    Nacional.{' '}
                  </p>
                  <p>
                    Apesar disso, o coordenador do movimento, Elvaristo do
                    Amaral, se mostra confiante no avanço das medidas. “O
                    Congresso e suas várias lideranças parecem muito
                    sensibilizados”, afirma ele. / I.T.
                  </p>{' '}
                </div>{' '}
              </section>

              <a
                href="https://economia.estadao.com.br/noticias/geral,guedes-ja-defendeu-uso-de-dividendos-pagos-por-estatais,70003655461"
                rel="noreferrer"
                target="_blank"
                className="mt-1 text-blue font-weight-bold d-block text-end h6 link-materia"
              >
                Matéria completa
                <img src={setaVerde} alt="Ícone Seta Verde" className="ml-2" />
              </a>
            </div>
          </div>
        </div>
      </Container>
    </>
  );
}

export default GuedesDefendeu;
