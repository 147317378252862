import React, {
  useEffect,
  useState,
  // useCallback,
  // useLayoutEffect,
} from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import api from '../../services/api';

import { Container } from './styles';
import setaVerde from '../../assets/seta-verde.png';
import Loader from '../Loader';

function Blog({ heading }) {
  const [loading, setLoading] = useState(true);
  const [materias, setMaterias] = useState([]);
  // const [mobile, setMobile] = useState(false);

  // const updateSize = useCallback(() => {
  //   if (window.innerWidth < 768) {
  //     setMobile(true);
  //   } else {
  //     setMobile(false);
  //   }
  // }, []);

  // useLayoutEffect(() => {
  //   window.addEventListener('resize', updateSize);
  //   updateSize();
  //   return () => window.removeEventListener('resize', updateSize);
  // }, [updateSize]);

  useEffect(() => {
    api.get('blog').then(response => {
      const data = response.data.materias.map(materia => ({
        id: materia.Id,
        titulo: materia.NmTitulo,
        tituloCapa: materia.NmTituloCapa,
        descricao: materia.NmDescription,
        slug: materia.NmSlug,
        imagemPrincipal: materia.urlImgPrincipal,
        imagemCapa: materia.urlImgCapa,
      }));

      setMaterias(data.slice(0, 2));
      setLoading(false);
    });
  }, []);
  const CustomHeading = `h${heading}`;
  return (
    <Container id="blog">
      <div className="container pb-4">
        <span className="barra d-flex mx-auto mb-4">
          <div className="green" />
          <div className="bg-blue" />
        </span>
        <CustomHeading className="text-blue text-uppercase text-center my-auto titulo">
          Blog
        </CustomHeading>
        {loading && <Loader />}
        {Object.keys(materias).length > 0 &&
          materias.map(materiaHome => (
            <div key={materiaHome.id} className="row">
              <Link
                to={`${process.env.PUBLIC_URL}/blog/${materiaHome.slug}`}
                className="col-12 my-4 post-principal d-flex align-items-center"
              >
                <img
                  src={materiaHome.imagemPrincipal}
                  alt={materiaHome.titulo}
                  className=""
                />
                <h2 className="heading-materia">{materiaHome.tituloCapa}</h2>
                <div className="col-4 d-flex flex-wrap text-white post-blog-principal pl-0 pr-4">
                  <h3 className="h3 text-right font-weight-bold mt-auto post-principal-chamada">
                    {materiaHome.titulo}
                  </h3>
                  <p className="text-right mb-auto">{materiaHome.descricao}</p>
                </div>
              </Link>
              <div className="col-12 d-flex flex-wrap">
                <p className="mt-3 sub-post-principal w-100">
                  {materiaHome.descricao}
                </p>
                <Link
                  to={`${process.env.PUBLIC_URL}/blog/${materiaHome.slug}`}
                  className="mt-1 text-blue font-weight-bold ml-auto h6 link-post-principal"
                >
                  Matéria completa
                  <img
                    src={setaVerde}
                    alt="Ícone Seta Verde"
                    className="ml-2"
                  />
                </Link>
              </div>
            </div>
          ))}
        {Object.keys(materias).length > 0 && (
          <div className="text-center mt-3">
            <Link
              to={`${process.env.PUBLIC_URL}/blog`}
              className="mt-3 text-blue font-weight-bold ml-auto h6"
            >
              Ver mais
              <img src={setaVerde} alt="Ícone Seta Verde" className="ml-2" />
            </Link>
          </div>
        )}
        {/* <div className="row">
          {materias.map(materia => (
            <div key={materia.id} className="col-12 col-md-6 d-flex flex-wrap">
              <Link
                to={`${process.env.PUBLIC_URL}/blog/${materia.slug}`}
                className="position-relative w-100 d-flex align-items-center"
              >
                <img
                  src={mobile ? materia.imagemPrincipal : materia.imagemCapa}
                  alt={materia.titulo}
                  className="img-post"
                />
                <h2 className="heading-materia mobile-only">
                  {materia.tituloCapa}
                </h2>
                <div className="text-white bg-blue  position-absolute bottom-0 h5 p-3 m-0 post-blog">
                  <h3 className="font-weight-bold post-chamada h5 my-auto">
                    {materia.titulo}
                  </h3>
                </div>
              </Link>
              <p className="mt-3 sub-post">{materia.descricao}</p>
              <Link
                to={`${process.env.PUBLIC_URL}/blog/${materia.slug}`}
                className="mt-1 text-blue font-weight-bold ml-auto h6"
              >
                Matéria completa
                <img src={setaVerde} alt="Ícone Seta Verde" className="ml-2" />
              </Link>
            </div>
          ))}
        </div> */}
        {Object.keys(materias).length === 0 && !loading && (
          <div className="row">
            <div className="col text-center">
              <p className="mt-4 h4">Sem postagens no momento.</p>
            </div>
          </div>
        )}
      </div>
    </Container>
  );
}

export default Blog;

Blog.propTypes = {
  heading: PropTypes.number.isRequired,
};
