import styled from 'styled-components';

import Banner from '../../assets/banner.jpg';
import BannerMobile from '../../assets/banner-mobile.jpg';
import BannerSecundario from '../../assets/banner-partido.jpg';
import BannerTerciario from '../../assets/movimento.png';
import BannerTerciarioMobile from '../../assets/movimento-mobile.png';

export const Container = styled.div`
  padding-top: 140px;
  .carrousel {
    .rec-item-wrapper {
      width: 100vw !important;
      display: block;
    }

    .rec-arrow-right {
      position: absolute !important;
      right: 10px !important;
    }

    .rec-arrow-left {
      position: absolute !important;
      left: 10px !important;
      z-index: 1 !important;
    }
    .rec-arrow {
      width: 35px !important;
      height: 35px !important;
      min-width: 35px !important;
      line-height: 36px !important;
      font-size: 1.4rem !important;
      background-color: rgba(46, 45, 123, 0.2) !important;
      box-shadow: 0 0 2px 0px #fff !important;
      color: #fff;
    }
    .rec-slider-container {
      margin: 0px !important;
    }

    .rec-carousel {
      position: relative !important;
    }
    .rec-dot_active {
      background-color: rgba(46, 45, 123, 0.5);
      box-shadow: 0 0 1px 3px rgb(46, 45, 123);
    }
  }
  @media screen and (max-width: 767px) {
    padding-top: 112px;
    .carrousel {
      .rec-item-wrapper {
        height: 560px;
      }
    }
  }
  @media screen and (max-width: 574px) {
    padding-top: 112px;
    .carrousel {
      .rec-item-wrapper {
        height: 93vw;
      }
    }
  }
`;

export const BannerPrincipal = styled.div`
  .banner {
    background-size: cover;
    background-image: url(${Banner});
    background-position: center;
    background-repeat: no-repeat;
    padding: 0;
    height: 560px;
  }
  .assine-manifesto {
    margin-left: auto;
    text-decoration: none;
    margin-right: 95px !important;
    margin-top: -31px !important;
  }
  a,
  a:active,
  a:hover {
    text-decoration: none;
    color: inherit;
  }

  .assine {
    transform: skewX(18deg);
    font-size: 1.15rem;
  }
  .leia {
    transform: skewX(18deg);
    font-size: 0.9rem;
  }
  .blue {
    transform: skewX(-18deg);
    background-color: #2e2d7b;
    height: 28px;
    padding: 0 2.2rem;
  }
  .input-assinatura {
    background: none;
    border: none;
    border-bottom: 1px solid #2e2d7b;
  }
  .barra {
    height: 28px;
    width: 30px;
    position: relative;
    left: -2px;

    div {
      width: 50%;
      transform: skewX(-18deg);
    }
  }
  .green {
    background-color: #129346;
  }
  .font-weight-black {
    font-weight: 900;
  }

  .por-um-brasil {
    font-size: 2.5rem;
    line-height: 50px;
    text-align: right;
    margin: 256px 262px 0 auto !important;

    .por-um {
      display: flex;
    }
    .brasil {
      font-size: 4.7rem;
      vertical-align: text-top;
    }
  }
  .justo-eficiente {
    line-height: 40px;
    font-weight: 900;
    text-align: right;
    margin: 282px 87px auto auto;
    font-size: 2.6rem;
    display: block;

    .justo {
      font-size: 3.5rem;
    }
    .eficiente {
      font-size: 2.85rem;
    }
  }
  #somos {
    .assine-manifesto {
      display: none !important;
    }
  }
  @media screen and (min-width: 1200px) {
    .por-um-brasil {
      margin: 256px 175px 0 auto !important;
    }

    .justo-eficiente {
      margin: 286px 174px auto auto;
    }

    .assine-manifesto {
      margin-right: 176px !important;
      margin-top: -31px !important;
    }
  }
  @media screen and (min-width: 767px) and (max-width: 992px) {
    .banner {
      background-position: 46.5%;
      height: 512px;
    }
    .assine-manifesto {
      margin-right: -17px !important;
      margin-top: -15px !important;
    }
    .justo-eficiente {
      margin: 282px -26px auto auto;
    }
    .por-um-brasil {
      margin: 234px 262px 0 auto !important;
    }
  }

  @media screen and (max-width: 767px) {
    h2 {
      font-size: 1.5rem;
    }
    .assine-manifesto {
      margin-top: 12px !important;
      margin-right: auto !important;
    }
    .assine {
      font-size: 1.5rem;
    }
    .blue,
    .barra {
      height: 38px;
    }
    .barra {
      width: 23px;
    }
    .banner {
      background-image: url(${BannerMobile});
    }
    .leia-manifesto {
      display: none !important;
    }
    #somos {
      text-align: center;
      .row {
        flex-direction: column-reverse;
      }
      .assine-manifesto {
        display: -webkit-box !important;
        .blue {
          height: 34px !important;
        }
        .barra {
          height: 34px !important;
          width: 24px !important;
        }
        p {
          font-size: 1.5rem;
        }
      }
    }
    .por-um-brasil {
      font-size: 40px;
      line-height: 47px;
      margin: 156px auto 0 0 !important;

      .por-um {
        display: flex;
      }
      .brasil {
        font-size: 60px;
      }
      .mais {
        margin-right: -23px;
      }
    }

    .justo-eficiente {
      line-height: 42px;
      margin: 382px 0 0 auto;
      font-size: 43px;

      .justo {
        font-size: 52px;
      }
      .eficiente {
        font-size: 44px;
      }
    }
    .banner {
      background-position: 44%;
      height: 489px;
    }
  }
  @media screen and (max-width: 715px) {
    .banner {
      background-position: 45%;
    }
  }
  @media screen and (max-width: 574px) {
    .por-um-brasil {
      font-size: 7vw;
      line-height: 8.5vw;
      margin: 25vw auto 0 0 !important;

      .por-um {
        display: flex;
      }
      .brasil {
        font-size: 10.5vw;
      }
      .mais {
        margin-right: -5vw;
      }
    }

    .justo-eficiente {
      line-height: 7vw;
      margin: 63vw 0 0 auto;
      font-size: 7.5vw;

      .justo {
        font-size: 9.1vw;
      }
      .eficiente {
        font-size: 7.5vw;
      }
    }
    .banner {
      background-position: 45%;
      height: 81vw;
    }
  }
  @media screen and (max-width: 350px) {
    #somos {
      .assine-manifesto {
        p {
          font-size: 1.25rem;
        }
      }
    }
    .assine {
      font-size: 1.2rem;
    }
  }
  @media screen and (min-width: 767px) and (max-width: 992px) {
    h2 {
      font-size: 2rem;
    }
  }
`;

export const BannerSemPartido = styled.div`
  .banner {
    background-size: cover;
    background-image: url(${BannerSecundario});
    background-position: center;
    background-repeat: no-repeat;
    padding: 0;
    height: 560px;
  }
  .texto-sem-partido {
    font-size: 2.1rem;
    margin-left: 9.7rem;
  }
  .direcao {
    font-size: 3.3rem;
  }
  .nem {
    line-height: 38px;
    display: inline-block;
    margin-top: -10px;
  }
  .direcao,
  .brasil {
    font-weight: 900;
  }
  .lutamos {
    line-height: 2.9rem;
    font-size: 2.7rem;
    .brasil {
      font-size: 3.7rem;
      vertical-align: middle;
    }
  }

  @media screen and (min-width: 767px) and (max-width: 992px) {
    .banner {
      height: 512px;
    }
    .texto-sem-partido {
      font-size: 1.6rem;
      margin-left: 6.7rem;
    }
    .direcao {
      font-size: 2.8rem;
    }
    .nem {
      line-height: 32px;
      display: inline-block;
      margin-top: -10px;
    }
    .direcao,
    .brasil {
      font-weight: 900;
    }
    .lutamos {
      line-height: 2.4rem;
      font-size: 2rem;
      .brasil {
        font-size: 3rem;
        vertical-align: middle;
      }
    }
  }

  @media screen and (max-width: 767px) {
    .banner {
      background-position: 52%;
    }
    .texto-sem-partido {
      font-size: 1.6rem;
      margin-left: 3.7rem;
    }
    .direcao {
      font-size: 2.8rem;
    }
    .nem {
      line-height: 32px;
      display: inline-block;
      margin-top: -10px;
    }
    .direcao,
    .brasil {
      font-weight: 900;
    }
    .lutamos {
      line-height: 2.4rem;
      font-size: 2rem;
      .brasil {
        font-size: 3rem;
        vertical-align: middle;
      }
    }
  }
  @media screen and (max-width: 574px) {
    .banner {
      height: 81vw;
    }
    .texto-sem-partido {
      font-size: 4.46vw;
      margin-left: 10.3vw;
    }

    .nem {
      line-height: 5.57vw;
      margin-top: -1.74vw;
    }

    .direcao {
      font-size: 7.8vw;
    }

    .lutamos {
      line-height: 6.9vw;
      font-size: 5.57vw;
      .brasil {
        font-size: 8.36vw;
      }
    }
  }
  @media screen and (min-width: 767px) and (max-width: 992px) {
    h2 {
      font-size: 2rem;
    }
  }
`;

export const BannerMovimento = styled.div`
  .banner {
    background-size: cover;
    background-image: url(${BannerTerciario});
    background-position: center;
    background-repeat: no-repeat;
    padding: 0;
    height: 560px;
  }
  .assine-manifesto {
    margin-left: auto;
    text-decoration: none;
    margin-right: 95px !important;
    margin-top: -31px !important;
  }
  a,
  a:active,
  a:hover {
    text-decoration: none;
    color: inherit;
  }

  .assine {
    transform: skewX(18deg);
    font-size: 1.15rem;
  }
  .leia {
    transform: skewX(18deg);
    font-size: 0.9rem;
  }
  .blue {
    transform: skewX(-18deg);
    background-color: #2e2d7b;
    height: 28px;
    padding: 0 2.2rem;
  }
  .input-assinatura {
    background: none;
    border: none;
    border-bottom: 1px solid #2e2d7b;
  }
  .barra {
    height: 28px;
    width: 30px;
    position: relative;
    left: -2px;

    div {
      width: 50%;
      transform: skewX(-18deg);
    }
  }
  .green {
    background-color: #129346;
  }
  .font-weight-black {
    font-weight: 900;
  }

  .por-um-brasil {
    font-size: 2.5rem;
    line-height: 50px;
    text-align: right;
    margin: 256px 262px 0 auto !important;

    .por-um {
      display: flex;
    }
    .brasil {
      font-size: 4.7rem;
      vertical-align: text-top;
    }
  }
  .justo-eficiente {
    line-height: 40px;
    font-weight: 900;
    text-align: right;
    margin: 282px 87px auto auto;
    font-size: 2.6rem;
    display: block;

    .justo {
      font-size: 3.5rem;
    }
    .eficiente {
      font-size: 2.85rem;
    }
  }
  #somos {
    .assine-manifesto {
      display: none !important;
    }
  }
  @media screen and (min-width: 1200px) {
    .por-um-brasil {
      margin: 256px 175px 0 auto !important;
    }

    .justo-eficiente {
      margin: 286px 174px auto auto;
    }

    .assine-manifesto {
      margin-right: 176px !important;
      margin-top: -31px !important;
    }
  }
  @media screen and (min-width: 767px) and (max-width: 992px) {
    .banner {
      background-position: 46.5%;
      height: 512px;
    }
    .assine-manifesto {
      margin-right: -17px !important;
      margin-top: -15px !important;
    }
    .justo-eficiente {
      margin: 282px -26px auto auto;
    }
    .por-um-brasil {
      margin: 234px 262px 0 auto !important;
    }
  }

  @media screen and (max-width: 767px) {
    h2 {
      font-size: 1.5rem;
    }
    .assine-manifesto {
      margin-top: 12px !important;
      margin-right: auto !important;
    }
    .assine {
      font-size: 1.5rem;
    }
    .blue,
    .barra {
      height: 38px;
    }
    .barra {
      width: 23px;
    }
    .banner {
      background-image: url(${BannerTerciarioMobile});
    }
    .leia-manifesto {
      display: none !important;
    }
    #somos {
      text-align: center;
      .row {
        flex-direction: column-reverse;
      }
      .assine-manifesto {
        display: -webkit-box !important;
        .blue {
          height: 34px !important;
        }
        .barra {
          height: 34px !important;
          width: 24px !important;
        }
        p {
          font-size: 1.5rem;
        }
      }
    }
    .por-um-brasil {
      font-size: 40px;
      line-height: 47px;
      margin: 156px auto 0 0 !important;

      .por-um {
        display: flex;
      }
      .brasil {
        font-size: 60px;
      }
      .mais {
        margin-right: -23px;
      }
    }

    .justo-eficiente {
      line-height: 42px;
      margin: 382px 0 0 auto;
      font-size: 43px;

      .justo {
        font-size: 52px;
      }
      .eficiente {
        font-size: 44px;
      }
    }
    .banner {
      background-position: 44%;
      height: 489px;
    }
  }
  @media screen and (max-width: 715px) {
    .banner {
      background-position: 45%;
    }
  }
  @media screen and (max-width: 574px) {
    .por-um-brasil {
      font-size: 7vw;
      line-height: 8.5vw;
      margin: 25vw auto 0 0 !important;

      .por-um {
        display: flex;
      }
      .brasil {
        font-size: 10.5vw;
      }
      .mais {
        margin-right: -5vw;
      }
    }

    .justo-eficiente {
      line-height: 7vw;
      margin: 63vw 0 0 auto;
      font-size: 7.5vw;

      .justo {
        font-size: 9.1vw;
      }
      .eficiente {
        font-size: 7.5vw;
      }
    }
    .banner {
      background-position: 45%;
      height: 81vw;
    }
  }
  @media screen and (max-width: 350px) {
    #somos {
      .assine-manifesto {
        p {
          font-size: 1.25rem;
        }
      }
    }
    .assine {
      font-size: 1.2rem;
    }
  }
  @media screen and (min-width: 767px) and (max-width: 992px) {
    h2 {
      font-size: 2rem;
    }
  }
`;
