import React from 'react';
import { NavHashLink } from 'react-router-hash-link';
import {
  FacebookShareButton,
  LinkedinShareButton,
  TwitterShareButton,
  WhatsappShareButton,
  FacebookIcon,
  TwitterIcon,
  LinkedinIcon,
  WhatsappIcon,
} from 'react-share';
import { Container } from './styles';
import mao from '../../assets/mao.png';

function FacaParte() {
  const scrollWithOffset = el => {
    const yCoordinate = el.getBoundingClientRect().top + window.pageYOffset;
    let yOffset = -109;
    if (window.innerWidth <= 768) {
      yOffset = -80;
    }
    window.scrollTo({ top: yCoordinate + yOffset, behavior: 'smooth' });
  };
  return (
    <Container className="faca-parte-component ">
      <div className="container mt-5">
        <div className="row">
          <div className="col-12 col-sm-5 col-md-4 d-flex">
            <img
              src={mao}
              alt="Ilustração mão segurando smartphone"
              className="ml-auto mt-auto"
            />
          </div>
          <div className="col-12 col-sm-7 col-md-8 d-flex flex-wrap">
            <h2 className="h2 faca-parte mt-auto text-blue text-uppercase">
              Faça parte desse manifesto!
            </h2>
            <p className="text-blue compartilhe h3">
              Compartilhe esse projeto em suas redes e grupos sociais e assine{' '}
              <NavHashLink
                smooth
                scroll={el => scrollWithOffset(el)}
                to={`${process.env.PUBLIC_URL}/#assine`}
                className="text-blue"
              >
                o Manifesto aqui.
              </NavHashLink>
            </p>
            <div className="col-12 ">
              <p className="text-blue text-center text-uppercase">
                Compartilhe nas redes
              </p>
              <div className="row">
                <div className="col-12 d-flex justify-content-center">
                  <FacebookShareButton
                    className="mx-2"
                    url={window.location.href}
                  >
                    <FacebookIcon size={32} round />
                  </FacebookShareButton>
                  <LinkedinShareButton
                    className="mx-2"
                    url={window.location.href}
                  >
                    <LinkedinIcon size={32} round />
                  </LinkedinShareButton>
                  <TwitterShareButton
                    className="mx-2"
                    url={window.location.href}
                  >
                    <TwitterIcon size={32} round />
                  </TwitterShareButton>
                  <WhatsappShareButton
                    className="mx-2"
                    url={window.location.href}
                  >
                    <WhatsappIcon size={32} round />
                  </WhatsappShareButton>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Container>
  );
}

export default FacaParte;
