import React from 'react';
import { NavHashLink } from 'react-router-hash-link';
import PropTypes from 'prop-types';
import Carousel from 'react-elastic-carousel';
import {
  Container,
  BannerPrincipal,
  BannerSemPartido,
  BannerMovimento,
} from './styles';

function BannerHome({ heading }) {
  const scrollWithOffset = el => {
    const yCoordinate = el.getBoundingClientRect().top + window.pageYOffset;
    let yOffset = -109;
    if (window.innerWidth <= 768) {
      yOffset = -80;
    }
    window.scrollTo({ top: yCoordinate + yOffset, behavior: 'smooth' });
  };
  const CustomHeading = `h${heading}`;
  const SubCustomHeading = `h${heading + 1}`;
  return (
    <Container>
      <Carousel
        showArrows={false}
        enableAutoPlay
        className="carrousel"
        autoPlaySpeed={5000}
      >
        <BannerMovimento>
          <div className="banner" />
          <div className="container text-center d-flex justify-content-center align-items-center">
            <NavHashLink
              smooth
              scroll={el => scrollWithOffset(el)}
              to={`${process.env.PUBLIC_URL}/coordenadores#titulo`}
              className="row "
            >
              <div className="blue d-flex px-3">
                <h2 className="my-auto text-white text-uppercase font-weight-bold assine mb-0">
                  LEIA MAIS
                </h2>
              </div>
              <span className="barra d-flex">
                <div className="green" />
              </span>
            </NavHashLink>
          </div>
        </BannerMovimento>
        <BannerPrincipal>
          <div className="banner">
            <div className="container h-100">
              <div className="row h-100 justify-content-between">
                <div className="col-12">
                  <CustomHeading className="d-inline-flex w-100">
                    <span className="w-fit-content  my-auto align-self-center text-green font-weight-bold por-um-brasil">
                      <span className="por-um">Por um </span>
                      <span className="brasil font-weight-black">
                        Brasil
                      </span>{' '}
                      <br />
                      <span className="mais">mais</span>
                    </span>

                    <span className="w-fit-content align-self-center text-blue text-uppercase justo-eficiente">
                      <span className="justo">Justo</span> e
                      <br />
                      <span className="eficiente">eficiente</span>
                    </span>
                  </CustomHeading>
                  <NavHashLink
                    smooth
                    scroll={el => scrollWithOffset(el)}
                    to={`${process.env.PUBLIC_URL}/#assine`}
                    className="row w-fit-content assine-manifesto mr-0 my-4 flex-nowrap"
                  >
                    <div className="blue d-flex px-3">
                      <h2 className="my-auto text-white text-uppercase font-weight-bold assine mb-0">
                        Assine o manifesto
                      </h2>
                    </div>
                    <span className="barra d-flex">
                      <div className="green" />
                    </span>
                  </NavHashLink>
                </div>
              </div>
            </div>
          </div>
          <NavHashLink
            smooth
            scroll={el => scrollWithOffset(el)}
            to={`${process.env.PUBLIC_URL}/manifesto#manifesto-na-integra`}
            className="row w-fit-content leia-manifesto mx-auto mb-4 flex-nowrap"
          >
            <div className="blue d-flex">
              <h3 className="my-auto text-white text-uppercase font-weight-bold leia mb-0">
                LEIA O NOSSO MANIFESTO NA ÍNTEGRA
              </h3>
            </div>
            <span className="barra d-flex">
              <div className="green" />
            </span>
          </NavHashLink>
        </BannerPrincipal>
        <BannerSemPartido>
          <div className="banner">
            <div className="container h-100">
              <div className="row h-100 justify-content-between">
                <div className="ml-auto col-7 d-flex">
                  <SubCustomHeading className="w-100 text-blue my-auto font-weight-bold texto-sem-partido">
                    <span className="text-green nem">
                      Nem de <br />
                      <span className="text-uppercase direcao">esquerda,</span>
                    </span>
                    <br />
                    <span className="nem">
                      nem de
                      <br />
                      <span className="text-uppercase direcao">direita,</span>
                    </span>
                    <br />
                    <span className="lutamos mt-3 d-inline-block">
                      lutamos por um <br />
                      <span className="text-uppercase brasil">Brasil</span>
                      <br />
                      sem privilégios.
                    </span>
                  </SubCustomHeading>
                </div>
              </div>
            </div>
          </div>
        </BannerSemPartido>
      </Carousel>
    </Container>
  );
}

export default BannerHome;
BannerHome.propTypes = {
  heading: PropTypes.number.isRequired,
};
