import styled from 'styled-components';

export const Container = styled.div`
  justify-content: center;
  button {
    outline: #fff !important;
    width: fit-content;
  }
  .pagination {
    font-size: 1.6rem;
    color: #707070;
  }
  .selected {
    font-weight: bold;
  }
`;
