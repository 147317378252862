import styled from 'styled-components';
import BgAssinatura from '../../assets/bg-assinatura.jpg';

export const Container = styled.div`
  .titulo {
    font-weight: 900;
    font-size: 4rem;
    margin-bottom: 2rem;
  }
  label span {
    font-size: 24px;
    vertical-align: middle;
    margin-left: -4px;
  }
  .banner {
    background-size: cover;
    background-image: url(${BgAssinatura});
    background-position: center;
    background-repeat: no-repeat;
    padding: 0;
  }
  .barra {
    height: 12px;
    width: 340px;

    div {
      width: 50%;
      transform: skewX(-25deg);
    }
  }
  .green {
    background-color: #129346;
  }
  .bg-blue {
    background-color: #2e2d7b;
  }
  .texto-assinatura {
    max-width: 600px;
  }
  .lgpd {
    text-decoration: underline;
    text-decoration-color: #129346;
  }
  @media screen and (max-width: 767px) {
    p {
      text-align: center !important;
    }

    .lgpd {
      font-size: 1.25rem !important;
    }
    .barra {
      width: 300px;
    }
    .titulo {
      font-size: 3rem;
      margin-bottom: 1.5rem;
    }
  }
  @media screen and (min-width: 767px) and (max-width: 992px) {
    .titulo {
      font-size: 3rem;
    }
  }
  text-align: left;
  label {
    font-weight: 600;
  }
  button {
    border-radius: 0px;
    transform: skewX(25deg);
    width: 100%;
    height: 100%;
    font-size: 1.25rem;
    outline: none;
  }
  button:focus {
    outline: 0 !important;
  }
  .blue {
    transform: skewX(-25deg);
    background-color: #2e2d7b;
    width: -moz-available;
    width: -webkit-fill-available;
    height: 48px;
  }
  .input-assinatura {
    background: none;
    border: none;
    border-bottom: 1px solid #2e2d7b;
  }
  .barra-btn {
    height: 48px;
    width: 22.5px;

    div {
      width: 100%;
      transform: skewX(-25deg);
    }
  }
  .green {
    background-color: #129346;
  }

  @media screen and (max-width: 768px) {
    button {
      font-size: 2rem;
      padding: 0;
    }
    .barra-btn {
      height: 48px;
      width: 29px;
    }
  }

  @media screen and (min-width: 1440px) {
  }
`;
