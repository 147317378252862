import React, { useEffect, useState, useRef } from 'react';
import { Helmet } from 'react-helmet';
import { useSelector, useDispatch } from 'react-redux';
import CountUp from 'react-countup';
import VisibilitySensor from 'react-visibility-sensor';

import api from '../../services/api';

import { Container } from './styles';
import Loader from '../../components/Loader';
import Contato from '../../components/Contato';
import Pagination from '../../components/Pagination';

function Assinaturas() {
  const [loading, setLoading] = useState(true);
  const [assinaturas, setAssinaturas] = useState([]);
  const alphabet = [...'ABCDEFGHIJKLMNOPQRSTUVWXYZ'];
  const tableRef = useRef(null);
  const scrollToRef = ref =>
    window.scrollTo({ top: ref.current.offsetTop - 180 });

  useEffect(() => {
    api.get('assinatura/listar').then(response => {
      const { data } = response;
      setAssinaturas(data);
      setLoading(false);
    });
  }, []);

  const [focus, setFocus] = useState(false);
  const dispatch = useDispatch();

  useEffect(() => {
    api.get('assinatura/total').then(response => {
      if (response.data) {
        dispatch({ type: 'currentAssinaturas', assinaturas: response.data });
      }
    });
  }, [dispatch]);
  const state = useSelector(assinaturasState => assinaturasState);

  function loadPage(page) {
    setLoading(true);
    scrollToRef(tableRef);
    api.get(`assinatura/listar?page=${page}`).then(response => {
      const { data } = response;
      setAssinaturas(data);
      setLoading(false);
    });
  }

  function loadPageByLetter(letter) {
    setLoading(true);
    scrollToRef(tableRef);
    api.get(`assinatura/listar?letra=${letter}`).then(response => {
      const { data } = response;
      setAssinaturas(data);
      setLoading(false);
    });
  }

  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Convergência Brasil – Veja quem já assinou</title>
        <meta
          name="description"
          content="Conheça o Convergência Brasil, um grupo de empresários unidos para lutar por um Brasil mais justo, assine nosso manifesto"
        />
      </Helmet>
      <Container className="mb-4">
        <div className="banner">
          <div className="container h-100">
            <div className="row h-100 justify-content-between">
              <div className="col-6 d-flex">
                <h1 className="text-white my-auto text-uppercase veja-quem">
                  Veja quem <br />
                  já assinou
                </h1>
              </div>
              <div className="col-6 flex-wrap" />
            </div>
          </div>
        </div>
        <div className="container my-3">
          <div className="row">
            <div className="col">
              <h2 className="text-green text-center h4">
                Este Manifesto já conta com
                <br />
                <CountUp
                  start={focus ? 0 : null}
                  end={state.assinaturasState.assinaturas}
                  redraw
                >
                  {({ countUpRef }) => (
                    <VisibilitySensor
                      onChange={isVisible => {
                        if (isVisible) {
                          setFocus(true);
                        }
                      }}
                      delayedCall
                    >
                      <span
                        className="text-blue num-assinatura"
                        ref={countUpRef}
                      />
                    </VisibilitySensor>
                  )}
                </CountUp>{' '}
                <span className="assinaturas">assinaturas</span>
              </h2>
            </div>
          </div>
        </div>
        <div className="container">
          <div className="row row-assinaturas">
            <div className="col-12 col-letras d-flex justify-content-center">
              {alphabet.map(letter => (
                <button
                  key={letter}
                  type="button"
                  onClick={() => loadPageByLetter(letter)}
                  className={`letra mx-1  ${
                    letter === assinaturas.letter ? 'selected-letter' : ''
                  }`}
                >
                  {letter}
                </button>
              ))}
            </div>
            <div ref={tableRef} className="col-12 col-table">
              <table>
                <thead>
                  <tr>
                    <th>Nome</th>
                  </tr>
                </thead>
                <tbody>
                  {!loading && Object.keys(assinaturas).length > 0 ? (
                    assinaturas.assinaturas.map(assinatura => (
                      <tr key={assinatura.Id}>
                        <td>{assinatura.NmNome}</td>
                      </tr>
                    ))
                  ) : (
                    <tr />
                  )}
                </tbody>
              </table>
              {loading && (
                <div className="d-flex justify-content-center pb-5">
                  <Loader />
                </div>
              )}
            </div>
          </div>
          <div className="row">
            {Object.keys(assinaturas).length > 0 ? (
              <Pagination
                currentPage={assinaturas.page}
                totalPages={assinaturas.totalPages}
                onChangePage={loadPage}
              />
            ) : (
              ''
            )}
          </div>
        </div>
      </Container>
      <Contato />
    </>
  );
}

export default Assinaturas;
