import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import CountUp from 'react-countup';
import VisibilitySensor from 'react-visibility-sensor';
import { useSelector, useDispatch } from 'react-redux';
import api from '../../services/api';
import { Container } from './styles';

function Assinaturas() {
  const [focus, setFocus] = useState(false);
  const dispatch = useDispatch();

  useEffect(() => {
    api.get('assinatura/total').then(response => {
      if (response.data) {
        dispatch({ type: 'currentAssinaturas', assinaturas: response.data });
      }
    });
  }, [dispatch]);
  const state = useSelector(assinaturasState => assinaturasState);

  return (
    <Container>
      <div className="container pb-4">
        <div className="col-12 py-3">
          <div className="row my-4 mx-0  flex-nowrap">
            <span className="barra-assinatura d-flex">
              <div className="green" />
            </span>
            <div className="blue">
              <Link
                to={`${process.env.PUBLIC_URL}/assinaturas`}
                className="text-center my-auto ja-assinou px-4 text-white font-weight-bold m-0 text-uppercase h2"
              >
                Veja quem já assinou
              </Link>
            </div>
          </div>
        </div>
        <p className="text-green text-center h4">
          Este Manifesto já conta com
          <br />
          <CountUp
            start={focus ? 0 : null}
            end={state.assinaturasState.assinaturas}
            redraw
          >
            {({ countUpRef }) => (
              <VisibilitySensor
                onChange={isVisible => {
                  if (isVisible) {
                    setFocus(true);
                  }
                }}
                delayedCall
              >
                <span className="text-blue num-assinatura" ref={countUpRef} />
              </VisibilitySensor>
            )}
          </CountUp>{' '}
          <span className="assinaturas">assinaturas</span>
        </p>
      </div>
    </Container>
  );
}

export default Assinaturas;
