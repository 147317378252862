import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { NavHashLink } from 'react-router-hash-link';
import { Container } from './styles';
import logo from '../../assets/logo.png';
import assineIcone from '../../assets/assine-icone.png';
import faleConoscoIcone from '../../assets/fale-conosco-icone.png';

function Header() {
  const [tipoMenu, setTipoMenu] = useState(0);
  function handleMenu(tipo) {
    setTipoMenu(tipo);
  }
  const scrollWithOffset = el => {
    const yCoordinate = el.getBoundingClientRect().top + window.pageYOffset;
    let yOffset = -109;
    if (window.innerWidth <= 768) {
      yOffset = -80;
    }
    window.scrollTo({ top: yCoordinate + yOffset, behavior: 'smooth' });
  };

  return (
    <>
      <Container className="position-fixed w-100">
        <div className="container">
          <div className="row position-relative py-2 pt-lg-3 pb-lg-4">
            <div className="col-12 px-4 col-logo col-lg-3 col-md-5 d-flex justify-content-between">
              <Link to={`${process.env.PUBLIC_URL}`} className="d-flex">
                <img
                  src={logo}
                  alt="Logo Manifesto Convergência Brasil"
                  className="my-auto w-100 logo"
                />
              </Link>

              <div className="d-none-md my-auto align-center">
                <button
                  className={`ml-auto ${
                    tipoMenu === 2 ? 'is--active' : ''
                  } menu-btn my-4 d-none-md`}
                  aria-labelledby="sr-only"
                  type="button"
                  onClick={() =>
                    tipoMenu === 2 ? handleMenu(1) : handleMenu(2)
                  }
                >
                  <span className="sr-only" id="sr-only">
                    Menu
                  </span>
                  <div className="menu-btn__icon" aria-hidden="true">
                    <span className="icon icon--menu">
                      <span />
                      <span />
                      <span />
                    </span>
                    <span className="icon icon--close">
                      <span />
                      <span />
                    </span>
                  </div>
                </button>
              </div>
            </div>

            <nav className="col-12 d-flex align-items-end my-2 justify-content-center mobile-only">
              <div className="d-flex">
                <Link
                  to={`${process.env.PUBLIC_URL}/assine`}
                  className="mt-auto mb-0 ml-0 text-uppercase text-green text-center font-weight-bold"
                >
                  ASSINE O MANIFESTO
                </Link>
              </div>
              <NavHashLink
                smooth
                scroll={el => scrollWithOffset(el)}
                to={`${process.env.PUBLIC_URL}/#manifesto`}
                className="text-blue text-center font-weight-bold mx-1"
              >
                <div className="nav-item d-flex flex-row-reverse">
                  <p className="nav-link px-2 pb-0 m-0">O MANIFESTO</p>
                  <span className="barra my-auto">
                    <div className="green" />
                    <div className="blue" />
                  </span>
                </div>
              </NavHashLink>
              <NavHashLink
                smooth
                scroll={el => scrollWithOffset(el)}
                to={`${process.env.PUBLIC_URL}/coordenadores#titulo`}
                className="text-blue text-center font-weight-bold "
              >
                <div className="nav-item d-flex flex-row-reverse">
                  <p className="nav-link px-0 pb-0 m-0">COORDENADORES</p>
                  <span className="barra my-auto">
                    <div className="green" />
                    <div className="blue" />
                  </span>
                </div>
              </NavHashLink>
            </nav>

            <nav className={`col dropdown-nav ${tipoMenu === 2 ? 'drop' : ''}`}>
              <div className="row">
                <div className="col assine-contato d-flex justify-content-end">
                  <div className="d-flex">
                    <img
                      src={assineIcone}
                      alt="Ícone Assine"
                      className=" my-auto"
                    />
                    <Link
                      to={`${process.env.PUBLIC_URL}/assine`}
                      className="mt-auto mb-1 ml-1 text-uppercase text-green font-weight-bold"
                      onClick={() =>
                        tipoMenu === 2 ? handleMenu(1) : handleMenu(2)
                      }
                    >
                      ASSINE O MANIFESTO
                    </Link>
                  </div>

                  <div className="d-flex">
                    <img
                      src={faleConoscoIcone}
                      alt="Ícone Fale Conosco"
                      className="my-auto ml-3"
                    />
                    <NavHashLink
                      smooth
                      scroll={el => scrollWithOffset(el)}
                      to={`${process.env.PUBLIC_URL}/#contato`}
                      className="mt-auto mb-1 ml-1 text-uppercase text-green font-weight-bold"
                      onClick={() =>
                        tipoMenu === 2 ? handleMenu(1) : handleMenu(2)
                      }
                    >
                      FALE CONOSCO
                    </NavHashLink>
                  </div>
                </div>
              </div>
              <div className="row mt-2">
                <div className="col items-nav d-flex justify-content-end px-0">
                  <NavHashLink
                    smooth
                    scroll={el => scrollWithOffset(el)}
                    to={`${process.env.PUBLIC_URL}/#manifesto`}
                    className="text-blue font-weight-bold mx-1 desk-only"
                    onClick={() =>
                      tipoMenu === 2 ? handleMenu(1) : handleMenu(2)
                    }
                  >
                    <div className="nav-item d-flex flex-row-reverse">
                      <p className="nav-link px-2 m-0">O MANIFESTO</p>
                      <span className="barra my-auto">
                        <div className="green" />
                        <div className="blue" />
                      </span>
                    </div>
                  </NavHashLink>
                  <NavHashLink
                    smooth
                    scroll={el => scrollWithOffset(el)}
                    to={`${process.env.PUBLIC_URL}/#midia`}
                    className="text-blue font-weight-bold"
                    onClick={() =>
                      tipoMenu === 2 ? handleMenu(1) : handleMenu(2)
                    }
                  >
                    <div className="nav-item d-flex flex-row-reverse">
                      <p className="nav-link px-2 m-0">NA MÍDIA</p>
                      <span className="barra my-auto">
                        <div className="green" />
                        <div className="blue" />
                      </span>
                    </div>
                  </NavHashLink>
                  <NavHashLink
                    smooth
                    scroll={el => scrollWithOffset(el)}
                    to={`${process.env.PUBLIC_URL}/blog`}
                    className="text-blue font-weight-bold"
                    onClick={() =>
                      tipoMenu === 2 ? handleMenu(1) : handleMenu(2)
                    }
                  >
                    <div className="nav-item d-flex flex-row-reverse">
                      <p className="nav-link px-2 m-0">BLOG</p>
                      <span className="barra my-auto">
                        <div className="green" />
                        <div className="blue" />
                      </span>
                    </div>
                  </NavHashLink>
                  <NavHashLink
                    smooth
                    scroll={el => scrollWithOffset(el)}
                    to={`${process.env.PUBLIC_URL}/quem-somos#titulo`}
                    className="text-blue font-weight-bold "
                    onClick={() =>
                      tipoMenu === 2 ? handleMenu(1) : handleMenu(2)
                    }
                  >
                    <div className="nav-item d-flex flex-row-reverse">
                      <p className="nav-link px-2 m-0">QUEM SOMOS</p>
                      <span className="barra my-auto">
                        <div className="green" />
                        <div className="blue" />
                      </span>
                    </div>
                  </NavHashLink>
                  <NavHashLink
                    smooth
                    scroll={el => scrollWithOffset(el)}
                    to={`${process.env.PUBLIC_URL}/assinaturas`}
                    className="text-blue font-weight-bold "
                    onClick={() =>
                      tipoMenu === 2 ? handleMenu(1) : handleMenu(2)
                    }
                  >
                    <div className="nav-item d-flex flex-row-reverse">
                      <p className="nav-link px-2 m-0">ASSINATURAS</p>
                      <span className="barra my-auto">
                        <div className="green" />
                        <div className="blue" />
                      </span>
                    </div>
                  </NavHashLink>
                  <NavHashLink
                    smooth
                    scroll={el => scrollWithOffset(el)}
                    to={`${process.env.PUBLIC_URL}/coordenadores#titulo`}
                    className="text-blue font-weight-bold desk-only"
                    onClick={() =>
                      tipoMenu === 2 ? handleMenu(1) : handleMenu(2)
                    }
                  >
                    <div className="nav-item d-flex flex-row-reverse">
                      <p className="nav-link px-2 m-0">COORDENADORES</p>
                      <span className="barra my-auto">
                        <div className="green" />
                        <div className="blue" />
                      </span>
                    </div>
                  </NavHashLink>
                </div>
              </div>
            </nav>
          </div>
        </div>
      </Container>
    </>
  );
}

export default Header;
