/* eslint-disable react/no-danger */
import React, { useState, useEffect } from 'react';
import { Link, useParams } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import { NavHashLink } from 'react-router-hash-link';
import {
  FacebookShareButton,
  LinkedinShareButton,
  TwitterShareButton,
  WhatsappShareButton,
  FacebookIcon,
  TwitterIcon,
  LinkedinIcon,
  WhatsappIcon,
} from 'react-share';

import { Container } from './styles';
import setaVerde from '../../assets/seta-verde.png';
import api from '../../services/api';
import Loader from '../../components/Loader';
import Assine from '../../components/Assine';

function Materia() {
  const param = useParams();
  const [materia, setMateria] = useState({});
  const [materias, setMaterias] = useState([]);
  const [loading, setLoading] = useState(true);

  const scrollWithOffset = el => {
    const yCoordinate = el.getBoundingClientRect().top + window.pageYOffset;
    let yOffset = -109;
    if (window.innerWidth <= 768) {
      yOffset = -80;
    }
    window.scrollTo({ top: yCoordinate + yOffset, behavior: 'smooth' });
  };

  useEffect(() => {
    api.get(`blog/${param.slug}`).then(response => {
      setMateria(response.data);
    });

    api.get(`blog/more/${param.slug}`).then(response => {
      const data = response.data.map(moreMateria => ({
        id: moreMateria.Id,
        titulo: moreMateria.NmTitulo,
        tituloCapa: moreMateria.NmTituloCapa,
        descricao: moreMateria.NmDescription,
        slug: moreMateria.NmSlug,
        image: moreMateria.urlImgCapa,
      }));
      setMaterias(data);
      setLoading(false);
    });
  }, [param.slug]);

  return (
    <Container>
      {loading && (
        <div className="col loader position-fixed">
          <Loader />
        </div>
      )}
      {!loading && Object.keys(materia).length < 1 && (
        <div className="col text-center nao-encontrado">
          <div className="h2">Post não encontrado.</div>
        </div>
      )}
      {Object.keys(materia).length > 0 && (
        <>
          <Helmet>
            <meta charSet="utf-8" />
            <title>{materia.NmMetaTitle}</title>
            <meta name="description" content={materia.NmDescription} />
            <meta
              name="keywords"
              content={materia.NmTags.replaceAll(';', ',')}
            />
          </Helmet>
          <div className="w-100 d-flex align-items-center banner-blog">
            <img
              src={materia.urlbanner}
              alt={materia.NmTitulo}
              className="w-100 "
            />
            <h1 className="titulo-capa">{materia.NmTituloCapa}</h1>
          </div>
          <div className="container">
            <div className="row my-5">
              <div className="col-12">
                <h2 className="h2 font-weight-bold">{materia.NmTitulo}</h2>
                <div
                  dangerouslySetInnerHTML={{ __html: materia.NmMateriaPt1 }}
                />
                <div className="bannerCTA">
                  <div className="col-6" />
                </div>
                <div className="d-flex justify-content-center">
                  <NavHashLink
                    smooth
                    scroll={el => scrollWithOffset(el)}
                    to={`${process.env.PUBLIC_URL}/#assine`}
                    className="row w-fit-content assine-manifesto mr-0 my-4 flex-nowrap"
                  >
                    <div className="blue-blog d-flex px-3">
                      <p className="my-auto text-white text-uppercase font-weight-bold assine mb-0">
                        Assine o manifesto
                      </p>
                    </div>
                    <span className="barra-blog d-flex">
                      <div className="green" />
                    </span>
                  </NavHashLink>
                </div>

                <div
                  dangerouslySetInnerHTML={{ __html: materia.NmMateriaPt2 }}
                />
              </div>
              <div className="col-12 ">
                <p className="text-blue text-center text-uppercase">
                  Compartilhe nas redes
                </p>
                <div className="row">
                  <div className="col-12 d-flex justify-content-center">
                    <FacebookShareButton
                      className="mx-2"
                      url={window.location.href}
                    >
                      <FacebookIcon size={32} round />
                    </FacebookShareButton>
                    <LinkedinShareButton
                      className="mx-2"
                      url={window.location.href}
                    >
                      <LinkedinIcon size={32} round />
                    </LinkedinShareButton>
                    <TwitterShareButton
                      className="mx-2"
                      url={window.location.href}
                    >
                      <TwitterIcon size={32} round />
                    </TwitterShareButton>
                    <WhatsappShareButton
                      className="mx-2"
                      url={window.location.href}
                    >
                      <WhatsappIcon size={32} round />
                    </WhatsappShareButton>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <Assine heading={3} />
          <div className="container mt-4">
            <div className="row">
              <div className="col-12 mt-3">
                <h3>Outras matérias</h3>
              </div>
              <div className="row  mx-0">
                {materias.map(materia => (
                  <div
                    key={materia.id}
                    className="col-12 col-md-6 d-flex flex-wrap"
                  >
                    <Link
                      to={`${process.env.PUBLIC_URL}/blog/${materia.slug}`}
                      className="position-relative w-100"
                    >
                      <img
                        src={materia.image}
                        alt={materia.titulo}
                        className="img-post"
                      />

                      <div className="text-white bg-blue  position-absolute bottom-0 h5 p-3 m-0 post-blog">
                        <h5 className="font-weight-bold post-chamada my-auto">
                          {materia.titulo}
                        </h5>
                      </div>
                    </Link>
                    <p className="mt-3 sub-post">{materia.descricao}</p>
                    <Link
                      to={`${process.env.PUBLIC_URL}/blog/${materia.slug}`}
                      className="mt-1 text-blue font-weight-bold ml-auto h6"
                    >
                      Matéria completa
                      <img
                        src={setaVerde}
                        alt="Ícone Seta Verde"
                        className="ml-2"
                      />
                    </Link>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </>
      )}
    </Container>
  );
}

export default Materia;
