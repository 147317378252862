import styled from 'styled-components';

export const Container = styled.div`
  .titulo {
    font-weight: 900;
    font-size: 3.8rem;
    margin-bottom: 2rem;
  }
  hr {
    border-top: 1px solid #8f8f8f;
    margin: 2rem 0;
  }
  .um-grupo {
    font-size: 1.5rem;
  }
  .barra {
    height: 12px;
    width: 340px;

    div {
      width: 50%;
      transform: skewX(-25deg);
    }
  }
  .green {
    background-color: #129346;
  }
  .bg-blue {
    background-color: #2e2d7b;
  }
  li {
    text-align: left;
    color: #707070;
    font-size: 1.2rem;
    margin: 1rem 0;
  }

  @media screen and (max-width: 767px) {
    padding-top: 2.8rem;
    li {
      font-size: 1.2rem;
    }
    .large-desc {
      padding: 0 15px !important;
      min-height: auto !important;
      .nome {
        padding-left: 140px;
        padding: 0 15px 0 140px;
        min-height: 120px;
        display: flex;
        align-items: center;
        margin-bottom: 3%;
      }
    }
    .li-1-col-2 {
      margin-top: 0 !important;
    }

    .barra {
      width: 300px;
    }
    .titulo {
      font-size: 3rem;
    }
    .col-img {
      position: absolute;
      max-width: 140px;
    }
    .info-coordenador {
      padding-left: 140px;
      padding: 5% 15px 5% 140px;
      min-height: 140px;
    }

    .um-grupo {
      font-size: 1.2rem;
    }

    .desc {
      font-size: 0.9rem;
    }
  }

  @media screen and (max-width: 575px) {
    .faca-parte-div {
      margin-top: -3rem !important;
      margin-bottom: 1.5rem !important;
    }
  }
  @media screen and (max-width: 466px) {
    .sub-titulo {
      font-size: 1.5rem;
    }

    .titulo {
      font-size: 2.8rem;
    }
  }
  @media screen and (max-width: 445px) {
    .info-coordenador {
      padding: 5% 15px 1% 140px;
    }
    .titulo {
      font-size: 2.6rem;
    }
  }
  @media screen and (max-width: 412px) {
    .info-coordenador {
      padding: 3% 15px 0% 140px;
    }
    .titulo {
      font-size: 2.4rem;
    }
  }

  @media screen and (max-width: 362px) {
    .titulo {
      font-size: 2.2rem;
    }
  }
  @media screen and (min-width: 992px) and (max-width: 1199px) {
    .fala,
    .info-coordenador p {
      font-size: 1.4rem;
    }
  }

  @media screen and (min-width: 1200px) {
    .fala,
    .info-coordenador p {
      font-size: 1.5rem;
    }
  }
`;
