import React from 'react';
import { Helmet } from 'react-helmet';
import { Container } from './styles';
import setaVerde from '../../../../assets/seta-verde.png';

function ConterCrise() {
  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Convergência Brasil -</title>
        <meta name="description" content="" />
      </Helmet>
      <Container>
        <div className="container" id="somos">
          <div className="col-12">
            <h1 className="font-weight-bold">
              Empresários tentamagirpara conter crise
            </h1>
            <h2 className="">
              Lideranças reagem à gestão de “continuidade” pregada pelo futuro
              ministro da Saúde
            </h2>
            <span>
              {' '}
              <span className="font-weight-bold">
                Por Talita Moreira e Mônica Scaramuzzo
              </span>{' '}
              — De São Paulo <br /> 23/03/2021 05h01
            </span>
            <hr />
            <p>
              Quando Marcelo Queiroga disse que faria um trabalho de
              continuidade ao ser convidado pelo presidente Jair Bolsonaro para
              assumir o Ministério da Saúde, o sinal de alerta acendeu de vez.
              Esse sentimento de urgência foi um catalisador de adesões dos
              setores produtivo e financeiro à carta elaborada por economistas
              com críticas à gestão da pandemia pelo governo
            </p>
            <p>
              As assinaturas trazem a público uma insatisfação que fermenta nos
              bastidores há meses entre empresários e banqueiros. Até então,
              poucos haviam exposto abertamente suas críticas à condução de uma
              crise sanitária e econômica que parece não ter fim.
            </p>
            <p>
              “A carta revela a fragilidade do governo na condução da pandemia”,
              afirma Pedro Passos, um dos acionistas da Natura. Para o
              empresário, que é um dos mais de 500 signatários do documento, o
              Congresso tem se fortalecido nas discussões e deveria cobrar mais
              o governo federal nas ações sobre a crise.
            </p>
            <p>
              Ao trazer nomes como os de Passos, dos banqueiros Roberto Setubal
              e Pedro Moreira Salles, copresidentes do conselho de administração
              do Itaú Unibanco, e do presidente do Credit Suisse, José Olympio
              Pereira, a carta ganhou peso. Por outro lado, engrossou as
              iniciativas de mobilização do setor privado diante do colapso da
            </p>
            <p>
              “A sociedade precisa se mobilizar [diante da pandemia] para fazer
              a parte que lhe cabe, seja por meio de iniciativas próprias, seja
              influenciando as políticas públicas”, afirma Fábio Barbosa,
              sócio-conselheiro da Gávea Investimentos e um dos mais de 500
              signatários do documento. Mas ele faz a ressalva de que a carta é
              sobre economia e não política.
            </p>
            <p>
              Esse é o tom. Em geral, mesmo após a divulgação do documento, são
              poucos os que centram suas críticas a Bolsonaro de forma pública.
              Uma fonte do setor financeiro diz que o empresariado perdeu as
              esperanças de ver um bom trabalho do governo e decidiu agir.
            </p>
            <p>
              Não é só a má gestão da pandemia que incomoda. Nas palavras de um
              executivo do setor bancário, o país entrou numa “zona de perigo”
              com a alta da inflação e a desvalorização cambial. Ao mesmo tempo,
              afirma, o governo flerta com o risco fiscal porque “Bolsonaro terá
              que ser cada vez mais Bolsonaro” para recuperar sua popularidade.
            </p>
            <p>
              Ainda assim, de acordo com uma das fontes, não se fala em
              impeachment - menos por apreço ao presidente do que pelo custo de
              uma ruptura no meio da pandemia. A saída, diz, é o setor privado
              fazer o que puder para empurrar o barco para a frente. “Vamos
              remar a favor”, afirma.
            </p>
            <p>
              reuniram ontem para discutir como o setor privado pode ajudar o
              governo no período mais agudo da pandemia. “Entendemos que não é o
              momento de apontar o dedo. Temos de ajudar na influência e
              articulação a favor do país”, afirma, Eduardo Sirotsky Melzer, do
              grupo RBS e um dos sócios do fundo de private equity EB Captal.
              Ele tem sido um dos braços-direito da empresária Luiza Trajano.
              “Não vamos comprar vacina”, reitera.
            </p>
            <p>
              Outro movimento empresarial, o Convergência Brasil, tenta emplacar
              a agenda de reformas. O grupo, voltou a intensificar o corpo a
              corpo com parlamentares pela aprovação do Programa de Renda
              Mínima. Capitaneada por Elvaristo Amaral (exSantander), a frente
              defende a criação de uma contribuição complementar voltada à
              população mais pobre. Amaral defende que 30% da economia gerada
              com a reforma administrativa e dos recursos de privatizações sejam
              usados para bancar o programa.
            </p>
            <p>
              Nos últimos dias, o grupo fez videoconferência com o
              vice-presidente da Câmara, Marcelo Ramos (PL-AM) e deve agendar
              uma conversa com o ministro da Economia, Paulo Guedes. Criado no
              ano passado, o movimento tem apoio de empresários da indústria,
              como Jorge Gerdau, e também de ex-ministros e técnicos do governo.
            </p>
            <p>
              Paulo Uebel, ex-secretário de Desburocratização do Ministério da
              Economia, tem ajudado Amaral a amarrar a agenda do grupo com a do
              governo. Segundo Uebel, a pode gerar uma comoção a favor da emenda
              a ser apresentada à comissão que discutirá o conteúdo da reforma
              administrativa.
            </p>
            <p>
              Uma das principais lideranças do agronegócio do país, Roberto
              Rodrigues, exministro da Agricultura no governo Lula, também apoia
              o projeto. “É preciso fazer uma discussão mais ampla sobre esse
              tema”, diz.
            </p>
            <p>
              Apoiadores da iniciativa, contudo, não acreditam que será uma
              discussão fácil em Brasília, pois avaliam que Bolsonaro tem um
              viés mais estatizante.
            </p>
            <a
              href="https://valor.globo.com/brasil/noticia/2021/03/23/empresarios-tentam-agir-para-conter-crise.ghtml"
              rel="noreferrer"
              target="_blank"
              className="mt-1 text-blue font-weight-bold d-block text-end h6 link-materia"
            >
              Matéria completa
              <img src={setaVerde} alt="Ícone Seta Verde" className="ml-2" />
            </a>
          </div>
          <div className="row" />
        </div>
      </Container>
    </>
  );
}

export default ConterCrise;
