import styled from 'styled-components';

export const Container = styled.div`
  .faca-parte {
    font-size: 4rem;
    font-weight: 900;
  }

  @media screen and (max-width: 767px) {
    .faca-parte {
      font-size: 3.1rem;
      line-height: 47px;
      max-width: 465px;
      margin: 1rem auto 0 !important;
    }

    .compartilhe {
      font-size: 1.5rem;
      max-width: 464px;
      margin: 1rem auto;
    }
  }
  @media screen and (max-width: 340px) {
    .faca-parte {
      max-width: 289px;
      font-size: 45px;
    }
  }
  @media screen and (max-width: 575px) {
    img {
      margin: auto !important;
    }
  }
  @media screen and (max-width: 486px) {
    img {
      margin: auto !important;
    }
    .faca-parte {
      max-width: 290px;
    }

    .compartilhe {
      font-size: 1.3rem;
      max-width: 285px;
    }
  }

  @media screen and (min-width: 767px) and (max-width: 992px) {
    .faca-parte {
      font-size: 3rem;
    }
    p {
      font-size: 1.4rem;
    }
  }
`;
