import React from 'react';
import { Helmet } from 'react-helmet';
import { Container } from './styles';
import bandeira from '../../assets/bandeira.png';

import Contato from '../../components/Contato';
import Assinaturas from '../../components/Assinaturas';
import 'react-h5-audio-player/lib/styles.css';
import BannerHome from '../../components/BannerHome';
import Midia from '../../components/Midia';
import Manifesto from '../../components/Manifesto';
import Assine from '../../components/Assine';
import Blog from '../../components/Blog';
import VideoCarousel from '~/components/VideoCarousel';
import VideoLivesCarousel from '~/components/VideoLivesCarousel';

function Home() {
  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>
          Convergência Brasil - Por um Brasil Mais Justo e Eficiente
        </title>
        <meta
          name="description"
          content="Convergência Brasil - Somos representantes da sociedade civil e buscamos soluções para a grave crise econômica e social brasileira"
        />
      </Helmet>
      <BannerHome heading={1} />
      <Container>
        <div className="container" id="somos">
          <div className="row">
            <div className="col-12 col-md-6 col-lg-5">
              <h3 className="text-blue font-weight-bold">
                Somos representantes da sociedade civil, sem vínculos
                partidários ou amarras políticas.
              </h3>
              <p>
                Buscamos soluções para a grave crise econômica e social
                brasileira.
              </p>
              <p>
                Apoiamos avanços de pautas no congresso, com o fim de reduzir a
                desigualdade e ampliar a educação para uma ascensão social e
                desenvolvimento econômico de todo o país.
              </p>
            </div>
            <div className="col-12 d-flex col-md-6 col-lg-7">
              <img
                src={bandeira}
                alt="Bandeira do Brasil"
                className="my-auto"
              />
            </div>
          </div>
        </div>
      </Container>
      <VideoCarousel heading={3} />
      <VideoLivesCarousel heading={3} />
      <Midia />
      <Manifesto />
      <Assine heading={3} />
      <Blog heading={3} />
      <Assinaturas />
      <Contato />
    </>
  );
}

export default Home;
