import React from 'react';

import { Helmet } from 'react-helmet';
import { NavHashLink } from 'react-router-hash-link';
import { Container } from './styles';
import BannerHome from '../../components/BannerHome';
import Contato from '../../components/Contato';
import Blog from '../../components/Blog';
import Assinaturas from '../../components/Assinaturas';
import Assine from '../../components/Assine';
import FacaParte from '../../components/FacaParte';
import Somos1 from '../../assets/somos-o-convergencia-1.png';
import Somos2 from '../../assets/somos-o-convergencia-2.png';
import economia from '../../assets/economia.png';
import avancos from '../../assets/avancos.png';
import desigualdade from '../../assets/desigualdade.png';
import mapaApartidarios from '../../assets/mapa-apartidarios.png';

function QuemSomos() {
  const scrollWithOffset = el => {
    const yCoordinate = el.getBoundingClientRect().top + window.pageYOffset;
    let yOffset = -109;
    if (window.innerWidth <= 768) {
      yOffset = -80;
    }
    window.scrollTo({ top: yCoordinate + yOffset, behavior: 'smooth' });
  };
  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Convergência Brasil – Quem Somos</title>
        <meta
          name="description"
          content="Conheça o Convergência Brasil, um grupo de empresários e executivos unidos para lutar por um Brasil mais justo, eficiente e igualitário"
        />
      </Helmet>
      <BannerHome heading={2} />
      <Container id="titulo">
        <div className="container pt-4 pb-2">
          <span className="barra d-flex mx-auto mb-4">
            <div className="green" />
            <div className="bg-blue" />
          </span>
          <h2 className="text-blue text-center mt-5 mb-4 text-uppercase titulo">
            QUEM SOMOS
          </h2>
          <p className="conheca my-4 mx-auto">
            Conheça o Convergência Brasil, um grupo de empresários e executivos
            unidos para lutar por um Brasil mais justo, eficiente e igualitário.
          </p>
          <h1 className="text-center my-4 font-weight-bold h1 sub-titulo text-blue">
            Somos o Convergência Brasil
          </h1>
          <div className="row mb-4">
            <div className="col-12 col-md-6 texto-quem-somos">
              <p>
                Diante da realidade de <strong> desigualdade no Brasil </strong>
                , o aumento do desemprego e as batalhas ideológicas que anulam a
                existência de milhões de brasileiros, deixando-os invisíveis
                perante o Estado e a sociedade, nós nascemos.
              </p>

              <p>
                Somos um grupo independente e{' '}
                <strong> sem vínculo partidário,</strong> pois sabemos que nosso
                país não pode mais perder tempo com ideologias, nem continuar
                dividido politicamente com disputas que visam garantir os
                próprios interesses, ignorando as desigualdades sociais. Todos
                querem um Estado eficiente e justo, então devemos lutar juntos
                por isso.
              </p>
            </div>
            <div className="col-12 col-md-6">
              <img
                src={Somos1}
                alt="Ilustração pessoa e bandeira do Brasil"
                className="img-somos1"
              />
            </div>
          </div>
          <div className="row row-somos2">
            <div className="col-12 col-md-7">
              <img
                src={Somos2}
                alt="Ilustração compras"
                className="img-somos2"
              />
            </div>
            <div className="col-12 col-md-5 texto-quem-somos">
              <p>
                Portanto, criamos o movimento Convergência Brasil e através de
                um Manifesto queremos ajudar a solucionar a grave crise
                econômica e social que vivemos. Reunimos 28 líderes e estamos em
                busca do apoio da sociedade para fazer do Brasil um país melhor.
                Apoiamos o Programa de Privatizações e de Reformas que criarão
                condições e oportunidades para todos os brasileiros,
                especialmente os mais necessitados.
              </p>

              <h3 className="text-blue propomos">
                Propomos que todos se unam para buscar a Convergência do Brasil!
              </h3>
            </div>
          </div>{' '}
          <div className="row mb-4">
            <h2 className="col-12 text-center mt-5 mb-4 mx-auto font-weight-bold h1 sub-titulo text-blue">
              Estamos em busca de um Brasil mais igualitário
            </h2>
            <div className="col">
              <div className="row row-busca justify-content-center">
                <div className="col col-busca col-sm-4 text-center flex-column d-flex">
                  <div className="img-busca mx-auto d-flex">
                    <img
                      src={economia}
                      alt="Ilustração Economia"
                      className="my-auto"
                    />
                  </div>
                  <h3 className="text-center mt-3 texto-busca">
                    Buscamos soluções para a grave crise econômica e social
                    brasileira.
                  </h3>
                </div>
                <div className="col col-busca  col-sm-4 text-center flex-column d-flex">
                  <div className="img-busca mx-auto d-flex">
                    <img
                      src={avancos}
                      alt="Ilustração Avanços"
                      className="my-auto"
                    />
                  </div>

                  <h3 className="text-center mt-3 texto-busca">
                    Apoiamos os avanços de pautas econômicas e sociais.
                  </h3>
                </div>
                <div className="col col-busca col-sm-4 text-center flex-column d-flex">
                  <div className="img-busca mx-auto d-flex">
                    <img
                      src={desigualdade}
                      alt="Ilustração Balança"
                      className="my-auto"
                    />
                  </div>

                  <h3 className="text-center mt-3 texto-busca">
                    Lutamos para a redução da desigualdade social no Brasil.
                  </h3>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="banner">
          <div className="container pt-5">
            <h2 className="text-center  mb-4 mx-auto font-weight-bold h1 sub-titulo text-blue um-manifesto">
              Um manifesto para um país melhor
            </h2>
            <p className="texto-pais-melhor mx-auto">
              Queremos mostrar que a sociedade civil não está imóvel e que cada
              vez mais estaremos em busca de uma Convergência de ideias e
              interesses para um país melhor para todos os brasileiros.
            </p>

            <p className="texto-pais-melhor mx-auto">
              Estamos motivados por essa proposta que contribui com o
              crescimento econômico, a fim de diminuir a desigualdade social e
              oferecer mais oportunidades para os brasileiros.
            </p>
          </div>
        </div>
        <div className="container my-5">
          <div className="row">
            <div className="col-12 col-md-7">
              <h2 className="text-left mb-4 mx-auto font-weight-bold h1 sub-titulo text-blue">
                Somos apartidários. <br />
                Nossa luta é pelo Brasil!
              </h2>
              <p className="texto-apartidarios texto-apartidarios-1">
                Somos um grupo apartidário, sem nenhuma ideologia política que
                visa o bom senso e os valores morais.
              </p>
              <p className="texto-apartidarios texto-apartidarios-2">
                Por isso repudiamos qualquer verba vinda do poder público e de
                partidos políticos. Esse manifesto é financiado pelos seus
                fundadores e coordenadores.
              </p>

              <div className="col col-veja-aqui py-3">
                <div className="row flex-nowrap">
                  <span className="barra-assinatura d-flex">
                    <div className="green" />
                  </span>
                  <div className="blue">
                    <NavHashLink
                      smooth
                      scroll={el => scrollWithOffset(el)}
                      to={`${process.env.PUBLIC_URL}/coordenadores#titulo`}
                      className="text-center my-auto ja-assinou px-4 text-white font-weight-bold m-0 text-uppercase h2"
                    >
                      Veja aqui quem apoia
                    </NavHashLink>
                  </div>
                </div>
              </div>
            </div>

            <div className="col col-img-apartidarios position-relative">
              <img
                src={mapaApartidarios}
                alt="Mapa do Brasil e brasileiros"
                className="img-apartidarios positon-absolute"
              />
            </div>
          </div>
        </div>
      </Container>
      <FacaParte />
      <Assine heading={3} />
      <Blog heading={3} />
      <Assinaturas />
      <Contato />
    </>
  );
}

export default QuemSomos;
