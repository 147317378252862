import styled from 'styled-components';

export const Container = styled.div`
  padding-top: 140px;
  a,
  a:active,
  a:hover {
    text-decoration: none;
    color: inherit;
  }

  .font-weight-black {
    font-weight: 900;
  }

  @media screen and (max-width: 767px) {
    padding-top: 112px;
  }
`;

export const FaleConosco = styled.div``;
