import React, { useState } from 'react';
import { Form, Input } from '@rocketseat/unform';
import * as Yup from 'yup';
import swal from 'sweetalert2';
import api from '../../services/api';
import { Container } from './styles';

const schema = Yup.object().shape({
  nomeContato: Yup.string().required('O nome é obrigatório'),
  emailContato: Yup.string().required('O e-mail é obrigatório'),
  messageContato: Yup.string().required('A mensagem é obrigatória'),
});

function Contato() {
  const [loading, setLoading] = useState(false);

  async function handleSubmit(
    { nomeContato, emailContato, messageContato },
    { resetForm }
  ) {
    setLoading(true);
    const formData = new URLSearchParams();
    formData.append('name', nomeContato);
    formData.append('email', emailContato);
    formData.append('message', messageContato);
    try {
      const response = await api.post('enviar-email', formData);
      if (response.data) {
        swal
          .fire('Bom Trabalho!', 'Mensagem enviada com sucesso!', 'success')
          .then(_ => {
            resetForm();
          });

      }
    } catch (error) {
      swal.fire(
        'Ocorreu um erro',
        'Desculpe o incômodo, mas um erro inesperado aconteceu tente novamente;',
        'error'
      );
    }

    setLoading(false);
  }

  return (
    <>
      <Container id="contato">
        <div className="container pb-2">
          <span className="barra d-flex mx-auto mb-4">
            <div className="green" />
            <div className="bg-blue" />
          </span>
          <h4 className="text-blue text-center text-uppercase">Fale Conosco</h4>
          <Form schema={schema} onSubmit={handleSubmit}>
            <div className="row mx-0">
              <div className="col-12 col-md campo campo-nome">
                <Input
                  name="nomeContato"
                  type="nome"
                  className="col-12 input-contato"
                />
                <label
                  className="input-label text-uppercase mb-2"
                  htmlFor="nomeContato"
                >
                  Nome <span>*</span>
                </label>
              </div>
              <div className="col-12 col-md campo campo-email">
                <Input
                  name="emailContato"
                  type="email"
                  className="col-12 input-contato"
                />
                <label
                  className=" text-uppercase mb-2 input-label"
                  htmlFor="emailContato"
                >
                  E-mail<span>*</span>
                </label>
              </div>
            </div>
            <div className="row mx-0">
              <div className="col mx-0 mb-0 campo campo-message">
                <Input multiline name="messageContato" />
                <label
                  className="text-uppercase mb-2 input-label"
                  htmlFor="messageContato"
                >
                  Mensagem<span>*</span>
                </label>
              </div>
            </div>
            <div className="col-12">
              <div className="row my-4 flex-nowrap mx-0">
                <span className="barra-btn d-flex">
                  <div className="green" />
                </span>
                <div className="blue">
                  <button
                    type="submit"
                    dissable={loading.toString()}
                    className="btn btn-assinar px-0 text-white font-weight-bold m-0"
                  >
                    {loading ? (
                      <div className="spinner-border" role="status">
                        <span className="sr-only" />
                      </div>
                    ) : (
                      'ENVIAR'
                    )}
                  </button>
                </div>
              </div>
            </div>
          </Form>
        </div>
      </Container>
    </>
  );
}

export default Contato;
