import styled from 'styled-components';

export const Container = styled.div`
  background-color: #fff;

  a {
    color: #fff !important;
    text-decoration: none !important;
    align-items: center;
    display: flex;
  }

  @media (max-width: 444px) {
    .logos-icones {
      justify-content: center !important;
    }
  }
  @media screen and (max-width: 767px) {
    span {
      display: block !important;
      width: 48px;
      height: 10px;
      position: absolute;

      &:nth-child(1) {
        margin-top: -2px;
        background-color: #129346;
        transform: skewX(-25deg);
      }
      &:nth-child(2) {
        margin-top: 13px;
        background-color: #2e2d7b;
        transform: skewX(-25deg);
        margin-left: -5px;
      }
      &:nth-child(3) {
        margin-top: 29px;
        background-color: #129346;
        margin-left: -10px;
        transform: skewX(-25deg);
      }
    }

    .hamburguer {
      width: 48px;
      display: block !important;
      height: 25px;
    }
    .email {
      display: none;
    }
  }
`;

export const Copyright = styled.div`
  background-color: #eeeeee;
`;
