import styled from 'styled-components';
import { darken } from 'polished';

export const Container = styled.div`
  width: 350px;
  position: sticky;
  display: ${props => (props.visible ? 'flex' : 'none')};
  bottom: 0;
  left: 0;
  width: 100%;
  z-index: 1000;
  background: #f7f7f7;
  padding: 15px;
  overflow-y: auto;
  justify-content: center;
  align-items: center;

  a {
    color: #0c6f35;
    transition-duration: 0.3s;

    :hover {
      color: ${darken(0.1, '#0c6f35')} !important;
    }
  }

  p {
    margin: 0px;
    margin-right: 15px;
    line-height: 20px;

    @media screen and (max-width: 768px) {
      margin-right: 10px;
    }
  }

  button {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 20px;
    height: 40px;
    background: #11823e;
    border: none;
    border-radius: 10px;
    color: #fff;
    transition: background 0.3s;
    font-size: 1.2rem;
    padding: 5px 10px;
    margin-top: 0px;
    font-weight: 500;
    :hover {
      background: ${darken(0.1, '#0c6f35')};
    }
  }
`;
