import styled from 'styled-components';

import Banner from '../../assets/banner-pais-melhor.jpg';

export const Container = styled.div`

  .titulo {
    font-weight: 900;
    font-size: 3.8rem;
    margin-bottom: 2rem;
  }
  .propomos {
    font-size: 1rem;
  }
  .texto-busca {
    font-size: 1rem;
    font-weight: 400;
  }
  .conheca {
    font-size: 1.5rem;
    max-width: 800px;
  }

  .barra {
    height: 12px;
    width: 340px;

    div {
      width: 50%;
      transform: skewX(-25deg);
    }
  }
  .green {
    background-color: #129346;
  }
  .bg-blue {
    background-color: #2e2d7b;
  }

  .img-busca {
    height: 177px;
  }
  .banner {
    background-size: cover;
    background-image: url(${Banner});
    background-position: center;
    background-repeat: no-repeat;
    padding: 0 0 390px 0;
  }
  .texto-pais-melhor {
    max-width: 757px;
  }

  .barra-assinatura {
    height: 60px;
    width: 22.5px;

    div {
      width: 100%;
      transform: skewX(-25deg);
    }
  }
  .blue {
    transform: skewX(-25deg);
    background-color: #2e2d7b;
    width: -moz-available;
    width: -webkit-fill-available;
    height: 60px;
    display: flex;
  }
  .ja-assinou {
    display: block;
    transform: skewX(25deg);
    width: inherit;
    font-size: 2.5rem;
  }

  .texto-apartidarios-1 {
    max-width: 500px;
  }
  .img-apartidarios {
    max-width: fit-content;
    width: 116%;
    margin-left: -114px;
  }
  @media screen and (max-width: 767px) {
    padding-top: 2.8rem;
    .img-somos2,
    .img-somos1 {
      display: flex;
      max-width: 230px;
      margin: auto;
    }
    .row-somos2 {
      flex-direction: column-reverse;
    }
    .barra {
      width: 300px;
    }
    .titulo {
      font-size: 3rem;
    }
    .sub-titulo {
      font-size: 1.8rem;
    }
    .conheca {
      font-size: 1rem;
    }
    .banner {
      padding: 0px 0 227px 0;
    }
    .col-veja-aqui {
      padding-top: 445px !important;
      padding-right: 3rem;
      padding-left: 3rem;

      .ja-assinou {
        font-size: 1.5rem;
      }
    }
    .col-img-apartidarios {
      position: absolute !important;
      max-width: 564px;
      display: flex;

      .img-apartidarios {
        width: 100%;
        max-width: 467px;
        margin: 243px auto auto;
      }
    }
  }

  @media screen and (max-width: 574px) {
    .col-busca {
      max-width: 190px;
    }
    .row-busca {
      align-items: center;
      flex-direction: column !important;
    }
    .img-busca {
      height: auto;
      max-width: 200px;
    }
    .sub-titulo {
      font-size: 1.5rem;
    }
    .banner {
      padding: 0px 0 276px 0;
    }
    .um-manifesto {
      margin-bottom: 1rem !important;
    }
  }

  @media screen and (max-width: 497px) {
    .col-veja-aqui {
      padding-top: 90vw !important;
      padding-right: 2rem;
      padding-left: 2rem;
      .ja-assinou {
        font-size: 1.4rem;
      }
    }
  }

  @media screen and (max-width: 427px) {
    .img-apartidarios {
      margin: 279px auto auto !important;
    }
  }
  @media screen and (min-width: 768px) and (max-width: 992px) {
    .banner {
      padding: 0 0 27vw 0;
    }
    .sub-titulo {
      font-size: 2.1rem;
    }

    .img-apartidarios {
      width: 115%;
      margin-left: -25px;
      margin-top: -4px;
    }
    .texto-apartidarios-1 {
      min-width: 405px;
    }
    .texto-apartidarios-2 {
      min-width: 497px;
    }
    .ja-assinou {
      font-size: 1.6rem;
    }

    .blue {
      height: 38px;
    }
    .barra-assinatura {
      height: 38px;
    }
  }

  @media screen and (min-width: 992px) and (max-width: 1199px) {
    .texto-quem-somos,
    .texto-busca,
    .texto-pais-melhor,
    .texto-apartidarios,
    .propomos {
      font-size: 1.4rem;
    }
    .banner {
      padding: 0 0 27vw 0;
    }

    .img-apartidarios {
      width: 130%;
      margin-left: -88px;
      margin-top: 26px;
    }
    .ja-assinou {
      font-size: 2.3rem;
    }
  }

  @media screen and (min-width: 1200px) {
    .texto-quem-somos,
    .texto-busca,
    .texto-pais-melhor,
    .texto-apartidarios,
    .propomos {
      font-size: 1.5rem;
    }
  }
`;
