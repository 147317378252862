const initialState = {
  assinaturas: 0,
};
export const assinaturasReducer = (state = initialState, action) => {
  switch (action.type) {
    case 'addNew':
      return { assinaturas: state.assinaturas + 1 };
    case 'currentAssinaturas':
      return { assinaturas: action.assinaturas };
    default:
      return state;
  }
};
