import React from 'react';
// import { NavHashLink } from 'react-router-hash-link';
import PropTypes from 'prop-types';
import Carousel from 'react-elastic-carousel';
import { Container } from './styles';
// import videoCassio from '~/assets/videos/cassio.mp4';
// import  "node_modules/video-react/dist/video-react.css";
// import { Player } from 'video-react';
import Item from './Item';

function VideoCarousel({ heading }) {
  const scrollWithOffset = el => {
    const yCoordinate = el.getBoundingClientRect().top + window.pageYOffset;
    let yOffset = -109;
    if (window.innerWidth <= 768) {
      yOffset = -80;
    }
    window.scrollTo({ top: yCoordinate + yOffset, behavior: 'smooth' });
  };
  // const CustomHeading = `h${heading}`;
  // const SubCustomHeading = `h${heading + 1}`;
  const breakPoints = [
    { width: 1, itemsToShow: 1 },
    { width: 550, itemsToShow: 2 },
    { width: 768, itemsToShow: 3 },
    { width: 1200, itemsToShow: 3 },
  ];
  return (
    <Container>
      <div className="container">
        <span className="barra d-flex mx-auto my-4 mb-4">
          <div className="green" />
          <div className="bg-blue" />
        </span>
        <h2 className="text-blue text-uppercase mx-auto text-center txt">
          APOIO AO MOVIMENTO
        </h2>

        <p className="text-center">
          Veja depoimentos de personalidades que acreditam no conceito
          Convergência
        </p>
      </div>

      <div className="container">
        <div className="carousel-wrapper">
          <Carousel isRTL itemsToShow={3} breakPoints={breakPoints}>
            <Item>
              <div className="embed-responsive embed-responsive-16by9">
                <video
                  controls
                  poster="https://convergenciabrasil.com.br/videos/capav1.jpg"
                >
                  <source
                    src="https://convergenciabrasil.com.br/videos/Cassio-Casseb.mp4"
                    type="video/mp4"
                  />
                </video>
              </div>
            </Item>
            <Item>
              <div className="embed-responsive embed-responsive-16by9">
                <video
                  controls
                  poster="https://convergenciabrasil.com.br/videos/capav2.jpg"
                >
                  <source
                    src="https://convergenciabrasil.com.br/videos/Felipe-Rigoni.mp4"
                    type="video/mp4"
                  />
                </video>
              </div>
            </Item>
            <Item>
              <div className="embed-responsive embed-responsive-16by9">
                <video
                  controls
                  poster="https://convergenciabrasil.com.br/videos/capv8.png"
                >
                  <source
                    src="https://convergenciabrasil.com.br/videos/Tabata-Amaral.mp4"
                    type="video/mp4"
                  />
                </video>
              </div>
            </Item>
            <Item>
              <div className="embed-responsive embed-responsive-16by9">
                <video
                  controls
                  poster="https://convergenciabrasil.com.br/videos/capav3.jpg"
                >
                  <source
                    src="https://convergenciabrasil.com.br/videos/Samuel-Moreira.mp4"
                    type="video/mp4"
                  />
                </video>
              </div>
            </Item>
            <Item>
              <div className="embed-responsive embed-responsive-16by9">
                <video
                  controls
                  poster="https://convergenciabrasil.com.br/videos/capav4.jpg"
                >
                  <source
                    src="https://convergenciabrasil.com.br/videos/raul-henry.mp4"
                    type="video/mp4"
                  />
                </video>
              </div>
            </Item>
            <Item>
              <div className="embed-responsive embed-responsive-16by9">
                <video
                  controls
                  poster="https://convergenciabrasil.com.br/videos/capav5.jpg"
                >
                  <source
                    src="https://convergenciabrasil.com.br/videos/jose-paulo.mp4"
                    type="video/mp4"
                  />
                </video>
              </div>
            </Item>
            <Item>
              <div className="embed-responsive embed-responsive-16by9">
                <video
                  controls
                  poster="https://convergenciabrasil.com.br/videos/capav6.png"
                >
                  <source
                    src="https://convergenciabrasil.com.br/videos/Elvaristo_Teixeira.mp4"
                    type="video/mp4"
                  />
                </video>
              </div>
            </Item>
            <Item>
              <div className="embed-responsive embed-responsive-16by9">
                <video
                  controls
                  poster="https://convergenciabrasil.com.br/videos/capav7.png"
                >
                  <source
                    src="https://convergenciabrasil.com.br/videos/Apoiadores-Convergencia.mp4"
                    type="video/mp4"
                  />
                </video>
              </div>
            </Item>
          </Carousel>
        </div>
      </div>
    </Container>
  );
}

export default VideoCarousel;
VideoCarousel.propTypes = {
  heading: PropTypes.number.isRequired,
};
