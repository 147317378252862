import React, { useRef } from 'react';
import { Link } from 'react-router-dom';
import swal from '@sweetalert/with-react';
import AudioPlayer from 'react-h5-audio-player';
import { Container } from './styles';
import jpEntrevista from '../../assets/jp-entrevista-new.jpg';
import logoJp from '../../assets/logo-jp.png';
import logoEstadaoSP from '../../assets/logoEstadao-2.png';
import logoOGlobo from '../../assets/logo-o-globo.png';
import logoIstoe from '../../assets/logo-istoe.png';
import elvaristo from '../../assets/elvaristo-new.jpg';
import familia from '../../assets/familia-new.jpg';
// import logoFolha from '../../assets/logo-folha.png';
import logoParana from '../../assets/logo-parana.png';
import logoRb from '../../assets/logo-rb.png';
import logoVeja from '../../assets/logo-veja.png';
import logoIg from '../../assets/logo-ig.png';
import rendaBrasil from '../../assets/renda-brasil-1.jpg';
// import luiza from '../../assets/luiza-1.jpg';
import microfoneRb from '../../assets/microfone-rb.jpg';
import bolsaFamilia from '../../assets/bolsa-familia.jpg';
import 'react-h5-audio-player/lib/styles.css';
import AudioEntrevista from '../../assets/audio/ELVARISTO-AMARAL-90MINUTOS.mp3';
import CapaElvaristo from '../../assets/capa-materia-principal-1.jpg';
import guedes from '../../assets/guedes.jpg';
// import LuizaIg from '../../assets/luiza-ig-capa-1.jpg';
import ElvaristoMigalhas from '../../assets/elvaristo-materia-migalhas.jpg';
import logoMigalhas from '../../assets/logo-migalhas.png';
import moneyReport from '../../assets/money-report-capa.jpg';
import logoMoneyReport from '../../assets/logo-money-report.png';

function Midia() {
  const player = useRef();
  const pause = () => {
    player.current.audio.current.pause();
  };
  function showAudio() {
    swal({
      title: 'Rádio Bandeirantes Porto Alegre Entrevista Elvaristo Amaral',
      content: <AudioPlayer autoPlay src={AudioEntrevista} ref={player} />,
      buttons: ['Fechar', false],
    }).then(() => pause());
  }
  return (
    <Container id="midia">
      <div className="container">
        <span className="barra d-flex mx-auto my-4 mb-4">
          <div className="green" />
          <div className="bg-blue" />
        </span>
        <h2 className="text-blue text-uppercase text-center ">Na Mídia</h2>

        <div className="row">
          <div className="col-12  col-md">
            <Link
              to={`${process.env.PUBLIC_URL}/noticias/empresarios-articulam-proposta-para-programa-de-renda-basica-no-pais`}
              className="row"
            >
              <div className="col">
                <img
                  src={CapaElvaristo}
                  alt="Empresários articulam proposta para programa de renda básica no País"
                />
                <div className="bg-blue box-entrevista box-entrevista-principal text-white">
                  <div className="row align-items-center mx-0 mb-1">
                    <span className="barra-entrevista d-flex ml-1">
                      <div className="green" />
                      <div className="bg-white" />
                    </span>
                    <span className="font-weight-bold m-0 ml-2 entrevista ">
                      ENTREVISTA
                    </span>
                  </div>
                  <p className="font-weight-bold m-0 h5-5 text-uppercase ">
                    Empresários articulam proposta para programa de renda básica
                    no País
                  </p>
                </div>
              </div>
            </Link>
            <div className="row my-3">
              <img
                src={logoEstadaoSP}
                alt="Logo Estadão"
                className="col-4 my-auto logo-estadao"
              />
              <p className="col my-auto h5-5 noticia-texto-principal">
                Empresários brasileiros de diversos segmentos declararam apoio
                ao Manifesto Convergência Brasil, movimento iniciado em junho
                deste ano.
              </p>
            </div>
          </div>

          <div className="col">
            <Link
              to={`${process.env.PUBLIC_URL}/noticias/guedes-ja-defendeu-uso-de-dividendos-pagos-por-estatais`}
              className="row mx-0"
            >
              <img
                src={guedes}
                alt="Guedes já defendeu uso de dividendos pagos por estatais"
                className="col p-0  img-midia"
              />
              <div className="col d-flex flex-wrap bg-blue box-entrevista text-white">
                <div className="row mt-auto align-items-center mx-0 mb-2">
                  <span className="barra-entrevista d-flex ml-1">
                    <div className="green" />
                    <div className="bg-white" />
                  </span>
                  <span className="font-weight-bold m-0 ml-2 entrevista-sub">
                    ENTREVISTA
                  </span>
                </div>
                <p className="m-0 h5-5 entrevista-sub-texto text-uppercase">
                  Guedes já defendeu uso de dividendos pagos por estatais
                </p>
              </div>
            </Link>

            <div className="row ml-0 my-3">
              <img
                src={logoEstadaoSP}
                alt="Logo Estadão"
                className="col-4 pl-0 my-auto logo-estadao"
              />
              <p className="col my-auto h5-5 noticia-texto-principal">
                Resistência do presidente Jair Bolsonaro a outras medidas do
                “pacote” planejado pelo ministro da Economia adiou o debate.
              </p>
            </div>

            {/* <a
              href="https://economia.ig.com.br/2021-03-22/luiza-trajano-e-outros-empresarios-se-juntam-para-propor-renda-basica.html"
              className="row mx-0"
              target="_blank"
              rel="noreferrer"
            >
              <img
                src={LuizaIg}
                alt="Luiza Trajano e outros empresários se juntam para propor renda básica"
                className="col p-0 img-midia"
              />
              <div className="col d-flex flex-wrap bg-blue box-entrevista text-white">
                <div className="row mt-auto align-items-center mx-0 mb-2">
                  <span className="barra-entrevista d-flex ml-1">
                    <div className="green" />
                    <div className="bg-white" />
                  </span>
                  <span className="font-weight-bold m-0 ml-2 entrevista-sub">
                    ENTREVISTA
                  </span>
                </div>
                <p className="m-0 h5-5 text-uppercase entrevista-sub-texto">
                  Luiza Trajano e outros empresários se juntam para propor renda
                  básica
                </p>
              </div>
            </a> */}
            <div className="row ml-0 my-3">
              <img
                src={logoIg}
                alt="Logo IG"
                className="col-4 pl-0 my-auto logo-ig"
              />
              <p className="col my-auto h5-5 noticia-texto-principal">
                O Movimento Convergência Brasil retomou as articulações após a
                definição para o comando do Congresso Nacional
              </p>
            </div>
          </div>
        </div>
        {/* <div className="row mt-2">
          <a
            href="https://www.youtube.com/watch?v=iJaYgQyB2FE"
            rel="noreferrer"
            target="_blank"
            className="col-12 logo-texto-img col-md-4 "
          >
            <img
              src={jpEntrevista}
              alt="ELVARISTO DO AMARAL, FUNDADOR DO GRUPO CONVERGÊNCIA BRASIL"
              className="w-100 img-materia-meio col-6 col-md-12 p-0"
            />
            <div className=" col-6 logo-texto flex-column col-md-12 p-0">
              <img
                src={logoJp}
                alt="Logo JovemPan"
                className="my-2 mt-auto mr-auto logo-jp"
              />
              <p className="noticia-texto-secundaria mb-auto">
                Empresários brasileiros de diversos segmentos declararam apoio
                ao Manifesto Convergência Brasil, movimento iniciado em junho
                deste ano.
              </p>
            </div>
          </a>
          <a
            href="https://www.istoedinheiro.com.br/ter-uma-surpresa-por-dia-na-politica-sufoca-qualquer-programa-economico/"
            rel="noreferrer"
            target="_blank"
            className="col-12 logo-texto-img col-md-4"
          >
            <img
              src={elvaristo}
              alt="ELVARISTO DO AMARAL, FUNDADOR DO GRUPO CONVERGÊNCIA BRASIL"
              className="w-100 img-materia-meio col-6 col-md-12 p-0"
            />
            <div className=" col-6 logo-texto flex-column col-md-12 p-0">
              <img
                src={logoIstoe}
                alt="Logo Isto é Dinheiro"
                className="my-2 mt-auto mr-auto logo-istoe"
              />
              <p className="noticia-texto-secundaria mb-auto">
                Empresários querem usar privatização e reforma administrativa
                para dobrar Bolsa Família.
              </p>
            </div>
          </a>

          <Link
            to={`${process.env.PUBLIC_URL}/noticias/grupo-de-empresarios-quer-destinar-30-das-privatizacoes-do-governo-federal-aos-mais-pobres`}
            className="col-12 logo-texto-img  col-md-4"
          >
            <img
              src={familia}
              alt="Grupo de empresários quer destinar 30% do arrecadado..."
              className="w-100 img-materia-meio col-6 col-md-12 p-0"
            />
            <div className=" col-6 logo-texto flex-column col-md-12 p-0">
              <img
                src={logoOGlobo}
                alt="Logo O Globo"
                className="my-2 mt-auto mr-auto logo-istoe"
              />
              <p className="noticia-texto-secundaria mb-auto">
                Parte da economia potencial com a reforma administrativa também
                seria destinada ao programa.
              </p>
            </div>
          </Link>
        </div>

 */}

        <div className="row mt-4">
          <a
            href="https://www.youtube.com/watch?v=iJaYgQyB2FE"
            rel="noreferrer"
            target="_blank"
            className="col-6 col-md "
          >
            <img
              src={jpEntrevista}
              alt="ELVARISTO DO AMARAL, FUNDADOR DO GRUPO CONVERGÊNCIA BRASIL"
            />
            <img src={logoJp} alt="Logo JovemPan" className="my-2 logo-jp" />
            <p className="noticia-texto-secundaria">
              Empresários brasileiros de diversos segmentos declararam apoio ao
              Manifesto Convergência Brasil, movimento iniciado em junho deste
              ano.
            </p>
          </a>

          <a
            href="https://www.moneyreport.com.br/agenda-liberal/empresarios-se-mobilizam-por-criacao-de-renda-basica-com-avanco-de-reformas/"
            rel="noreferrer"
            target="_blank"
            className="col-6 col-md "
          >
            <img
              src={moneyReport}
              alt="Empresários se mobilizam por criação de renda básica com avanço de reformas"
            />
            <img
              src={logoMoneyReport}
              alt="Logo MoneyReport"
              className="mt-2 mb-2 "
            />
            <p className="noticia-texto-secundaria">
              Empresários se mobilizam por criação de renda básica com avanço de
              reformas.
            </p>
          </a>
          <a
            href="https://www.istoedinheiro.com.br/ter-uma-surpresa-por-dia-na-politica-sufoca-qualquer-programa-economico/"
            rel="noreferrer"
            target="_blank"
            className="col-6 col-md"
          >
            <img
              src={elvaristo}
              alt="ELVARISTO DO AMARAL, FUNDADOR DO GRUPO CONVERGÊNCIA BRASIL"
            />
            <img
              src={logoIstoe}
              alt="Logo Isto é Dinheiro"
              className="my-2 logo-istoe"
            />
            <p className="noticia-texto-secundaria">
              Empresários querem usar privatização e reforma administrativa para
              dobrar Bolsa Família.
            </p>
          </a>

          <Link
            to={`${process.env.PUBLIC_URL}/noticias/grupo-de-empresarios-quer-destinar-30-das-privatizacoes-do-governo-federal-aos-mais-pobres`}
            className="col-6 col-md"
          >
            <img
              src={familia}
              alt="Grupo de empresários quer destinar 30% do arrecadado..."
            />
            <img
              src={logoOGlobo}
              alt="Logo O Globo"
              className="my-3  logo-istoe"
            />
            <p className="noticia-texto-secundaria">
              Parte da economia potencial com a reforma administrativa também
              seria destinada ao programa.
            </p>
          </Link>
        </div>

        <div className="row mt-4">
          <Link
            to={`${process.env.PUBLIC_URL}/noticias/porandubas-n-709`}
            className="col-6 col-md"
          >
            <img
              src={ElvaristoMigalhas}
              alt="O Movimento Convergência lança ofensiva junto aos Poderes Executivo e Legislativo"
            />
            <img src={logoMigalhas} alt="Logo Migalhas" className="my-2" />
            <p className="noticia-texto-secundaria">
              O Movimento Convergência, que agrega importantes figuras da área
              econômica, lança ofensiva junto aos Poderes Executivo e
              Legislativo
            </p>
          </Link>
          <Link
            to={`${process.env.PUBLIC_URL}/noticias/renda-brasil-a-interminavel-discussao-em-torno-de-como-pagar-o-programa`}
            className="col-6 col-md "
          >
            <img
              src={rendaBrasil}
              alt="Renda Brasil: A interminável discussão em torno de como pagar o
                programa"
            />
            <img src={logoVeja} alt="Logo Veja" className="my-2" />
            <p className="noticia-texto-secundaria">
              Renda Brasil: A interminável discussão em torno de como pagar o
              programa.
            </p>
          </Link>
          {/* <Link
            to={`${process.env.PUBLIC_URL}/noticias/executivos-querem-usar-privatizacao-e-reforma-administrativa-para-dobrar-bolsa-familia`}
            className="col-6 col-md"
          >
            <img
              src={luiza}
              alt="Empresários querem usar privatização e reforma administrativa
                para dobrar Bolsa Família"
            />
            <img
              src={logoFolha}
              alt="Logo Folha de São Paulo"
              className="my-2"
            />
            <p className="noticia-texto-secundaria">
              Empresários querem usar privatização e reforma administrativa para
              dobrar Bolsa Família.
            </p>
          </Link> */}
          <div
            className="col-6 col-md"
            onClick={showAudio}
            onKeyPress={showAudio}
            role="button"
            tabIndex="0"
          >
            <img
              src={microfoneRb}
              alt="Rádio Bandeirantes Porto Alegre Entrevista Elvaristo Amaral."
            />
            <img src={logoRb} alt="Logo Rádio Bandeirantes" className="my-2" />
            <p className="noticia-texto-secundaria">
              Rádio Bandeirantes Porto Alegre Entrevista Elvaristo Amaral.
            </p>
          </div>
          <a
            href="https://paranaportal.uol.com.br/economia/executivos-privatizacao-reforma-administrativa-bolsa-familia/"
            rel="noreferrer"
            target="_blank"
            className="col-6 col-md"
          >
            <img
              src={bolsaFamilia}
              alt="Executivos querem usar privatização e reforma administrativa
                para dobrar Bolsa Família"
            />
            <img src={logoParana} alt="Logo Paraná Portal" className="my-2" />
            <p className="noticia-texto-secundaria">
              Executivos querem usar privatização e reforma administrativa para
              dobrar Bolsa Família.
            </p>
          </a>
        </div>
      </div>
    </Container>
  );
}

export default Midia;
