import styled from 'styled-components';

import Banner from '../../assets/banner-assinaturas.jpg';
import BannerMobile from '../../assets/banner-assinaturas-mobile.jpg';

export const Container = styled.div`
  padding-top: 140px;
  .banner {
    background-size: contain;
    background-image: url(${Banner});
    background-repeat: no-repeat;
    padding: 0;
    height: 38vw;
    background-position: bottom;
  }

  .veja-quem {
    font-weight: 900;
    font-size: 3.5rem;
  }
  a,
  a:active,
  a:hover {
    text-decoration: none;
    color: inherit;
  }
  button {
    outline: #fff !important;
    width: fit-content;
  }
  .num-assinatura {
    font-family: Roboto;
    font-weight: 900;
    font-size: 4rem;
    vertical-align: sub;
  }
  .assinaturas {
    vertical-align: super;
  }
  .letra {
    margin: auto;
  }
  .letra,
  .pagination {
    font-size: 1.6rem;
    color: #707070;
  }

  .letra-selected {
    font-weight: bold;
  }

  table {
    border-collapse: collapse;
    width: 100%;
  }

  td,
  th {
    border: none;
    text-align: left;
    padding: 8px 12px;
  }
  th {
    background-color: #2e2d7b;
    color: white;
  }
  tr:nth-child(odd) {
    background-color: #dddddd;
  }

  .col-numeros {
    justify-content: flex-end;
  }

  .pagination {
    color: #707070;
  }
  .selected-number,
  .selected-letter {
    font-weight: bold;
  }
  @media screen and (min-width: 767px) and (max-width: 992px) {
  }

  @media screen and (max-width: 767px) {
    padding-top: 90px;

    .veja-quem {
      font-size: 2.5rem;
    }
    table {
      min-width: 434px;
    }
    .col-table {
      overflow-x: scroll;
      flex: 1 1 auto;
      width: auto;
      margin: 0 20px;
      padding-left: 0;

      ::-webkit-scrollbar-track {
        border-radius: 20px;
        background: #eeeeee;
      }

      ::-webkit-scrollbar-thumb {
        background: #707070;
        border-radius: 20px;
      }
      ::-webkit-scrollbar {
        height: 10px;
        width: 10px;

        border: 1px solid #d5d5d5;
        border-radius: 20px;
      }
    }
    .col-letras {
      flex-direction: column;
      width: fit-content;
      flex: none;
      align-items: center;
      padding-right: 0px;
    }

    .col-numeros {
      justify-content: center;
    }
    .row-assinaturas {
      flex-wrap: nowrap;
    }
  }

  @media screen and (max-width: 574px) {
    padding-top: 20vw;
    .banner {
      background-image: url(${BannerMobile});
      height: 56vw;
    }
    .veja-quem {
      font-size: 7.2vw;
    }
  }

  @media screen and (min-width: 992px) and (max-width: 1199px) {
    .veja-quem {
      font-size: 4.8rem;
    }
  }

  @media screen and (min-width: 1200px) {
    .veja-quem {
      font-size: 5.8rem;
    }
  }
`;
