import React from 'react';
import { Helmet } from 'react-helmet';
import { Container } from './styles';

import AssineForm from '../../components/Assine';

function Assine() {
  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Convergência Brasil - Assine</title>
        <meta
          name="description"
          content="Convergência Brasil - Assine o manifesto"
        />
      </Helmet>
      <Container>
        <AssineForm heading={1} />
      </Container>
    </>
  );
}

export default Assine;
